.modalheading-green{
    color: $theme3;
}
.detimg{
    width: 77px;
    height: auto;
    padding: 5px;
}
.commonbtn{
    width: 170px;
    height: 40px;
    border: none;
    background-color: $theme8;
    color: $theme5;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px 0 0 5px;
    margin-inline: -20px;
    @include md{
        width: 228px;
        height: 50px;

    }
}
.bgsection{
    background:$theme8;
    width: 100%;
    padding: 23px 20px 20px;
    // padding: 40px 20px 20px;
    border-radius: 5px;
    margin-bottom: 25px;
    & .screenImg{
        width: 100%;
        // aspect-ratio: 1/.6;
        height: 75vh;
        border: 4px solid #666973;
        // border-right: 4px solid #666973;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        // &::before{
        //     position: absolute;
        //     content: '';
        //     background-image: url(../images/game-screen-border-top.png);
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     background-size: cover;
        //     height: 36px;
        //     top: -18px;
        //     left: 0;
        //     width: 100%;
        // }
        // &::after{
        //     position: absolute;
        //     content: '';
        //     background-image: url(../images/game-screen-border-bottom.png);
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     background-size: cover;
        //     height: 36px;
        //     bottom: -22px;
        //     left: 0;
        //     width: 100%;
        // }
 
    }
    & .verifyBox{
        border: 1px solid #666973;
        width: 120px;
        height: 40px;
        border-radius: 5px;
        & .smbox{
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 2px solid #666973;
            & .verifyImg{
                width: 16px;
            } 
        }
        & .verify-text{
            font-size: 10.12px;
            font-weight: 500;
            margin: auto;
            padding-left: 3px;
            color: #666973;
        } 
    }
    & .advance{
        & .vsmtext{
            font-size: 10.12px;
            font-weight: 500;
            line-height: 9.26px;
            color: #666973;
            border-color: #666973 !important;
        }
    }
    & .bonus-container{
        border-color: #666973 !important;
        & .game_text{
            font-size: 14px;
            font-weight: 600;
            line-height: 19.5px;
        }
        & .sm-text{
            font-size: 10.89px;
            font-weight: 600;
            line-height: 14.16px;
            text-align: center;
        }
    }
    & .logo-img{
        width: 200px;
        height: auto;
        margin-inline: auto;
    }
    & .play-full-wrapper{
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: end;
        & .play-wrapper{
            & .fun{
                font-size: 16px;
                font-weight: 500;
                line-height: 20.8px;
                color: $theme5;
                &.active{
                    color: white;
                }
            }
            & .switch {
                position: relative;
                display: inline-block;
                width: 44px;
                height: 19px;
            }
            
            & .switch input { 
                opacity: 0;
                width: 0;
                height: 0;
            }
            
            & .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $theme1;
                -webkit-transition: .4s;
                transition: .4s;
            }
    
            & .slider:before {
                position: absolute;
                content: "";
                height: 17px;
                width: 22px;
                left: 1px;
                bottom: 1px;
                background-color: $theme3;
                -webkit-transition: .4s;
                transition: .4s;
            }
            
            & input:checked + .slider {
                background-color: $theme1;
            }
            
            & input:focus + .slider {
                box-shadow: 0 0 1px $theme1;
            }
            
            & input:checked + .slider:before {
                -webkit-transform: translateX(20px);
                -ms-transform: translateX(20px);
                transform: translateX(20px);
            }
            
            & .slider.round {
                border-radius: 6px;
            }
            
            & .slider.round:before {
                border-radius: 4px;
            }
        }
    } 
    & .full-screen-toggle{
        background-color: transparent;
        border: 0;
        height: 27px;
        width: 35px;
        & img{
            height: 100%;
            width: 100%;
        }
    }
}
 .label{
   font-size: 13px;
   line-height: 16px;
   color: $theme5;
   font-weight: 500;
   margin-left: 24px;
}

.modalSection{
    & .modalBody{
        & .modalHeading{
         color: white !important;
        font-size: 18px;
        font-weight: 500;
        line-height: 20.8px;

        }
    & .category{
        max-height: 240px;
      overflow-x: hidden;
      background-color: $theme1;
      border-radius: 10px;
      margin-top: 10px;
      @include md {
      background-color: $theme2;
      }

        @include md{
        background-color: $theme8;
        border-radius: 5px;
                }
        & .bonusmcard{
            padding-block: 13px;
            height: 100%;
            border-bottom: 1px solid rgba($theme5, 0.4);
            padding-inline: 2px;
            &:last-child {
                border: 0 !important;
            }
            @include max-md {
                align-items: center;
            }
            @include md{
            padding-inline: 10px;
            padding-block: 10px;
            // margin-block: 6px;
            border: none;
            }
            .icon-1{
                width: 18px;
                height:18px ;
                display: flex;
                & img {
                    display: flex;
                }
            }
         & .bonus-text{
                font-size: 13px;
                color: $theme5 !important;
                margin-left: 5px;
                display: flex;
            }
        }
        .Bonusmoney{
            font-size: 16px;
            font-weight: 600;
            color: white;
            @include md {

                margin-top: 2px;
            }
        }
    }
    #dropdown-basic-button{
        background-color: $theme8;
        border: none;
        min-width: 120px;
        display: flex;
        justify-content:space-between;
        align-items: center;
        height: 40px;
        font-size: 15px;
        font-weight: 500;
        line-height: 23.4px;
         color: $theme5;
         border-radius: 8px !important;
         @include md{
            width: 200px;

                }
    }
    .show{
    background-color: $theme8;
     min-width: 120px;
     border-radius: 8px !important;
     left: 0px;
     max-height: 300px;
     overflow-y: auto;
     display: inline;
     @include md{
        width: 100%;

            }
     
    }
  
    
    
    }
    .BounseDropdown {
        & .dropdown-menu {
            @include max-md {
                left: unset !important;
                right: 0px !important;
                min-width: 180px !important;
                padding-inline: 8px;
            }
        }
    }
}

.drop-item{
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 19.5px !important;
    color: $theme5;
    padding-block: 10px;
        border-radius: 8px;
        background-color: none;
        border: 2px solid $theme8;
    &.active{
        border: 1px solid $theme3;
        background-color: transparent !important;
        & .greenbtn{
            display: flex;
        }

    }
    & .greenbtn{
     width: 14px;
     aspect-ratio: 1/1;
     display: none;
    }
    
    &:hover{
        background-color: $theme10;
        color: white;
        transition-duration: .4s all;
      
    
    }
    .dropdown-item.active{
        background-color: none;
    }
   
}
.modal-text{
     font-size: 13px;
     font-weight: 500;
     line-height: 19.5px;
     text-align: left;
     color: $theme5;
    margin-bottom: 7px;
     }
     .data{
        background-color: $theme8;
        padding: 10px;
        border-radius: 8px;
    &    .amounthistory{
         width: 100%;
            border-collapse: separate;
            border-spacing: 0 5px;
         thead{
           tr{ 
            th{
                padding: 10px 15px;
                font-size: 13px;
                font-weight: 500;
                 color: $theme5;
                 background-color: $theme10;
                 white-space: nowrap;

                &:first-child{
                border-radius: 5px 0px 0px 5px !important;
                }
                &:nth-child(3){
                    border-radius: 0px 5px 5px 0px !important;
                    }
            }
           }
         }
            tr{ 
             td:not(.noDataTd){
                 padding: 10px 15px;
                 font-size: 13px;
                 font-weight: 500;
                  color: $theme5;
                  background-color: $theme10;
                  white-space: nowrap;
 
                 &:first-child{
                 border-radius: 5px 0px 0px 5px !important;
                 }
                 &:nth-child(3){
                     border-radius: 0px 5px 5px 0px !important;
                     }
             }
            }
        }
     &   .nodataimg{
        width: 138px;
        height: 1/1;
        margin-block: 54px;
        margin-inline: auto;
     }
     }
     .modalSection{
        & .dblimg{
            width: 32px;
            height: auto;
        }
        .fat{
            font-size: 30px;
            font-weight: 600;
            color: white;
            
        }
    .btnholder{
        & .greenbtn{
            color: $theme5;
            padding-block: 8px;
            width: 50%;
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
            border-bottom: 1px solid $theme5 !important;

            &.active{
                background-image: linear-gradient(to top, rgba(66, 183, 42, 0.4), rgba(66, 183, 42, 0) 44%);
                color: white;
                border: none;
                border-bottom: 1px solid #42B72A !important;
            }
        }
    }
    .expire{
        font-size: 13px;
        color: $theme5;
        font-weight: 400;
    }
    .time{
        font-size: 17px;
        font-weight: 500;
        line-height: 22.1px;
        color: white;
    }
     }
     .slots{
        background-color: $theme8;
        padding: 10px;
        border-radius: 5px;
        & .circle{
            height: 77px;
            width: 77px;
            min-width: 77px;
            border-radius: 50%;
            border: 8px solid $theme2;
            display: flex;
            justify-content: center;
            align-items: center;
            & .inner-circle{
                width: 50px;
                height: 50px;
                background-color: $theme2;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 13px;
                font-weight: 500;
                line-height: 16.9px;
                text-align: left;
                color: $theme8;
            }
        }
        .earn{
            font-size: 22px;
            font-weight: 600;
            color: $theme3;
            
        }
        .quection{
            width: 17px;
            height: 1/1;
            position: relative;
            &:hover{
             .tooltip-1{
                opacity: 100;
             }
            }
        }
     }
     .okbtn{
        background-color: $theme3;
        border: none;
        width: 123px;
        height: 40px;
        border-radius: 5px;
        border-bottom: 2px solid $theme12;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        color: white;

     }


     @media (min-width: 1800px) {
        .bgsection .screenImg {
            aspect-ratio: 16/9;
            height: auto;
        }
     }

