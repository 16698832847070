.herowrapper{
    padding-bottom: 35px;
    @include max-md{
        padding-bottom: 25px;
    }
    & .homebanner{
        height: 100%;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include max-md{
                height: 160px;
            }
            @include max-lg{
                height: 200px;
            }
        }
    }
    & .pokerBanner{
        position: relative;
        @include lg{
            // height: 170px; 
            border-radius: 15px;
            overflow: hidden;
        }
        @include xl{
            // height: auto;
        }
        @include xxl{
            
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        & .textupper{
            position: absolute;
            left: 15px;
            top: 15px;
            @include sm{

            }
            @include md{
                
            }
            @include lg{
            }
            @include xl{
            }
            @include xxl{
                left: 20px;
                top: 20px;
            }
            & .poker{
                background-color: #1E222D;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                @include sm{

                }
                @include md{
                    
                }
                @include lg{
                }
                @include xl{
                }
                @include xxl{
                    width: 35px;
                    height: 35px;
                }
                & img{
                    object-fit: contain;
                    width: 21px;
                    height: 21px;
                    @include sm{

                    }
                    @include lg{
                    }
                    @include xl{
                    }
                    @include xxl{
                        width: 25px;
                        height: 25px;
                    }
                    @include sm{

                    }
                    @include md{
                        
                    }
                    @include xl{
                        
                    }
                    @include xxl{
                        
                    }
                }
            }
            & .pokertextbig{
                font-weight: 600;
                color: white;
                line-height: 1.1;
                font-size: clamp(0.8rem, 1vw + 0.65rem, 3rem);
                @include sm{

                }
                @include md{
                    
                }
                @include xl{
                    // font-size: 25px;
                }
                @include xxl{
                    // font-size: 29.35px;
                }
                &.pokertextbig1{
                    line-height: 1.3;
                    font-size: 16px;
                    @include sm{

                    }
                    @include lg{
                        font-size: clamp(0.8rem, 1vw + 0.65rem, 3rem);
                    }
                    @include xl{
                    }
                    @include xxl{
                    }
                }
            }
            & .pokerText{
                font-weight: 600;
                color: white;
                margin-top: 8px;
                font-size: 12px;
                @include sm{

                }
                @include md{
                    
                }
                @include xl{
                // font-size: 14px;
                font-size: clamp(0.65rem, 1vw + 0.05rem, 2rem);
                }
                @include xxl{
                    // font-size: 20px;
                }
            }
        }
        & .lowertext{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 15px;
            @include sm{

            }
            @include md{
                
            }
            @include lg{
            }
            @include xl{
            }
            @include xxl{
                bottom: 20px;
            }
            & .bonusbtn{
                border: 0;
                // height: 39px;
                height: 30px;
                background-color: white;
                border-radius: 4px;
                // font-size: 15px;
                font-weight: 600;
                color: black;
                padding-inline: 20px;
                border-bottom: 3px solid #ADADAD;
                transition: 0.3s ease-in-out;
                &:hover {
                    transform: translateY(-5px);
                }
                @include sm{

                }
                @include md{
                    
                }
                @include lg{
                    font-size: 12px;
                    width: 115px;
                }
                @include xl{
                    width: 100px;
                    padding-inline: 0;
                }
                @include xxl{
                    font-size: 14px;
                    width: 125px;
                    padding-inline: 0;
                }
            }
        }
    }
    & .swiper-pagination-bullets{
        position: absolute;
        bottom: 18px;
        right: unset;
        width: auto;
        left: 50%;
        & .swiper-pagination-bullet{
            border-radius: 50px;
            background-color: $theme5;
            opacity: 1;
            width: 7px;
            height: 7px;
            transition: 0.3s ease-in-out;
            background: #191D27;
        }
        & .swiper-pagination-bullet-active{
            background: #0E95FA;
            width: 33px;
            height: 7px;
        }
    }
    & .navigation{
        position: absolute;
        right: 0;
        bottom: 0px;
        background-color: #1E222D;
        border-radius: 12px 0px 0px 0px;
        border: 1px solid #1E222D;
        height: 28px;
        z-index: 5;
        display: flex;
        width: auto;
        padding: 6px 0 6px 6px;
        gap: 4px;
        & .prev{
            background-color: #2E3443;
            width: 29px;
            height: 22px;
            border-radius: 4.22px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            & img{
                width: 8px;
                height: 8px;
                object-fit: contain;
            }
        }
        & .next{
            background-color: #2E3443;
            width: 29px;
            height: 22px;
            border-radius: 4.22px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            & img{
                width: 8px;
                height: 8px;
                object-fit: contain;
            }
        }
    }
}
#sport-container {
    height: 336px !important;
    min-height: 336px !important;
    margin-top: 20px;
    @media only screen and (max-width: 740px){
            height: 308px !important;
            min-height: 308px !important;
           margin-top: 10px;
      }
    & iframe {
        height: 100% !important;
        min-height: 100% !important;
   
   }
}


// register big wins desktop

.recentSectionHeadingWrapper{
    padding-bottom: 35px;
    & .registernowBtn{
        height: 40px;
        background-color: $theme3;
        border: 0;
        padding-inline: 35px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        color: white;
        border-bottom: 3px solid #318120;
    }
    & .registertext{
        font-size: 14px;
        font-weight: 600;
        color: $theme5;
    }
    & .registerbox{
        background-color: $theme8;
        height: 34px;
        width: 49.89px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        & img{
            width: 16.22px;
            height: 16.22px;
            object-fit: contain;
        }
    }
}

.recentSectionWrapper{
    padding-bottom: 35px;
    & .sectionHeading{
        font-size: 20px;
        font-weight: 500;
        color: white;
        margin-bottom: 22px;
        font-family: RETROTECH;
        line-height: normal;
        & span{
            width: 10px;
            height: 10px;
            background-color: $theme11;
            display: block;
            border-radius: 3.75px;
            margin-right: 20px;
        }
    }
}

.recentSectionWrapper{
    & .recentbackground{
        background-image: linear-gradient(to right, #2E3443 , #153124);
        padding: 18px;
        border-radius: 10px;
    }
    & .cardSlide{
        width: 100%;
        height: auto;
        overflow: hidden;
        & img{
            width: 100%;
            height: 100%;
            border-radius: 2.33px;
        }
    }
    & .cardTitle{
        font-size: 11.73px;
        font-weight: 500;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-block: 5px;
        line-height: normal;
        & img{
            width: 13px;
            height: 13px;
            object-fit: contain;
            margin-right: 4px;
            flex-shrink: 0;
        }
    }
    & .money{
        font-size: 14.66px;
        font-weight: 600;
        color: $theme11;
        line-height: normal;
    }
}

.gamesBannerWrapper{
    padding-bottom: 35px;
    & .gamesbackground{
        background-image: linear-gradient(to right, #2E3443 , #242937);
        padding: 18px;
        border-radius: 10px;
        & .bannerSlide{
            & .bannerImg{
                width: 100%;
                height: auto;
                // height: 150px;
                & img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
        }
    }
}

.bigwinsBannerWidth{
    width: 85px !important;
}

.providerSwiperSlider {
    width: auto !important;
    min-width: 90px;
    max-width: 130px !important;
    @include lg {
        width: 155.82px !important;
    }
    & .ProviderSlider{
        background-color: $theme8;
        border-radius: 5px;
        height: 52px;
        @include lg {
            height: 72.5px;
            border-radius: 6.66px;
        }
        & img{
            height: 25px;
            object-fit: contain;
            width: 70%;
            @include lg {
                height: 40px;
            }
        }
    }
}


.searchComp{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: $theme10;
    border: 0;
    padding-left: 40px;
    color: white;
    &::placeholder{
        color: $theme5;
        font-size: 15px;
        font-weight: 500;
    }
    &:focus{
        outline: unset;
    }
    @include lg {
        height: 45px;
        padding-left: 60px;
    }
}
.serachImg{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
    display: flex;
    & img{
        width: 18px;
        height: 18px;
        object-fit: contain;
        color: white;
        display: flex;
    }
    @include lg {
        left: 25px;
    }
}

.lobbyBanner{
    width: 100%;
    height: auto;
    & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// deposit modal 

.depositModalWrapper{
    background-color: rgba(0, 0, 0, 0.301);

    & .modal-dialog{
        @include md {
            min-width: 640px;
        }
        // height: auto;
        & .modal-content{
            background-color: $theme10 !important;
            // width: 100%;
            // height: auto;
            border-radius: 10px !important;
            // height: 100%;
              & .depositHeader{
                padding-inline: 35px;
                padding-block: 18px;
                background-color: #2F364C;
                & .text{
                    font-size: 18px;
                    line-height: 22px;
                }
                & .closeIcon{
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    & img{
                        width: 10px;
                    }
                }
              }
              & .depositMain{
                background-color: #202733;
                box-shadow: 0px 5.34px 5.34px 0px #00000040;
                padding: 25px;
                height: 609px;
                // overflow: auto;
                 & .nav{
                    & .nav-item{
                        width: 50%;
                        background-color: #191D27;
                        text-align: center;
                        & .nav-link{
                        color: #6A798C;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20.67px;
                        padding: 7px;
                        border: none;
                        &.active{
                            
                            border-top: 2.67px solid #8ECFFF !important;
                            color: white !important;
                            background-color:#0E95FA;

                        }
                            
                        }
                    }
                }
                & .nav-tabs{
                    border: none !important;
                    & .nav-link{
                        & .active{
                            background-color: #0E95FA !important;
                        }
                    }
                }
                & .coinParent{
                    padding-top: 22px;
                    overflow-x: auto;
                    /* width */
                        &::-webkit-scrollbar {
                            height: 5px;
                            display: block;
                        }
                        
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #202733; 
                        }
                        
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #191D27; 
                        }
                    & .coinParentInner{
                        min-width: 85px;
                        background-color: #191D27;
                        padding: 6px;
                        & .coinIconparent{
                            width: 18px;
                            height: 18px;
                        }
                        & .coinText{
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 18.75px;
                            color: white;
                        }
                    }
                    & .coinMoreBtn{
                        background-color: #191D27;
                        padding: 6px;
                        & .iconsParent{
                                width: 21.5px;
                                height: 21.5px;
                                & .coinLogo9{
                                 left: 18px;
                                 top: 0;
                                }
                                & .coinLogo8{
                                    left: 12px;
                                    top: 0;
                                   }
                                   & .coinLogo7{
                                    left: 6px;
                                    top: 0;
                                   }
                                   & .coinLogo6{
                                    left: 0;
                                    top: 0;
                                   }
                            
                        }
                        & .text{
                            font-size: 15px;
                            font-weight: 500;
                            line-height: 18.75px;
                        }
                        & .downIcon{
                            width: 9px;
                            height: 6px;
                       
                    }
                }
              }
              & .addHere{
                padding-top: 22px;
                font-size:  11.36px;
                font-weight: 400;
                 line-height: 14.2px;
                 color: white;
                 & a{
                    font-weight: 600;
                    color: #C2FB01;
                 }
              }
              & .dropdownParent{
                padding-top: 22px;
                gap: 30px;
                & .dropdowninner{
                    width: 50%;
                    & .dropdownText{
                        font-size: 13.1px;
                        font-weight: 500;
                        line-height: 16.38px;
                        color: #6A798C;
                        padding-bottom: 5px;
                    }
                    & .dropdown{
                        background-color: #191D27;
                        & .btn{
                            padding-block: 4px;
                            padding-inline: 7px;
                            &::after{
                                display: none !important;
                            }
                            & .iconParent{
                                gap: 13px;
                                & img{
                                    width: 25px;
                                    height: 25px;
                                }
                                & div{
                                    font-size: 13.1px;
                                    font-weight: 500;
                                    line-height: 16.38px;
                                }
                            }
                            & .iconParent2{
                                width: 30px;
                                height: 30px;
                                background-color: #202733;
                                & img{
                                    width: 9px;
                                    height: 6px;
                                }
                            }
                        }
                        & .dropdown-menu{
                            background-color: #202733;
                            & .dropdown-item{
                                &:hover{
                                    background-color: #333b4a;
                                }
                            }
                        }
                    }
                }
                & .bitCoinBtn{
                    background-color: #191D27;
                    border-radius: 25px;
                    padding: 5px 12px;
                    & .selected-icon{
                        // height: 22px;
                        width: 20px;
                        border-radius: 50%;
                        flex-shrink: 0;
                        & img{
                            overflow: hidden;
                        }
                    }
                    & .arrow-svg{
                        background-color: #2f364c;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                & .currency-heading{
                    color: #6A798C;
                    font-weight: 500;
                    font-size: 13.1px;
                }
              }
              & .bonusBox{
                margin-top: 0px;
                background-color: #f8ba2846;
                border-radius: 10px;
                gap: 12px;
                padding-inline: 15px;
                padding-block: 7px;
                & img{
                    width: 26px;
                    object-fit: cover;
                }
                & .text{
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 16.25px;
                    & .goldenText{
                        font-weight: 800;
                        color: #F8BA28;
                    }
                }
              }
              & .scannerSection{
                gap: 14px;
                padding-top: 31px;
                & .scanner{
                    width: 115px;
                    height: 115px;
                }
                & .rightSection{
                    & .childFirst{
                        padding-bottom: 22px;
                        & .text{
                            font-size: 13.1px;
                            font-weight: 500;
                            line-height: 16.38px;
                            color: #6A798C;
                            padding-bottom: 5px;
                        }
                        & .inputContainer{
                            background-color: #191D27;
                            padding-inline: 17px;
                            height: 40px;
                            & .input-address{
                                display: flex;
                                align-items: center;
                                background-color: transparent;
                                outline: none;
                                border: none;
                                width: 100%;
                                height: 100%;
                                color: white;
                            }
                        }
                    }
                    & .childSecond{
                        padding-block: 12px;
                        background-color: #2F364C;
                        gap: 15px;
                        border: #2F364C;
                        width: 100%;
                        & .text{
                            font-size: 13px;
                            font-weight: 600;
                            line-height: 16.25px;
                        }
                        & img{
                            width: 15px;
                            height: 15px;
                            object-fit: cover;
                        }
                    }
                }
              }
              & .infoBox{
                margin-top: 17px;
                gap: 19px;
                background-color: #c1fb0125;
                border-radius: 10px;
                padding-inline: 17px;
                padding-block: 19px;
                & img{
                    height: 26px;
                    width: 26px;
                    object-fit: cover;
                }
                & .text{
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16.25px;
                }
              }
              & .DepositCurrency{
                padding-top: 18px;
                & .text{
                    font-size: 11.36px;
                    font-style: 400;
                    line-height: 14.2px;
                }
                & .dropdownParent{
                    width: 40%;
                    padding-top: 0;

                    & .dropdown{
                        background-color: #191D27;
                        & .btn{
                            padding-block: 4px;
                            padding-inline: 10px;
                            &::after{
                                display: none;
                            }
                            & .iconParent{
                                gap: 10px;
                                & img{
                                    width: 20px;
                                    height: 20px;
                                    object-fit: cover;
                                }
                                & div{
                                    font-size: 13px;
                                    font-weight: 500;
                                    line-height: 16.38px;
                                }
                            }
                            & .iconParent2{
                                width: 30px;
                                height: 30px;
                                background-color: #202733;
                                & img{
                                    width: 8px;
                                    height: 6px;
                                  
                                }
                            }

                        }
                        & .dropdown-menu{
                            background-color: #202733;
                            & .dropdown-item{
                                &:hover{
                                    background-color: #333b4a;
                                }
                            }
                        }
                    }
                }
              }
              & .DepositMethod{
                padding-top: 15px;
                & .text1{
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16.38px;
                    color: #6A798C;
                }
                & .text2{
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 16.25px;
                }
                & .dropdownParent{
                    padding-top: 7px;
                    & .dropdown{
                        background-color: #191D27;
                        & .btn{
                            &::after{
                                display: none;
                            }
                            & .iconParent{
                                width: 72px;
                                height: 27px;
                            }
                            & .iconParent2{
                                width: 30px;
                                height: 30px;
                                background-color: #202733;
                                & img{
                                    width: 8px;
                                    height: 6px;
                                }
                            }
                        }
                    }
                }
                & .dropdown-menu{
                    background-color: #202733;
                    & .dropdown-item{
                        &:hover{
                            background-color: #333b4a;
                        }
                    }
                }
              }
              & .bonusBox2{
                background-color: #f8ba2842;
                padding-inline: 10px;
                padding-block: 8px;
                border-radius: 10px;
                gap: 14px;
                margin-top: 18px;
                & img{
                    width: 25px;
                    height: 29px;
                }
                & .text{
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 16px;
                    & .goldenText{
                        font-weight: 800;
                        color: #F8BA28;
                    }
                }
              }
              & .inputBox{
                padding-top: 7px;
                gap: 7px;
                & label{
                    padding-bottom: 7px;
                    display:block;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16px;
                    color: #6A798C;
                }
                & .box1{
                  & .inputParent1{
                    background-color: #191D27;
                    height: 40px;
                    padding-inline: 30px;
                    & input{
                        background-color: transparent;
                        border: none;
                        outline: none;
                        
                    }
                  }
                }
                & .box2{
                    & .inputParent2{
                      background-color: #191D27;
                      height: 40px;
                      padding-inline: 30px;
                      & input{
                        flex-grow:1;
                          background-color: transparent;
                          border: none;
                          outline: none;
                          padding-inline: 10px;
                      }
                      & .text{
                        color: #C2FB01;
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 16.38px;
                      }
                    }
                  }
              }
              & .amountBoxParent{
                padding-block: 7px;
                gap: 10px;
                & .amountBox1{
                  max-width: 200px;
                  height: 40px;
                  width: 100%;
                  & .amount{
                    width: 75%;
                    height: 100%;
                    text-align: end;
                    padding-right: 22px;
                    background-color: #2F364C;
                    & img{
                        width: 12px;
                        height: 12px;
                    }
                    & div{
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 16.38px;
                    }
                  }
                  & .percentage{
                    height: 100%;
                    background-color: #42B72A;
                    font-size: 8px;
                    font-weight: 700;
                    line-height: 10px;
                    flex-grow: 1;
                  }
                }
                & .active{
                  border: 1px solid #0E95FA;
                }
              }
              & .infoBox{
                gap: 20px;
                border-radius: 10px;
                background-color: #c1fb0140;
                padding-block: 12px;
                padding-inline: 17px;
                & img{
                  width: 26px;
                  height: 26px;
                }
                & div{
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                }
              }
              & .depositBtn{
                box-shadow: 0px 0px 8.75px 0px #00000066;
                border-top: 1.95px solid #3DACFF;
                background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
                font-size: 13px;
                font-weight: 600;
                line-height: 17px;
                margin-block: 15px;
              }
              & .discriptionBox{
                background-color: #1C202B;
                border-radius: 10px;
                padding-inline: 20px;
                padding-block: 10px;
                color: #6A798C;
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
              }
        }
    }
}
.cryptoHeader{
    & .heading{
        font-size: 20px;
        font-weight: 600;
        color: white;
        @include md {
            font-size: 25px;
        }
    }
    & .close{
        width: 14px;
        height: 14px;
        object-fit: contain;
        cursor: pointer;
        margin-bottom: 5px;
        & img{
            width: 100%;
            height: 100%;
        }
    }
}

// deposite fiat
.modalFiat{
    & .fiatHeading{
        font-size: 15px;
        font-weight: 600;
        color: white;
    }
    & .euroBtn{
        background-color: $theme8;
        display: flex;
        align-items: center;
        height: 44px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        padding: 0 20px 0 24px;
        font-size: 15px;
        font-weight: 600;
        line-height: 19.5px;
        color: white;
        width: 150px;
        & img{
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin-left: 8px;
        }
        & span{
            margin-left: auto;
            & img{
                width: 12px;
                height: 12px;
                object-fit: contain;
            }
        }
    }
    & .amountTxt{
        color: $theme5; 
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    & .amountBox{
        // background-color: $theme8;
        // border-radius: 5px;
        // height: 61px;
        // padding-inline: 24px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        background-color: #1B2028 !important;
        border-radius: 10px;
        height: 60px;
        width: 100%;


        position: relative;
        & input {
            height: 100%;
            border-radius: 10px;
            background-color: transparent;
            color: white;
            border: none !important;
            font-size: 24px;
            font-weight: 500;
            padding-inline: 25px;
            padding-right: 150px;
            &:focus{
                outline: none;
                box-shadow: none;
                border: none;
            }
          }
        & .textWhite{
            font-size: 24px;
            font-weight: 600;
            color: white;
            left: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        
            
        }
        & .textGreen{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 25px;
            color: #5BB54F;
            font-size: 16px;
            font-weight: 500;
        }
    }
    & .extra{
        font-size: 13px;
        font-weight: 500;
        color: $theme5;
        & span{
            font-weight: 600;
            color: white;
        }
    }
    & .dollorLbl{
        height: 58px;
        border: 1px solid $theme5;
        // border-radius: 5px;
        border-radius: 8.4px;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & .checksvg{
        position: absolute;
        bottom: -1px;
        right: 0;
        display: none;
        & img{
            width: 23.75px;
            height: 22px;
            object-fit: contain;
        }
    }
    & .percent{
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0px 5px 0px 5px;
        background-color: $theme14;
        font-size: 8px;
        font-weight: 500;
        color: white;
        width: 42.6px;
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & .dollorInp:checked + label {
        background-image: linear-gradient(to right, $theme14 , #082502);
        & .percent{
            background-color: #4483EE;
        }
        & .checksvg{
            display: block;
        }
    }
    & .recommandBox{
        background-color: #1B2028;
        padding: 12px;
        border-radius: 5px;
        & .recommandText{
            font-size: 13px;
            font-weight: 500;
            color: $theme5;
            margin-bottom: 22px;
        }
        & .cardWrapper{
            background-color: #212632;
            border-radius: 5px;
            height: 76px;
            & .cardLeft{
                padding-left: 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                & .cardImg{
                    width: 21.9px;
                    height: 14px;
                    object-fit: contain;
                    & img{
                        width: 100%;
                        height: 100%;
                    }
                }
                & .creditText{
                    font-size: 13px;
                    font-weight: 600;
                    color: 
                    white
                }
                & .creditGray{
                    font-size: 13px;
                    font-weight: 400;
                    color: $theme5
                }
                & .eurtxt{
                    font-size: 12px;
                    font-weight: 500;
                    color: white;
                }
            }
            & .divider{
                background-color: $theme5;
                width: 2px;
                height: 24px;
            }
            & .rytArroww{
                width: 12px;
                height: 12px;
                & img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            & .fastestTxt{

            }
            & .exclaImg{
                width: 18px;
                height: 18px;
                object-fit: contain;
                & img{
                    width: 100%;
                    height: 100%;
                }
            }
            & .fastestTxt{
                border-radius: 0px 5px 0px 10px;
                background-color: $theme14;
                height: 16px;
                padding-inline: 19px;
                font-size: 12px;
                font-weight: 500;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

// buy sell modal
.buysellModal{
    & .sellPara{
        font-size: 15px;
        font-weight: 500;
        color: $theme5;
        line-height: 19.5px;
    }
    & .textwhite{
        font-size:15px ;
        font-weight: 600;
        color: white;
        line-height: 19.5px;
    }
    & .textgray{
        font-size: 13px;
        font-weight: 500;
        color: $theme5;
        & img{
            width: 12px;
            height: 12px;
            object-fit: contain;
            margin-left: 4px;
        }
    }
    & .swapImg{
        width: 28px;
        height: 28px;
        object-fit: contain;
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .formInp{
        box-shadow: unset;
        &:focus{
            outline: 0;
        }
    }
    & .form-check-input:checked{
        background-color: $theme14 !important;
        border-color: $theme14 !important;
    }
    
    & .formLbl{
        font-size: 15px;
        font-weight: 600;
        color: $theme5;
    }
    & .formAnchor{
        font-size: 15px;
        font-weight: 600;
        color: $theme14;
    }
    & .confirmBtn{
        background-color: $theme14;
        height: 41px;
        width: 100%;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
        color: white;
        border: 0;
        border-bottom: 3px solid #318120;
    }
    & .one{
        font-size: 13px;
        font-weight: 500;
        line-height: 16.9px;
        color: $theme5;
        margin-top: 11px;
        text-align: center;
        &.onePara{
            text-align: start;
        }
        & .oneLink{
            color: white;
        }
    }
}
}

.languageModal{
    padding-inline: 20px;
    padding-block: 15px;
    box-shadow: 4px 4px 4px 0px #00000040;
    background: var(--Gray-Mneu-button-Color, #202733);
    height: 100%;
    width: 100%;
    & .heading{
        font-weight: 600;
        font-size: 15px;
        color: #FFFFFF;
    }
    & .closeIcon{
        width: 11px;
        object-fit: cover;
    }
    & .searchParent{
        background: var(--Menu-Color, #191D27);
        margin-top: 13px;
        width: 100%;
        height: 28px;
        border-radius: 5px;
         padding-inline: 12px;
         padding-block: 8px;

         & .searchIcon{
            width: 14px;
            object-fit: cover;
         }

          & input {
            color: #B2B6C5;
            font-family: Lexend;
            font-weight: 400;
            font-size: 10px;
            background-color: transparent;
            outline: none;
            border: none;
       }
    }
    & .languageBoxparent{
        padding: 14px;
        margin-top: 13px;
        background: var(--Menu-Color, #191D27);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        & .languageBox{
            background: var(--Main-menu-button-Color, #202733);
            padding-inline: 10px;
            padding-block: 7px;
            border-radius: 17.63px;
            &.active{
                background: var(--Main-Blue-Color, #0E95FA);
                & .languagebtnLabel{
                    border:3px solid var(--white-text-Color, #FFFFFF);
                    background-color: var(--Main-Blue-Color, #0E95FA);
                }
            }
        }
        & .languagecontent{
            display: flex;
            align-items: center;
            gap: 12px;
        }
        & .imgBox{
            background-color: #191D27;
            border-radius: 5px;
            width: 20px;
            height: 20px;
            
            & img{
                width: 10px;
                height: 10px;
                object-fit: cover;
            }
        }
        & .text{
            font-weight: 600;
            font-size: 11px;
            color: #FFFFFF;
        }
        & .languagebtnLabel{
          border-radius: 50%;
          width: 14px;
          height: 14px;
          border:3px solid var(--white-text-Color, #2F364C);
          background-color: var(--Main-Blue-Color, #6A798C);
        }
    }
}   