// live sports card
.liveCard{
    background-color: $theme7;
    border-radius: 8px;
    padding: 10px 15px;
    & .cardText{
        font-size: 12px;
        font-weight: 600;
        color: $theme5;
        & img{
            width: 12px;
            height: 12px;
            margin-right: 5px;
            margin-top: 2px;
            &.countryIcon {
                height: 16px;
                width: 16px;
                margin-top: 0;
            }
        }
        & .rytArrow{
            width: 3px;
            height: 4px;
            margin-left: 5px;
        }
    }
    & .icon{
        & img{
            width: 12px;
            height: 12px;
        }
    }
    & .bootomBar{
        background-color: $theme10;
        padding: 7px 8px;
        border-radius: 8px;
        height: 100%;
        // width: 105px;
        & .barText{
            font-size: 12.04px;
            color: $theme5;
            font-weight: 600;
        }
        & .num{
            font-size: 13.65px;
            font-weight: 600;
        }
    }
    & .down{
        background-color: $theme10;
        padding-inline: 14px;
        border-radius: 8px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & img{
            width: 5px;
            height: 7px;
            transform: rotate(90deg);
        }
    }
}
.mainHeading{
    font-size: 15px;
    font-weight: 600;
    color: white;
    & img{
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-right: 10px;
        filter: grayscale(50);
    }
    @include lg {
        font-size: 20px;
        & img {
            height: 20px;
            width: 20px;
        }
    }
}
.mainSubHeading{
    font-size: 13px;
    color: #CCFF00;
    font-weight: 600;
    cursor: pointer;
    @include lg {
        font-size: 14px;
        color: white;
        text-decoration: underline;
    }
}
.headingAnchor{
    text-decoration: none;
    color: $theme3;
    @include lg {
        color: white;
    }
}

.casinoSliderBtns {
    margin-left: 10px;
    & .sliderBtn {
        height: 27px;
        width: 36px;
        border-radius: 6px;
        background-color: #2E3443;
        display: flex;
        align-items: center;
        justify-content: center; 
        cursor: pointer;
        &:first-child {
            margin-right: 8px;
        }
        & img {
            height: 10px;
            object-fit: contain;
        }
        &.lobby {
            height: 22px;
            width: 28px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            & img {
                height: 9px;
            }
            &.lobbyPrev {
                left: 0;
                @include xl {
                    left: -20px;
                }
            }
            &.lobbyNext {
                right: 0;
                @include xl {
                    right: -20px;
                }
            }
        }
    }
}

.sectionSpacing {
    @include lg {
        padding-bottom: 25px !important;
    }
}

.bannerImgRow {
    max-width: 250px;
    @include sm {
        max-width: unset;
    }
}


// sports card 
.sportsCard {
    cursor: pointer;
    & .sportsImg{
        width: 100%;
        height: auto;
        border: 1px solid transparent;
        border-radius: 10px;
        overflow: hidden;
        &:hover {
            border-color: #999999;
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

// banner
.banner{
    background-color: $theme4;
    border-radius: 8px;
    padding: 15px;
    @include lg {
        margin-bottom: 35px !important;
    }
    & .bannerHeading{
        font-size: 10px;
        font-weight: bold;
        color: white;
        @include lg {
            font-size: 16px;
            line-height: normal;
        }
    }

    & img {
        height: 12px;
        object-fit: contain;
        @include lg {
            height: 20px;
        }
    }
    & .depositBtn{
        font-size: 11px;
        color: black;
        font-weight: 600;
        background-color: $theme3;
        border: 0;
        border-bottom: 4px solid #318120;
        border-radius: 6px;
        padding-block: 6px;
        padding-inline: 12px;
        font-weight: 600;
        @include lg {
            border-radius: 4px;
            padding-inline: 25px;
            font-size: 14px;
        }
    }
}

// popular card
.popularcardsWidth{
    width: 110px;
    @include lg{
        width: 140px;
        // width: 160px;
    }
}
.popularCard{
    border-radius: 10px;
    overflow: hidden;
    background-color: #1C202B;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: 0px 4px 4px 0px var(--LayoutcontentColor);
    // width: 110px;
    // @include lg{
    //     width: 198px;
    // }
    @include max-md{
    border-radius: 8px;
    box-shadow: 0px 3.17px 3.17px 0px var(--LayoutcontentColor);
    }
    &:hover {
        transform: translateY(-5px);
    }
    & .popularImg{
        aspect-ratio: 0.74;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
        }
        & .spanTxt{
            font-size: 10px;
            color: white;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $theme8;
            border-radius: 8px 0 8px 0;
            padding: 2px 4px;
            & span{
                font-size: 10px;
                color: $theme5;
            }
            & img{
                width: 12px;
                height: 13px;
                object-fit: contain;
                margin-right: 4px;
            }
        }
    }
    & .popularTxt{
        color: var(--Off-white-text-color, #D4D4D4);
        font-weight: 500;
        border: 1px solid var(--Strok-Color, #2F364C);
        border-top: none;
        border-radius: 10px;
        border-top-left-radius:0px;
        border-top-right-radius:0px;
        font-size: 12px;
        text-align: center;
        padding-block: 4px;
        @include max-md{
            font-size: 9.51px;
           padding-block: 6px;
           border-radius: 8px;
        }
        @include lg {
        padding-block: 10px;
        }
    }
}

.casinoTabOptionsWrapper {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        height: 5px;
        width: 100%;
        background-color: $theme7;
        border-radius: 8px;
        bottom: 8px;
    }
    @include lg {
        &::before {
            display: none;
        }
    }
    & .casinoTabOptions {
        // padding-bottom: 15px;
        & .casinoTabOption {
            font-size: 13px;
            font-weight: 600;
            color: $theme5;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            @include lg {
                font-size: 15px;
                color: white;
                padding-inline: 25px;
                height: 44px;
                border-radius: 5px;
            }
            & img{
                width: 15px;
                height: 15px;
                object-fit: contain;
                margin-right: 5px;
                @include lg {
                    display: none;
                }
            }
            &.active {
                color: white;
                & img{
                    filter: brightness(100);
                }
                @include lg {
                    background-color: $theme8;
                    &::before {
                        display: none;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -7px;
                    height: 5px;
                    width: 100%;
                    background-color: $theme10;
                    border-radius: 8px;
                }
            }
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
}


.betTableOptionWrapper {
    & > .col-auto {
        &:last-child {
            & .betTableOption {
                margin-right: 0;
            }
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
    & .betTableOption {
        font-size: 12px;
        font-weight: 600;
        color: $theme5;
        cursor: pointer;
        padding: 7px 15px;
        border-radius: 3px;
        margin-right: 5px;
        &:is(:hover, .active) {
            color: white;
            background-color: $theme8;
        }
        @include lg {
            font-size: 13px;
            padding: 7px 20px;
        }
    }
}

.betTable {
    & table {
        border-collapse: separate;
        border-spacing: 0 5px;
        & > * {
            border: 0;
        }
        & thead {
            & th {
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                background-color: $theme8;
                white-space: nowrap;
                color: $theme5;
                padding: 10px 17px;
                border: 0;
                text-align: start;
                @include lg {
                    font-size: 14px;
                    background-color: transparent;
                }
                &:first-child {
                    border-radius: 3px 0 0 3px;
                }
                &:last-child {
                    border-radius: 0 3px 3px 0;
                }
            }
        }
        & tbody {
            & td {
                font-size: 12px;
                font-weight: 500;
                background-color: $theme8;
                white-space: nowrap;
                color: $theme5;
                padding: 10px 17px;
                border: 0px;
                @include lg {
                    font-size: 14px;
                }
                &:first-child {
                    border-radius: 3px 0 0 3px;
                }
                &:last-child {
                    border-radius: 0 3px 3px 0;
                }
                & .gameImg {
                    
                    & img{
                        height: 40px;
                        width: 40px;
                        border-radius: 10px;
                    }
                   
                }
                & .gameName {
                    color: white;
                }
                &.activeVal {
                    color: $theme3;
                }
            }
        }
        // & .mobHiddenTableData {
        //     @include max-lg {
        //         display: none;
        //     }
        // }
    }
    @include lg {
        margin-top: 12px;
    }
}

.searchResultBox {
    position: absolute;
    width: 100%;
    padding: 12px;
    background-color: #212632;
    z-index: 89999;
    border-radius: 6px;
    max-height: 300px;
    overflow-y: auto;

    & .searchResultCard {
      cursor: pointer;
      margin-bottom: 10px;

      & .searchResultImg {
        width: 60px;
        height: 87px;
        overflow: hidden;
        border-radius: 6px;
        margin-right: 12px;
      }

      & .searchGameName {
        font-size: 16px;
        font-weight: 500;
        color: white;
      }

      & .searchProviderName {
        font-size: 14px;
        font-weight: 500;
        color: #b2b6c5;
      }

      & .searchGameCat {
        & .searchCatPill {
          font-size: 12px;
          font-weight: 500;
          padding: 3px 8px;
          color: black;
          border-radius: 3px;
          background-color: $theme3;
        }
      }
    }
  }


  .gamePageLoadMoreBtn {
    background-color: $theme8;
    border: 0;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: normal;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    padding: 12px 31px;
    min-width: 145px;
  }
  
  @media (max-width: 767px) {
    .gamePageLoadMoreBtn {
      font-size: 12px;
    }
  }

  .text-capitalize{
    text-transform: capitalize;
  }

.casino-card-img{
    height: 180px;
    border-radius: 5px;
    overflow: hidden;
    @include max-md{
    height: 155px;
    }
}