@import url("https://fonts.googleapis.com/css2?family=Girassol&display=swap");

@mixin animationDelay {
  animation-delay: 0.6s;
}

@mixin animationBlink {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  background-color: white;
  animation: blink 1.2s linear infinite;
  @media (max-width: 450px) {
    height: 8px;
    width: 8px;
  }
}

@keyframes blink {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: none;
  }
  51% {
    box-shadow: 0px 0px 10px 6px #fff;
  }
  100% {
    box-shadow: 0px 0px 10px 6px #fff;
  }
}

.spinDialog {
  --bs-modal-width: 460px !important;
  & .modal-content {
    background-color: #15191F;
    & .dealWheelInner1 {
      border: 6px solid #eeb90b;
      border-radius: 50%;
      aspect-ratio: 1;
      width: 370px;
      height: 370px;
      position: relative;
      &::after {
        content: '';
          @include animationBlink();
          @include animationDelay();
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
      }
      &::before {
        content: '';
          @include animationBlink();
          top: -7px;
          left: 50%;
          transform: translateX(-50%);
      }
      & .dealWheelInner2 {
        height: 100%;
        width: 100%;
        border: 6px solid #bfb775;
        border-radius: 50%;
        position: relative;
        &::after {
          content: '';
            @include animationBlink();
            top: 17%;
            left: 89%;
            transform: translateY(-50%);
        }
        &::before {
          content: '';
            @include animationBlink();
            top: 15%;
            left: 9%;
            transform: translateY(-50%);
        }
        & .dealWheelInner3 {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 6px solid #000;
          position: relative;
          &::after {
            content: '';
              @include animationBlink();
              @include animationDelay();
              top: 37%;
              right: -14px;
              transform: translateY(-50%);
          }
          &::before {
            content: ''; 
              @include animationBlink(); 
              @include animationDelay();
              top: 33%;
              left: -12px;
              transform: translateY(-50%);
          }
          & .dealWheelInner4 {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 4px solid #eeb90b;
            position: relative;
            &::after {
              content: '';
                @include animationBlink();
                @include animationDelay();
                bottom: 38px;
                right: 20px;
                transform: translateY(-50%);
            }
            &::before {
              content: '';
                @include animationBlink();
                @include animationDelay();
                bottom: 34px;
                left: 21px;
                transform: translateY(-50%);
            }
            & .dealWheelInner5 {
              height: 100%;
              width: 100%;
              border-radius: 50%;
              border: 2px solid #000;
              position: relative;
              &::after {
                content: '';
                  @include animationBlink();
                  bottom: -73px;
                  left: 60.2%;
                  transform: translateY(-50%);
              }
              &::before {
                content: '';
                  @include animationBlink();
                  bottom: -73px;
                  left: 20.4%;
                  transform: translateY(-50%);
              }
              & .dealWheelInner6 {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                border: 6px solid #fff;
                position: relative;
                &::before {
                  content: '';
                  @include animationBlink();
                  @include animationDelay();
                  top: -13px;
                  left: 76px;
                }
                &::after {
                  content: '';
                  @include animationBlink();
                  @include animationDelay();
                  top: -13px;
                  right: 76px;
                }
              }
              & .dealWheelInner7 {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                border: 4px solid #000;
                position: relative;
                &::before {
                  content: '';
                  @include animationBlink();
                  bottom: -18px;
                  left: 72px;
                }
                &::after {
                  content: '';
                  @include animationBlink();
                  bottom: -18px;
                  right: 72px;
                }
                & .dealWheelInner8 {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  position: relative;
                  &::before {
                    content: '';
                    @include animationBlink();
                    bottom: 100px;
                    left: -28px;                
                  }
                  &::after {
                    content: '';
                    @include animationBlink();    
                    bottom: 100px;
                    right: -28px;
                
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

* {
  box-sizing: border-box;
}

// html,
// body {
//   height: 100%;
// }

// body {
//   display: grid;
//   place-items: center;
//   overflow: hidden;
// }

.deal-wheel {
  // --size: clamp(250px, 80vmin, 700px);
  // --size: clamp(200px, 70vmin,500px);
  --size: clamp(200px, 70vmin,360px);
  --lg-hs: 0 3%;
  --lg-stop: 50%;
  --lg: linear-gradient(
      hsl(var(--lg-hs) 0%) 0 var(--lg-stop),
      hsl(var(--lg-hs) 20%) var(--lg-stop) 100%
    );
  
  position: relative;
  // display: grid;
  // grid-gap: calc(var(--size) / 20);
  align-items: center;
  // grid-template-areas:
  //   "spinner"
  //   "trigger";
  // font-family: "Girassol", sans-serif;
  display: flex;
  justify-content: center;
  font-size: calc(var(--size) / 21);
  line-height: 1;
  text-transform: lowercase;
}

.deal-wheel > * {
  grid-area: spinner;
}

.deal-wheel .btn-spin {
  // grid-area: trigger;
  // justify-self: center;
}

.spinner {
  margin: 0;
  padding: 0;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-areas: "spinner";
  width: 100%;
  height: 100%;
  // width: var(--size);
  // height: var(--size);
  // transform: rotate(calc(var(--rotate) * 1deg));
    transform: rotate(calc(var(--rotate, 25) * 1deg));
  border-radius: 50%;
  box-shadow: inset 0 0 0 calc(var(--size) / 40) hsl(0deg 0% 0% / 0.06);
}


.spinner * {
  grid-area: spinner;
}

.prize {
  position: relative;
  display: flex;
  align-items: center;
  // padding: 0 calc(var(--size) / 6) 0 calc(var(--size) / 20);
  padding: 0 0 0 8px;
  width: 50%;
  height: 50%;
  transform-origin: center right;
  transform: rotate(var(--rotate));
  user-select: none;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  // background-color: orange;
}

.prize img {
  height: 25px;
  object-fit: contain;
  margin-right: 5px;
}
.prize text {
  text-shadow: 1px 1px 0 #000;
}
// .prize::after {
//   content: '';
//   position: absolute;
//   height: 12px;
//   width: 12px;
//   border-radius: 50%;
//   background-color: red;
//   left: 150px;
//   top: 223px;
// }

.prize .dotSpan {
  height: 0px;
  z-index: 10000;
  width: 0px;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: rotate(var(--val));
}
.prize .dotSpan::after {
  content: '';
    height: 16px;
    width: 16px;
    // background: black;
    background-image: url(./../../assets/img/silverBall.png);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    position: absolute;
    z-index: 999;
    right: -10px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    transform-origin: right;
    transform: translateX(-150px);
}

.prize .dotSpan::before {
  content: "";
  height: 5px;
  width: 150px;
  position: absolute;
  z-index: 997;
  left: -7px;
  top: -5px;
  bottom: 0px;
  margin: auto;
  transform-origin: right;
  transform: translateX(-150px);
  // background: linear-gradient(0deg, #0000007d, transparent);
  background: linear-gradient(0deg, #0000007d, transparent);

}


.cap {
  --cap-size: calc(var(--size) / 4);
  position: relative;
  justify-self: center;
  width: var(--cap-size);
  height: var(--cap-size);
}

/* Hide select dropdown from SVG import file */
.cap select {
  display: none;
}

.cap svg {
  width: 100%;
}

.ticker {
  // position: absolute;
  // height: 60px;
  // width: 32px;
  // top: -37px;
  // left: 50%;
  // transform: translateX(-50%) rotate(0deg);
  // z-index: 1;
  // transform-origin: center left;
  position: absolute;
    height: 60px;
    width: 32px;
    top: 50%;
    left: -10px;
    transform: translateY(-50%) rotate(270deg);
    z-index: 1;
    transform-origin: center left;
}

.ticker img {
  height: 100%;
  width: 100%;
  object-fit: contain ;
}

.btn-spin {
  // position: absolute;
  // background: transparent;
  // border: none;
  // top:165px;
  // right: 244px;
  // color: hsl(0deg 0% 100%);
  // background: var(--lg);
  // border: none;
  // font-family: inherit;
  // font-size: inherit;
  // line-height: inherit;
  // text-transform: inherit;
  // padding: 0.9rem 2rem 1rem;
  // border-radius: 0.25rem;
  // cursor: pointer;
  // transition: opacity 200ms ease-out;
  position: absolute;
  background: transparent;
  border: none;
  top: 50%;
  left: 50%;
  width: 140px;
  transform: translate(-50%, -50%);
  transition: opacity 200ms ease-out;
  height: 140px;
  padding: 0;
  transition: 0.3s ease;
}

.btn-spin:not(:disabled) .tapSpinImg{
  animation: tapSpinImg 3s linear infinite;
}

@keyframes spinBtnAnimate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.btn-spin:not(:disabled) .spinBtnImg{
  animation: spinBtnAnimate 3s linear infinite;
}

@keyframes tapSpinImg {
  0% {
    transform: translate(-50%, -50%) scale(0.7) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.85) rotate(25deg);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.7) rotate(0deg);
  }
}

.btn-spin .tapSpinImg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  transition: 0.3s ease;
}

.btn-spin .spinPointerImg {
  position: absolute;
  // width: 85%;
  height: 100px;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%);
  object-fit: contain;
  transition: 0.3s ease;
}

// .btn-spin:focus {
//   outline-offset: 2px;
// }

// .btn-spin:active {
//   transform: translateY(1px);
// }

.btn-spin:disabled {
  cursor: progress;
  // opacity: 0.25;
}

/* Spinning animation */
.is-spinning .spinner {
  transition: transform 5s cubic-bezier(0.1, -0.01, 0, 1);
}

// .is-spinning .ticker {
//   animation: tick 700ms cubic-bezier(0.34, 1.56, 0.64, 1);
// }

@keyframes tick {
  40% {
    transform: rotate(-12deg);
  }
}

/* Selected prize animation */
.prize.selected .text {
  color: white;
  animation: selected 800ms ease;
  font-size: 16px;
}

@keyframes selected {
  25% {
    transform: scale(1.25);
    text-shadow: 1vmin 1vmin 0 hsla(0 0% 0% / 0.1);
  }
  40% {
    transform: scale(0.92);
    text-shadow: 0 0 0 hsla(0 0% 0% / 0.2);
  }
  60% {
    transform: scale(1.02);
    text-shadow: 0.5vmin 0.5vmin 0 hsla(0 0% 0% / 0.1);
  }
  75% {
    transform: scale(0.98);
  }
  85% {
    transform: scale(1);
  }
}

.spinModalBody {
  background-image: url(./../../assets/img/spinBg2.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  background-position: center;
}

.spinModalFooter {
  background-color: #212632 !important;
  border: 0 !important;
  & .bonusTotal {
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
  & .bounsTtlPrz {
    color: #EFB90B;
    font-size: 20px;
    font-weight: 700;
  }
  & .usrName {
    color: #B2B6C5;
  font-size: 16px;
  font-weight: 600;
  }
  & .usrWin {
    color: white;
    font-size: 16px;
    font-weight: 600;
    & span {
      color: #B2B6C5;
    }
  }
  & .cashBtn {
    color: black;
    font-size: 16px;
    font-weight: 700;
    background: #EFB90B; 
    border: 0;
    border-radius: 8.79px;
    height: 50px;
    padding-inline: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      margin-left: 5px;
    }
  }
  & .borderDiv {
    border-right: 1px solid #EFB90B;
  }
}

.spinHead {
  text-align: center;
  & .spinWinTxt {    
    color: white;
    font-size: 30px;
    font-weight: 700;
    line-height: 100%;
    position: relative;
    display: inline-block;
    & .leftImg {
      position: absolute;
      height: 50px;
      object-fit: contain;
      left: -45px;
      top: -25px;
    }
    & .rightImg {
      position: absolute;
      height: 50px;
      object-fit: contain;
      right: -65px;
      bottom: -25px;
    }
  }
  & .spinBtcTxt {
    color: #EFB90B;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
  }
}

.closeSpinBtn {
  background-color: transparent;
  padding: 0;
  position: absolute;
  top: -16px;
  right: 20px;
  & img {
    height: 18px !important;
    width: 18px;
    object-fit: contain;
  }
}

@media (max-width: 450px) {
  .dealWheelInner1 {
    width: 330px !important;
    height: auto !important;
    min-width: 300px;
    aspect-ratio: 1;
    border-width: 5px !important;
    &::before {
      top: -7px !important;
    }
    &::after {
      bottom: -6px !important;
    }
    & .dealWheelInner2 {
      border-width: 5px !important;
      &::after {
        // left: 84.3% !important;
        left: 90%;
      }
      &::before {
        // left: -3.2% !important;
      }
      & .dealWheelInner3 {
        border-width: 5px !important;
        &::after {
          // left: 92.6% !important;
          right: -12px !important;
        }
        & .dealWheelInner4 {
          border-width: 5px !important;
          &::before {
            bottom: 44px !important;
            left: 7px !important;
          }
          &::after {
            bottom: 39px !important;
            right: 12px !important;
          }
          & .dealWheelInner5 {
            border-width: 3px !important;
            &::before {
              bottom: -76px !important;
              left: 19.5% !important;
            }
            & .dealWheelInner6 {
              border-width: 5px !important;
              &::after {
                top: -12.3px !important;
                right: 63px !important;
              }
              &::before {
                top: -14px !important;
                left: 66px !important;
              }
              & .dealWheelInner7 {
                border-width: 3px !important;
                &::after {
                  bottom: -14px !important;
                  right: 57px !important;
                }
                &::before {
                  bottom: -14px !important;
                  left: 55px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .btn-spin {
    width: 125px !important;
    height: 125px !important;
  }
  .prize img {
    height: 20px;
  }
  .prize {
    font-size: 12px;
  }
  .ticker {
    height: 55px;
    width: 27px;
  }
  .prize .dotSpan::after {
    transform: translateX(-122px) !important;
    height: 14px;
    width: 14px;
    right: 4px !important;
  }
  .prize .dotSpan::before {
    left: 11px;
  }
}

.bankModal .modal-body.showBuyCrypto.spinModalBody {
  padding-inline: 0 !important;
}

@media (max-width: 575px) {
  .spinModalFooter {
    & .bonusTotal {
      font-size: 12px;
    }
    & .bounsTtlPrz {
      font-size: 16px;
    }
    & .usrName {
    font-size: 12px;
    }
    & .usrWin {
      font-size: 12px;
    }
    & .cashBtn {
      font-size: 12px;
      width: 100%;
      height: 40px;
      padding-inline: 8px;
      & svg {
        margin-left: 5px;
      }
    }
    & .borderDiv {
      border-right: 1px solid #EFB90B;
    }
  }
  .spinHead {
    & .spinWinTxt {    
      font-size: 20px;
      & .leftImg {
        height: 40px;
      }
      & .rightImg {
        height: 40px;
      }
    }
    & .spinBtcTxt {
      font-size: 32px;
    }
  }
  .bankModal .modal-body.showBuyCrypto.spinModalBody {
    padding-inline: 8 !important;
  }
}


.winnerSection {
  position: absolute;
  // top: 50%;
  // left: 40px;
  // transform: translateY(-50%);
  // height: calc(var(--sizeeee) * 2);
  // width: 150px;
  // background-color: rgba(red, 0.4);
  // clip-path: polygon(0 0, 100% 25%, 100% 75%, 0% 100%);
}


.spinWinnerModal {
  & .modal-content {
    background-color: transparent;
    & .modal-body {
      position: relative;
      padding: 140px;
      & .winnerBackImg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: translate(-50%, -50%);
      }
    }
    & .winnerInnerBox {
      padding: 25px 32px;
      background: linear-gradient(180deg, #212632 0%, #0D111A 100%); 
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.70);
      border-radius: 8px;
      position: relative;
      z-index: 1;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        height: 200px;
        opacity: 0.1; 
        background: #EFB90B; 
        box-shadow: 302.20001220703125px 302.20001220703125px 302.20001220703125px ; 
        border-radius: 9999px; 
        filter: blur(30.20px);
        z-index: -1;
      }
      & .congTxt {
        color: white;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.5px;
        margin-bottom: 8px;
      }
      & .congMsg {    
        color: white;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
      }
      & .congPrz {
        color: transparent;
        font-size: 55px;
        font-style: italic;
        font-weight: 700;
        text-align: center;
        margin: 25px 0 15px;
        background: linear-gradient(180deg, #f4cd4d, #faecbf);
        background-clip: text;
        filter: drop-shadow(2px 2px 0px #EFB90B);

      }
      & .claimBtn {
        color: black;
        font-size: 18px;
        font-weight: 600;
        background: #EFB90B;
        border: 1px solid #EFB90B;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        width: 100%;
        height: 40px;
      }
      & .coinGroupImage {
        width: 85px;
        position: absolute;
        top: -30px;
        left: -40px;
        object-fit: contain;
        z-index: -1;    
      }
      & .coinGroupImage2 {
        width: 87px;
        position: absolute;
        top: -30px;
        right: -40px;
        object-fit: contain;
        z-index: -1;    
      }
    }
  }
}

@media (max-width: 575px) {
  .spinWinnerModal {
    & .modal-content {
      & .modal-body {
        & .winnerBackImg {
          display: none;
        }
      }
      & .winnerInnerBox {
        &::before {
        }
        & .congTxt {
          font-size: 26px;
        }
        & .congMsg { 
          font-size: 16px;   
        }
        & .congPrz {
          font-size: 48px;
          margin: 20px 0 10px;
        }
        & .claimBtn {
          font-size: 16px;
          height: 40px;
        }
        & .coinGroupImage {  
          width: 75px;
          top: -25px;
          left: -25px;
        }
        & .coinGroupImage2 {  
          width: 75px;
          top: -25px;
          right: -25px;  
        }
      }
    }
  }
}

