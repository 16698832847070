    .heading{
        font-size: 18px;
        font-weight: 600;
        line-height: 32.5px;
        text-align: left;
        @include sm{
            font-size: 25px;
                }
}
.space{
    padding: 15px !important;
    @include md{
        padding: 25px !important;
            }
     @include xl{
        padding: 35px !important;
           }        

}
.imgbtn{
  height: 41px;
  width: 155px;
  border: none;
  box-shadow: none;
  padding-inline: 33px;
  background-color:$theme3 ;
    border-bottom: 3px solid #318220;
    border-radius: 5px; 
    display: flex;
    justify-content: center;
    align-items: center;
    .enaimg{
        width: 100px;
        margin-top: 4px;
    }          
}
.text-border{
    -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.imgIcon{
    width: 20px; 
    aspect-ratio: 1/1;
    object-fit: cover;


}
.auth_title{
    font-size: 15px;
    font-weight: 600;
    line-height: 19.5px;
    }
.commenBtn1{
    font-size: 13px;
    line-height: 16px;
    color: white;
    font-weight: 500;
    border-bottom: 3px solid #318120 !important;
    background-color: $theme6;
    border: 0;
    box-shadow: none;
    padding-block: 10px;
    width: 160px;
    border-radius: 5px;
    white-space: nowrap;
}
.img_none{
    width: 32px;
}
.detail-text{
font-size: 12px;
font-weight: 500;
line-height: 1.5;
text-align: left;
color: $theme5;
& h1, h2, h3, h4, h5, h6 {
    color: white !important;
}
& a {
    color: $theme5 !important;
    &:hover {
        color: white !important;
    }
} 
@include sm{
    font-size: 16px;
}

}

.signupBtn{
    background-color: $theme3;
    color: white;
    border:none;
    border-radius: 3px;
    font-weight: 600;
    border-bottom: 3px solid #318220;
    padding: 5px 22px;
    font-size: 14px;
    @include lg {
        padding:5px 38px;
        font-size: 16px;
        line-height: 28px;
        border-radius: 5px;
        border-bottom: 5px solid #318220;
    }
    & .mobsign{
    
        padding: 1px 10px;
        background-color: $theme3;
        color: white;
        border:none;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 28.5px;
        border-bottom: 3px solid #318220;
    }
  
}
.detail-bold{
    font-size: 12px;
    font-weight: 600;
    // line-height: 35px;
    text-align: left;
    @include sm{
        font-size: 16px;
            }
}
.title{
    & .ticon{
       img{
        width: 20px;
        height: 20px;
        margin-inline-end: 10px;
       }
    }
    & .text{
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
        text-align: left;

    }

}

.promotion-banner{
    background-image: url(./../images/promotionbannerMob.png);
    padding: 4px;
   width: 100%;
   background-size: 65%;
   background-repeat: no-repeat;
   aspect-ratio: 3.73;
   display: flex;
   align-items: center;
   justify-content: end;
   border-radius: 10px;
//    margin-block:10px;
   object-fit: cover;
   max-height: 320px;
   &.loggedIn {
    @include max-sm {
    background-image: url(./../images/promotionbannerMob2.png);
    background-size: 100%;
    }
   }
@include sm {
    background-image: url(./../images/promotionbanner.png);
    background-size: cover;
   min-height: 120px;
   &.loggedIn {
    background-image: url(./../images/promotionBanner2.png);
   }

}
}
.tab-holder{
    & .tab{
        border: 1px solid $theme8;
        border-bottom: 3px solid $theme8;
        border-radius: 5px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        width: 210px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $theme5;
        @include md {
            font-size: 16px;
            height: 42px;
        }
        &.active {
            color: white;
            &.casinoTab {
                border-color: $theme13;
            }
            &.sportsTab {
                border-color: $theme12;
            }
        }
    }
} 
.promotion-card {
    
    & .card-outer{
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        & .p_image{
            width: 100%;
            border-radius:10px 10px 0 0;
            overflow: hidden;
            aspect-ratio: 1/.5;
           & .image{
            width: 100%;
            height: 100%;
            object-fit: cover;
           }
        }
       & .contain{ 
        padding: 15px 11px;
        background-color: $theme8;
        border-radius:0 0 10px 10px;
        @include xxl {
            padding: 15px 10px;
        }
        & .left_contain{
            width: 65%; 
            & .time{
                // font-size: 14px;
                font-size: clamp(12px, calc(0.9vw + 1px), 16px);
                font-weight: 600;
                line-height: 1.1;
            }
            & .title{
                font-size: 12px;
                font-weight: 500;
                color: $theme5;
                text-align: left;
            }
        }
    & .right_contain{
        width:35%;
        
        & .bg-image{
            width: 100%;
            position: relative;
            cursor: pointer;
            img{
                width: 100%;
            }
            .btntext{
                white-space: nowrap;
                position: absolute;
                font-size: 12.5px;
                font-weight: 600;
                line-height: 20.8px;
                text-align: center;
                color: black;
                top: 50%;
                transform: translatey(-50%) translateX(-50%);
                left: 50%;
                @include md {
                    font-size: 14px;
                }
            }
            
        }
    }
       }
      & .tag-btn{
        position: absolute;
        top: -8px;
        left: -8px;
        width: 70px;
        height: 37px;
        @include md {
            width: 75px;
            height: 40px;
        }
        .tag-text{
            position: absolute;
            top: 52%;
            left: 54%;
            transform: translatey(-50%) translateX(-50%);
            color: black; 
            font-size: 12px;
            font-weight: 600;
            line-height: normal;
            @include md {
                font-size: 14px;
            }
           }
       }
      
    }
} 
.sports{
.casinoimg{
    display: none;
   
    }
    .disableimg{
        display: none;
    }
    
.btntext{
    color: white !important;
    }
    .tag-text{
        color: white !important;
    }
}

.casino{
    .sportimg{
       display: none;
    }
    .disableimg{
        display: none;
    }
}
.disabled{   
     .sportimg{
    display: none;
 }
 .casinoimg{
     display: none;
}
.disableimg {
    display: block;
}
.btntext{
color: white !important;
}
.tag-text{
    color: white !important;
}
}
.blur{
    position: relative;

    ::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.041);
        top: 0;
        left: 0;
    }
}
.btn-2{
    border: none;
    border-radius: 5px;
    padding: 8px 35px;
    color: white;
    font-size: 14px;
    height: 40px;
    line-height: 23.4px;
    font-weight: 600;
    background-color: $theme8;
    // margin-bottom: 20px;
}

.commonProBtn.navbtn{
    background-color: $theme8;
    width: max-content;
    margin-block: 10px;
    padding: 6px 14px;
    border-radius: 7px;
    display: flex;
    align-items: center;
  padding-bottom: 5px;
  &.mobTrans {
    @include max-md {
        background-color: transparent;
        padding: 0;
    }
  }
    
    & .btn-img1{
     width: 12px;  
    aspect-ratio: 1/1.2;
    object-fit: cover;

     @include sm{
         aspect-ratio: 1/1.2;
         width: 20px;
         top: 4px;
         }
    }
    & .btn-img2{
     width: 8px;
     aspect-ratio: 1/1;
     display: flex;
     @include lg{
     height: 10px;

     }
 }
     @include md{
         font-size: 20px;

    }
    & .promo{
         font-weight: 600;
         font-size: 13px;
         margin-top: 2px;
     @include lg{
         font-size: 18px;

     }

    }
    & .det{
         font-size: 12px;
         margin-top: 2px;
         font-weight: 500;
         text-align: left;
         @include lg{
             font-size: 16px;
         }

    }
 }
.containerSm{

    & .proBannerOuter {
        border-radius: 7px;
        @include lg {
        border-radius: 15px;
        }
    }

    & .jackpot{
    background-color: $theme8;
    border-radius: 9px;
    padding: 12px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    @include lg{
        padding: 20px;
        margin-block: 30px;
    background-color: $theme4;

    }
    & .jackpot-title{    
            font-size: 12px;
            font-weight: 600;
            
                @include lg{
                    font-size: 25px;
                    line-height: 39px;
                    }
                    @include xl{
                        font-size: 30px;

                        }
    }
    & .jackpot-text{
            font-size: 10px;
            font-weight: 500;
            line-height: 19.5px;
            text-align: left;
            @include lg{
            margin-block: 5px;
            font-size: 15px;

                }
    }
    & .calender{
        width: 15px;
        height: 14px;
        margin-inline-end: 8px;
        display: flex;
    }
    & .date{
        font-size: 10px;
        font-weight: 500;
        text-align: left;
        color: $theme5;
        @include lg{
            // margin-block: 12px;

                }

    }
    & .pro{ 
        width: 50%;
        @include lg {
            width: 40%;
        }
        .time{
            font-size: 9px;
            font-weight: 500;
            line-height: 26px;
            text-align: left;
            background-color: $theme1; 
            padding: 6px;
            border-radius: 5px;
            margin-bottom: 5px;
            justify-items: center;
            text-align: center;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-inline: 10px;
            @include sm{
                font-size: 12px;
            }
            @include md{
            padding-inline: 20px;
            height: 44px;
            font-size: 14px;
            background-color: $theme8; 
            }
        .bold{
            font-size: 8px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            @include md{
                font-size: 14px;
                }
            }

        }
        .prize{
            padding-inline: 10px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $theme1; 
            border-radius: 5px;
            margin-top: 5px;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            @include md{
            background-color: $theme8; 
                
            padding-inline: 20px;
            height: 44px;
            }
            
        }
        .total{
            font-size: 10px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            @include md{
                letter-spacing: 1px;
                font-size: 14px;
                line-height: 26px;
                }
            

        }
        .mony{
            font-size: 10px;
            font-weight: 600;
            line-height: 32.5px;
            color: $theme11;
            @include md{
                font-size: 20px;
                }
        }
    }
   .text-container{
    background-color: $theme4;
    padding: 10px;
    }

    }
    .text-container{
        background-color: $theme8;
        padding: 15px;
        border-radius: 15px;
        margin-bottom: 30px;
        @include lg{
            padding: 30px;
        background-color: $theme4;
        }
        }
    & .main-heading{
        font-size: 16px;
        font-weight: 600;
        line-height: 39px;
        text-align: center;
        margin-top: 20px;
        @include lg{
            font-size: 24px;
            margin-top: 40px;
        }
        
        }
        .cardHolder{
            // padding-inline: 10px;
            margin-top: 5px;
            display: flex;
            // gap: 10px;
            margin-bottom: 10px;
            margin-inline-end: auto;
             justify-content: center;
            @include lg{
            margin-top: 25px;
            margin-bottom: 25px;
                padding-inline: 80px;
                margin-inline-end: auto;
    
            }
            .gameCardouter{
                border-radius: 10px;
                overflow: hidden;
            }
            .cardImg{
                width: 100%;
                aspect-ratio: 1/1.1;
                object-fit: cover;
                background-color: $theme4;
            }
            .cardText{
                background-color: $theme4;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding-block:10px;
                font-size: 12px;
                font-weight: 600;
                line-height: 1.6;
                @include lg{
                    font-size: 14px;
                background-color: $theme8;
                }
                
            }
        }
        }
        .setting{
            background-color: $theme10;
            // margin-block: 10px;
            border-radius: 5px;
            & .set-text{
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                text-align: left;
            }
            & .form{
                padding-top: 30px;
            & .inputLabel{
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 19.5px;
                    text-align: left;
                    margin-bottom: 10px;
                }
            &    .emailGroup{
                display: flex;
                justify-content: space-between;
                gap: 18px;
                position: relative;
             & .inputUser{
                width: 100%;
                border-radius: 5px;
                border: none;
                background-color: $theme8;
                padding: 10px;
                color: white;
                text-align: 13px;
                &:placeholder-shown{
                & ~ .placeHolder{
                    display: block;
                }    
                }
                &:focus{
                    border: none;
                    outline: none;
                }
               
                & span{
                    color: #318120;
                }
           
                
             }
             & .placeHolder{
                caret-color: white !important;
                font-size: 13px;
                position: absolute;
                top: 50%;
                transform: translatey(-50%);
                left: 13px;
                display: none;
                & .star{
                    color: $theme6;
                }
               
            }
                }
            }
            & .auth{
               
                & .dis_text {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 19.5px;
                    color: $theme5;
                }
               
            }
            & .auth_text{
                font-size: 13px;
                font-weight: 400;
                line-height: 19.5px;
                color: $theme5;
                margin-block: 10px;
            }
            .betTable {
                & table {
                    border-collapse: separate;
                    border-spacing: 0 5px;
                    & > * {
                        border: 0;
                    }
                    & thead {
                        & th {
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            background-color: $theme8 !important;
                            white-space: nowrap;
                            color: $theme5;
                            padding: 10px 17px;
                            border: 0;
                            @include lg {
                                font-size: 14px;
                                background-color: transparent;
                            }
                            &:first-child {
                                border-radius: 3px 0 0 3px;
                            }
                            &:last-child {
                                border-radius: 0 3px 3px 0;
                            }
                        }
                    }
                    & tbody {
                        & td {
                            font-size: 12px;
                            font-weight: 500;
                            background-color: $theme8;
                            white-space: nowrap;
                            color: $theme5;
                            padding: 10px 17px;
                            border: 0px;
                            @include lg {
                                font-size: 14px;
                            }
                            &:first-child {
                                border-radius: 3px 0 0 3px;
                            }
                            &:last-child {
                                border-radius: 0 3px 3px 0;
                            }
                            & .gameImg {
                                height: 20px;
                                width: 20px;
                                overflow: hidden;
                                border-radius: 2px;
                                @include lg {
                                    display: none;
                                }
                            }
                            & .gameName {
                                color: white;
                            }
                            &.activeVal {
                                color: $theme3;
                            }
                        }
                    }
                    & .mobHiddenTableData {
                        @include max-lg {
                            display: none;
                        }
                    }
                }
                @include lg {
                    margin-top: 12px;
                }
            }
             & .cbtn{
                height: 38px;
                padding-inline: 25px;
                background-color: $theme8;
                font-size: 12px;
                font-weight: 600;
                border-radius: 5px;
                box-shadow: none;
                border: none;
                color: white;
                white-space: nowrap;
             }
             & .gBtn{
                background-color: $theme6 !important;
             }
             & .dataBtn{
                height: 50px;
                padding-inline: 40px;
                font-size: 14px;
                font-weight: 600;
                line-height: 23.4px;
                background:$theme8;
                border-radius: 5px;
                box-shadow: none;
                border: none;
                color: white;
             }
        }
       .provider{
        &    .icontbtn{
                display: flex;
                align-items: center;
                width: max-content;
                padding:6px 12px ;
                background: $theme8;
                border-radius: 5px;
                gap:   10px;
                .protext{
                    font-size: 21.23px;
                    font-weight: 600;
                    line-height: 27.6px;
                    text-align: left;
                }
            }
        &    .searchBar{
            margin-block: 10px;
                width: full;
                position: relative;
            }
            & .inputsearch{
                background-color: $theme8;
                width: 100%;
                border: none;
                padding: 7px 50px;
                border-radius: 5px;
                color: white;
                &:focus{
                    border: none;
                    outline: none;
                }

            }
            & .searchImg1{
                width: 20px;
                size: 20px;
                position: absolute;
                top: 50%;
                left: 20px;
                transform: translatey(-50%);
            }
            .gameCard{
                background-color: $theme8;
                display: flex;
                flex-direction:column;
                padding: 10px 20px;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
                & .gameImg{
                    width: 85%;
                    height: 38px;
                    flex: auto;
                }
                & .gameNum{
                    font-size: 11.97px;
                    font-weight: 600;
                    line-height: 15.56px;
                    text-align: left;
                    margin-top: 6px;
                    flex: 1 1 auto;
                    color: $theme5;
                    text-transform: capitalize;
                }
            }
        }
        .inputbox{
            width: 268px;
            padding-inline-end: 70px;
            background: $theme8;
            border-radius: 5px;
            position: relative;
            margin-block: 4px;




            .monyinput{
                display: flex;
                flex-direction: column;
                padding: 12px;
                width: max-content;
                position: relative;
                height: 66px;
    
                .mony{
                    background:$theme8;
                    border: none;
                    margin-top: auto;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 19.5px;
                    color: white;
                    width: 120px;
    
                    &:focus{
                        border: none;
                        outline: none;
                    }
                    .cross{
                        width: 12px;
                        height: 12px;
                        
                        &img{
                            cursor: pointer;
                        }
                    }
                    
                
                }
                .float{
                    position: absolute;
                    top: 7px;
                    font-size: 13px;
                    font-weight: 600;
                    color: #B2B6C5;
                 }
            }
            .max{
                position: absolute;
                width: 50px;
                height: 50px;
                right: 8px;
                top: 50%;
                background: $theme10;
                transform: translateY(-50%);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 600;
                cursor: pointer;
            }
        }


        .providerList{

            & .commonProBtn{
                & .arrowBtn{
                    height: 12.78px;
                    width: 12.78px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            & .imgNav{
                background-color: $theme10;
                border-radius: 5px;
              
                padding: 4px 25px;
                height: 37px;
                @include md{
                    padding: 7px 50px;
                    height: 41px;
                }
                & img{
                height: 20px;
                @include md{
                height: 25px;

                }
                }
            }
            & .drodwonProvider{
                & .dropBtn{
                    background-color: $theme10;
                    border-radius: 5px;
                    padding: 7px 22px 7px 22px;
                    color: $theme5;
                    border: none;
                    // width: 100%;
                    @include md{
                    padding: 11px 22px 11px 40px;

                    }
                    &::after{
                        color: white;
                    margin-left: 1.255em;

                    }
                }
                & .dropMenu{
                    background-color: $theme10;
                    border-radius: 5px;
                    padding: 5px;
                    & .dropItem{
                        color: $theme5;
                        font-size: 12px;
                        &:hover{
                            background-color: $theme8;
                        }
                    }
                }
            }
            & .customRow{
                & .customCol{
                    @include lg{
                        min-width: 124px !important;

                    }
                    & .providerListCard{
                        background-color: $theme10;
                        border-radius: 10px;
                        // width: 145px;
                        // // height: 200.05px;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        & img{
                            height: 22px;
                        }
                    }
                }
               
            }
        }

#MoreDetail{
    & .modal-content{
        background-color: #191D27;
        & .modal-header{
            border-bottom: 0 !important;
            padding-bottom: 0;
            & .modal-title{
                font-weight: 700;
                font-size: 18px;
            }
            & .btn-close{
                filter: invert(1);
            }
        }
        & .modal-body{
            & .deposite-rules{
                background: linear-gradient(90deg, #2F364C 0%, #0E95FA 100%);
                border-radius: 10px;
                padding: 0px 20px;
                &:not(:last-child){
                    margin-bottom: 15px;
                }

                & .deposit-number{
                    font-weight: 500;
                    font-size: 15px;
                }
                & .deposit-percentage{
                    font-weight: 700;
                    font-size: 18px;
                    color: #F8BA28;
                }
                & .min-deposit{
                    font-weight: 500;
                    font-size: 13px;
                    color: #B3B3BE;
                    
                }
            }
            & .detail-content-1{
                color: #B3B3BE;
                font-weight: 500;
                font-size: 15px;
            }
            & .detail-list{
                & li{
                    list-style-type: disc;
                    font-weight: 500;
                    font-size: 13px;
                    color: #B3B3BE;
                }
            }
        }
    } 

}    

.shine-bg{
    background: linear-gradient(180deg, #002789 0%, #191D27 100%);
    padding-block: 42px;
    padding-inline: 54px;
    .tittle-1{
        font-weight: 700;
        font-size: 39.56px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center;
        text-transform: uppercase;
        color: white;
    }
    .tittle-2{
        font-weight: 500;
        font-size: 25px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center;
        color: white;
        margin-top: 23px;
    }
    .money{
        font-weight: 700;
        font-size: 80px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center;
        margin-top: 67px;
        filter: drop-shadow(2px 2px 1px 0px #816200);
        background: -webkit-linear-gradient(180deg, #0E95FA 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }
    .claimClick{
        width: 359px;
        height: 70px;
        border-radius: 35px;
        background-color: #0E95FA;
        font-weight: 600;
        font-size: 25px;
        line-height: 100%;
        letter-spacing: 0%;
        color: white;
        box-shadow: 0px 3px 20px 0px #00000080;
        margin-inline: auto;
        margin-top: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 5;
        
    }
    .coinImage{
        position: absolute;
        width: 662px;
        height: 609px;
        top: -70px;
        left: -30px;
        
    }
    .shine{
        width: 786px;
        height: 786px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -4;
    }
}