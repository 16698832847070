// scss to put in stacking file

.cryptoHeroSection {
  & .stackingSocialBtn{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border: none;
    margin-right: 5px;
    & img {
    height: 100%;
    width: 100%;
    
    }

  }
  & .orTxt {
    font-size: 14px;
    font-weight: 600;
    color: #B1B6C6;
  }
}

.stackingCalculateModal {
  & .modal-dialog {
    max-width: 631px !important;
  }
  & .modal-content {
    background-color: #212632;
  }
  & .modal-header {
    border: 0;
  }
  & .modal-body {
    overflow-x: hidden;
  }
  & .modalTitle {
    font-size: 20px;
    font-weight: 600;
    color: white;
    @include lg {
      font-size: 22px;
    }
  }
  & .calculateCryptoEarning {
    padding: 15px;
    border-radius: 10px;
    background-color: #212632;
    overflow: hidden;
    position: relative;
    &::after {
      // content: '';
      // position: absolute;
      // top: 0;
      // right: 0;
      // height: 100%;
      // aspect-ratio: 1;
      // background-image: url(./assets/img/stacking/calculateBg.png);
      // background-repeat: no-repeat;
      // background-size: cover;
      // z-index: 1;
    }
    & .stackingTabs {
      background-color: #212632;
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      padding: 4px;
      @include max-lg {
          overflow-x: auto;
          width: 100%;
          &::-webkit-scrollbar {
            display: none;
          }
      }
      & .stackingTab {
        font-size: 14px;
        font-weight: 500;
        color: #B2B6C5;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37px;
        padding-inline: 22px;
        border-radius: 5px;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        white-space: nowrap;
        &.active {
          color: white;
          background: $theme14;
        }
        
        @include max-sm {
          font-size: 10px;
          padding-inline: 10px;
          border-radius: 5px;
        }
      }
    }
    & .commonBlueBtn {
      height: 35px;
      background-color: $theme14;
      border-bottom: 3px solid #388927 !important;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      padding-inline: 20px;
      @include lg {
        height: 45px;
        font-weight: 600;
        border-radius: 10px;
      }
      & .btnIcon {
        height: 16px;
        object-fit: contain;
        margin-right: 10px;
      }
      &.small {
        height: 35px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        
        @include max-sm {
          font-size: 12px;
          height: 30px;
        }
      }
    }
    & .sText {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      color: #B2B6C5;
      & .icon {
        height: 12px;
        object-fit: contain;
        cursor: pointer;
      }
      &.green {
        color: #5BB54F;
      }
      &.moreTxt {
        cursor: pointer;
        text-decoration: underline;
      }
      @include md {
        font-size: 14px;
      }
    }
    & .sTextBold {
      font-size: 18px;
      font-weight: 600;
      line-height: 29.5px;
      color: #ffffff;
      &.green {
        color: #5BB54F;
      }
      @include md {
        font-size: 22px;
      }
    }
    & .calculateCryptoEarningInner {
      position: relative;
      z-index: 3;
    }
    // & .stackingTabs {
    //   background-color: #2C303F;
    //   border-radius: 5px;
    //   & .stackingTab {
    //     border-radius: 5px;
    //     font-size: 13px;
    //   }
    // }
    & .termBox {
      height: 30px;
      background-color: #2E3342;
      border-bottom: 3px solid $theme1 !important;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline: 12px;
      font-size: 12px;
      font-weight: 500;
      color: #B2B6C5;
      // margin-right: 7px;
      cursor: pointer;
      @include max-sm {
        font-size: 10px;
        padding-inline: 10px;
        height: 28px;
      }
      &:is(.active, :hover) {
        background-color: $theme14;
        border-bottom: 3px solid #388927 !important;
        color: white;
      }
    }
    & .termBoxTxt {
      font-size: 18px;
      font-weight: 600;
      color: white;
      @include max-sm {
        font-size: 12px;
      }
    }
    & .subscribeBox {
      background-color: #1B1F27;
      padding: 15px;
      border-radius: 5px;
      @include max-sm {
        padding: 12px;
      }
    }

    & .calculateInput {
      & .usdtIcon {
        height: 28px;
        width: 28px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 17px;
        transform: translateY(-50%);
        border-radius: 100px;
        overflow: hidden;
        @include max-sm {
          height: 18px;
          width: 18px;
          left: 12px;
        }

      }
      & .form-control {
        border-radius: 5px;
        background-color: #15191F;
        height: 65px;
        font-size: 14px;
        font-weight: 600;
        color: #B2B6C5;
        border: 0 !important;
        box-shadow: none !important;
        padding-left: 60px;
        &::placeholder{
          font-size: 14px;
          font-weight: 600;
          color: #B2B6C5;
        }
        @include max-sm {
          height: 50px;
          font-size: 12px;
          padding-left: 40px;
        }
      }
      & .balance {
        position: absolute;
        top: 7px;
        right: 10px;
        @include max-sm {
          font-size: 10px;
        }
      }
    }
    & .cDrop {
      & .dropdown-toggle {
        background-color: #2C303F;
        border-radius: 5px;
        border: 0;
        height: 65px;
        display: flex;
        align-items: center;
        width: 100%;
        @include max-sm {
          height: 50px;
        }

        & .usdtIconBox{
          height: 28px;
          width: 28px;
          min-width: 28px;
          overflow: hidden;
          border-radius: 50px;
          margin-right: 7px;
          @include max-sm {
            height: 18px;
            width: 18px;
            min-width: 18px;
          }
          & img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            display: flex;
          }
        }
        & .sText {
          font-size: 12px;
          text-align: left;
          @include max-sm {
            font-size: 10px;
          }

        }
        & .sTextBold {
          font-size: 18px;
          text-align: start;
          text-transform: uppercase;
          @include max-sm {
            font-size: 12px;
            line-height: 12px;
          }

        }
        &::after {
          margin-left: auto;
        }
      }
      & .dropdown-menu {
        background-color: #2C303F;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        max-height: 185px;
        overflow-y: auto;
        @include max-sm {}
        & .dropdown-item {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          color: white;
          text-transform: uppercase;
          border-radius: 5px;
          padding: 7px;
          margin-bottom: 4px;
          @include max-sm {
            font-size: 12px;
          }

          &:last-child {
            margin-bottom: 0;
          }
          & img {
            height: 25px;
            width: 25px;
            min-width: 25px;
            object-fit: contain;
            margin-right: 8px;
          @include max-sm {
            height: 18px;
            width: 18px;
            min-width: 18px;
          }

          }
          &:is(:hover, .active) {
            background-color: #3B4155;
          }
        }
      }
    }
  
  }
  .charBox {
    & .stackingTabs {
      background-color: #2E3443;
      & .stackingTab {
        color: white;
        height: 32px;
        cursor: pointer;
      }
    }
  }
}

.stackingPage {
  & .tokenTableWrapper {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom , rgba(0,0,0,0), rgba(0,0,0,0), rgba(#647498, 0.5));
      border-radius: 10px;
      pointer-events: none;
      z-index: 8;
    }
    & .tokenTable {
        & thead {
          & tr {
            & th {
              background-color: #212632;
              color: #B2B6C5;
              font-size: 13px;
              font-weight: 500;
              padding: 13px 17px;
              // text-align: center;
              border-bottom: 1px solid #B2B6C51A;
              // border-left: 1px solid #B2B6C51A;
              @include max-lg {
                width: 33.33%;
                max-width: 33.33%;
                min-width: 33.33%;
              }
              @include max-sm {
                font-size: 10px;
                height: 42.4px !important;
              }
              & .tokenTableHeadImg {
                height: 17px;
                object-fit: contain;
                display: flex;
                margin: auto;
                &.small {
                  height: 16px;
                }
                @include max-sm {
                  height: 16px;
                  &.small {
                    height: 11px;
                  }
                }
              }
              &:nth-child(2) {
                background-color: #42B72A;
                color: white;
                position: relative;
                & img {
                  @include max-lg {
                    height: 16px;
                  }
                  @include max-sm {
                    height: 13px;
                  }
                }
                & .upperDiv {
                  position: absolute;
                  display: flex;
                  top: -19px;
                  left: 0;
                  width: 100%;
                  background-color: #42B72A;
                  height: 20px;
                  border-radius: 10px 10px 0 0;
                  @include max-lg {
                    display: none;
                  }
                }
              }
              &:nth-child(3) {
                @include max-lg {

                  border-left: 1px solid #42B72A;
                }
              }
              &:first-child {
                border-radius: 10px 0 0 0;
                border-left: 0;
              }
              &:last-child {
                border-radius: 0 10px 0 0;
              }
            }
          }
        }
        & tbody {
          & tr {
            & td {
              background-color: #212632;
              color: #B2B6C5;
              font-size: 14px;
              font-weight: 600;
              padding: 13px 17px;
              text-align: center;
              border-bottom: 1px solid #B2B6C51A;
              border-left: 1px solid #B2B6C51A;
              @include max-lg {
                width: 33.33%;
                max-width: 33.33%;
                min-width: 33.33%;
              }
              @include max-sm {
                font-size: 13px;
              }
              &:nth-child(2) {
                background-color: #42B72A;
                color: white;
              }
              &:first-child {
                border-left: 0;
              }
              & .tableTokenBodyImg {
                & img {
                  height: 22px;
                  width: 22px;
                  object-fit: contain;
                  margin-right: 8px;
                  @include max-sm {
                    height: 18px;
                    width: 18px;
                  }
                }
              }
            }
            &:last-child {
              & td {
                border-bottom: 0;
                &:first-child {
                  border-radius: 0 0 0 10px;
                }
                &:last-child {
                  border-radius: 0 0 10px 0;
                }
              }
            }
          }
        }
        & .mobHiddenTd {
          position: relative;
          &:not(.active) {
            @include max-lg {
              display: none;
            }
          }
          &.active {
            & .navBtns {
              @include max-lg {
                display: flex;
              }
            }
          }
          & .navBtns {
            position: absolute;
            padding-inline: 15px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            display: none;
            align-items: center;
            justify-content: space-between;
            @include max-sm {
              padding-inline: 10px;
            }
            & img {
              height: 15px;
              object-fit: contain;
              cursor: pointer;
              @include max-sm {
                height: 12px;
              }
            }
          }
        }
    }
  }
  & .tokenBanner {
    background: linear-gradient(to right, #212632, #42B72A);
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    & .earnImge{
      height: 115px;
      width: 200px !important;
      margin-top: 32px;
      @include sm{
        height: 129px;
        width: 246px !important;
        margin-top: 32px;
      }
    }
    & .speaker {
      position: absolute;
      bottom: 22px;
      right: -41px;
      height: 147.58px;
      width: 171px;
      @include sm{
        bottom: 76px;
      right: 8px;
      height:147.58px ;
      width: 167px;
      }
    }
    & .coin {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 40%;
      object-fit: contain;
    }
  }
}

.tooltipDropdown {
  & .dropdown-toggle {
    &::after {
      display: none;
    }
    &:hover {
      & ~ .dropdown-menu {
        display: block;
      }
    }
  }
  & .dropdown-menu {
    background-color: #212632;
    font-size: 10px;
    color: #B2B6C5;
    font-weight: 400;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
  }
}