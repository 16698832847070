.NotificationOffcanvas{
    background-color: $theme1;
    & .offcanvas-header{
        height: 48px;
        background-color: $theme10;
        & .offcanvas-title{
            font-size: 15px;
            font-weight: 500;
            color:white;
        }
        & .btn-close{
            filter: invert(1);
            font-size: 13px;
        }
    }
    & .offcanvas-body{
        & .notificationBtn{
            & li{
                width: 100%;
                & .tabsBtn{
                   
                    background-color: #2F364C;
                    color: #B2B6C5;
                    padding: 7px 15px;
                    border-radius: 50px;
                    font-size: 14px;
                    font-weight: 500;
                    color: $theme5;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    text-decoration: none;
                    text-wrap: nowrap;
                    cursor: pointer;
                    &.active{
                        background-color: #0E95FA;
                        color: white;
                    }
                }
            }
        }
        & .contentTabbs{
            background-color: $theme8;
            border-radius: 5px;
            padding: 14px;
            & .time{
                font-size: 11px;
                color: $theme5;
                font-weight: 400;
            }
            & .titlemain{
                font-size: 15px;
                color: white;
                font-weight: 500;
            }
            & .titlemain2{
                font-size: 13px;
                color: white;
                font-weight: 500;
            }
            & .WeeklybonusImg{
                height: 150px;
            }
            & .peranotif{
                font-size: 13px;
                color:$theme5;
                font-weight: 500;
            }
            & .showBtn{
                font-size: 13px;
                color:white;
                font-weight: 500;
                border: none;
                padding: 0;
                box-shadow: none;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                margin-left: auto;
                cursor: pointer;
                gap:10px;
                & .icon{
                    width: 10px;
                    height: 5.74px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    & img{
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            & .dambleHideTbs{
                padding-bottom: 10px;
                border-bottom: 0.5px solid #424966;
            }
            & .rightarrownoti{
                width: 7.2px;
                height: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                & img{
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }
    & .offcanvasfooter{
        background-color: $theme10;
        height: 25px;
    }
}


.notification_section{
width: 370px;
padding: 14px 22px;
background-color: #202733;
border-radius: 10px;
& .date{
font-size: 11px;
font-weight: 500;
color: #6A798C;
}
& .heading{
color: white;
font-size: 15px;
font-weight: 600;
line-height: 16.5px;
margin: 10px 0px; 
}
.notification_heading_image{
    height: 150px;
    width: 328px;
    border-radius: 10px;
    overflow: hidden;
    
    img{
        width: 100%;
    }
}
.heading_p{
    color:#6A798C ;
    font-size: 13px;
    font-weight: 500;
}
.show_btn{
    // text-align: end;
    color: white;
    font-size: 13px;
    background-color: transparent;
    border: none;
    outline: none;
}

.notification_ul{
    color: #6A798C;
    font-size: 12px;
    font-weight: 500;
}
.hide_all_div{
    border-top: 0.5px solid #424966;
    padding: 10px 10px 0px 0px;
    color: #C2FB01;
    font-size: 13px;
    font-weight: 600;
    display: flex ;
    justify-content: space-between;
    align-items: center;

   

   

}
.accordion-item {
    background: none !important;
    border: none !important;
}
.accordion-header{
    border-bottom:  0.2px solid  #424966 ;
}
.accordion-button {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    color: #C2FB01 !important;
    font-size: 13px !important;
    font-weight: 600;
    // padding-left: 0 !important;
  
    padding: 10px 0px !important;
    

    p{
        width: 225px;
    }
    span {
        transition: transform 0.3s ease;
        margin-left: 85px;
    }
   
}
.accordion-button::after {
    display: none !important;
}

.accordion-button:not(.collapsed) {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    color: #C2FB01 !important;
    font-size: 13px !important;
    font-weight: 600;
    padding-left: 0 !important;
 
   
}
.accordion-button:not(.collapsed)::after {
    display: none !important;
}
.accordion-button p {
    width: 225px;
    margin: 0;
}



.accordion-button:not(.collapsed) span {
    transform: rotate(90deg);
}

.accordion-body {
    background: none !important;
    border-top: none !important;
    color: black;
    padding: 0px;
}
.accordion-body p{
    color: #6A798C;
    font-size: 12px;
    font-weight: 500;
}

.accordion-button:focus {
    box-shadow: none !important;
}



}