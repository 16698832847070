
#nav-rate{
    & .bg_main_color{
      & .total-usdt{
        font-weight: 700;
        font-size: 20px;
        margin-top: 10px;
      }
      & .vip-table{
        & .vip-table-body{
          & .vip-table-row{
            position: relative;
            & .table-data{
              & img{
                padding-right: 4px;
              }
            }
            &::after{
              content: "";
              position: absolute;
              left: 100px;
              bottom: 12px;
              width: 36%;
              height: 1px;
              background: linear-gradient(90deg, rgba(57, 145, 210, 0) 0%, #1D4A6C 47.5%, rgba(57, 145, 210, 0) 100%);
            }
            &::before{
              content: "";
              position: absolute;
              right: 90px;
              bottom: 12px;
              width: 36%;
              height: 1px;
              background: linear-gradient(90deg, rgba(57, 145, 210, 0) 0%, #1D4A6C 47.5%, rgba(57, 145, 210, 0) 100%);
            }
            &:not(:first-child){
              margin-top: 20px;
            }
          }
        }
      }
      & #ViewRules{
        & .modal-header {
          background-color: #202733;
          border-bottom: 0 !important;
          padding: 15px 20px;
          & .modal-title {
            font-size: 18px;
            font-weight: 700;
          }
          & .custom-close-btn {
            background-color: #2f364c;
            position: absolute;
            right: 20px;
            & .btn-close {
              filter: invert(1);
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
            }
          }
        }
        & .modal-body {
          background-color: #191d27;
          padding: 15px 20px;
          & h6 {
            font-weight: 700;
            font-size: 15px;
          }
        }
      }
     
    }
  }

#nav-banner{
  & .bg_main_color{
    padding: 20px;
    & .download-banner-box{
      background-color: #191D27;
      border-radius: 10px;
      padding: 20px;
      & .download-banner-image{
        overflow: hidden;
        border-radius: 14px;
      }
      & .banner-heading{
        font-weight: 700;
        font-size: 20px;
        margin-top: 12px;
      }
      & .banner-content{
        font-weight: 400;
        font-size: 14px;
        color: #D4D4D4;
        margin-top: 12px;
      }
      & .banner-button{
        margin-top: 12px;
        border: 0;
        border-radius: 25px;
        border-top: 1.95px solid #3DACFF !important;
        box-shadow: 0px 0px 8.75px 0px #00000066;
        background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
        color: #fff;
        padding: 10px;
        width: 100%;
        font-size: 14px;
      }
    }
  }
}

 @media (max-width:850px) {
  .vip-table{
   & .vip-table-body{
     & .vip-table-row{
       &::after{
           left: 90px !important;
           width: 30% !important;
           bottom: 9px !important;
       }
       &::before{
        right: 80px !important;
        bottom: 12px !important;
        width: 30% !important;
       }
     }
   }
 }
}

@media (max-width:600px) {
  .vip-table{
   & .vip-table-body{
     & .vip-table-row{
       &::after{
           left: 90px !important;
           width: 20% !important;
           bottom: 9px !important;
       }
       &::before{
         right: 70px !important;
         width: 20% !important;
         bottom: 9px !important;
       }
     }
   }
 }
}

@media (max-width:450px) {
  .vip-table{
   & .vip-table-body{
     & .vip-table-row{
       &::after{
        left: 90px !important;
        width: 12% !important;
        bottom: 12px !important;
       }
       &::before{
        right: 70px !important;
        width: 12% !important;
        bottom: 12px !important;
       }
     }
   }
 }
}