.profileMainSection{

    @include xl{
    //   padding:   52px 44px 54px 31px;
    }



    & .sideContent{
        & .profileSideBarMenu{
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0;
            & .menuTab{
                font-size: 15px;
                color: white;
                font-weight: 500;
                display: flex;
                align-items: center;
                text-decoration: none;
                background-color: $theme10;
                border-radius: 5px;
                padding: 10px 40px 10px 15px;
                cursor: pointer;
                    border: 0.5px solid transparent;
                    min-width: 160px;
                @include xl{
                }
                &:is(:hover, .active){
                    background-color: $theme8;
                    color: white;
                    border: 0.5px solid $theme5;
                    & .menuIocn{
                        & img{
                            filter: brightness(9.5);
                        }
                    }
                }
                & .menuIocn{
                    height: 20px;
                    width: 20px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & img{
                        height: 100%;
                        width: 100%;
                        display: flex;
                        object-fit: contain;
                    }
                }
            }
        }
    }
    & .profileTabsDiv{
        background-color: $theme10;
        border-radius: 5px;
        overflow    : hidden;
        padding: 14px;
        @include sm{
            padding: 25px;
        }
        @include xl{
            padding: 35px;

        }
        & .tabsHeading{
            font-size: 18px;
            line-height: 22px;
            color: white;
            font-weight: 600;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            @include sm{
                font-size: 20px;
                line-height: 26px;
            }
            & .tabsIconHeading{
                height: 18px;
                width: 15px;
                margin-right: 16px;
                display: flex;
                @include sm{
                    height: 21px;
                    width: 17px;
                    margin-right: 20px;
                }
            }
        }
        & .changeHeading{
            font-size: 15px;
            line-height: 19px;
            color: white;
            font-weight: 500;
            padding-top: 20px;
            padding-bottom: 10px;
            @include sm{
                padding-top: 40px;

            }
        }
        & .inputLabel{
            font-size: 10px;
            font-family: 500;
            color: $theme5;
            &  .Star{
                color:$theme6;
            }
        }
        & .inputGroup{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 18px;
            & .inputUser{
                background-color: $theme8;
                border-radius: 5px;
                overflow: hidden;
                font-size: 12px;
                color: white;
                width: 100%;
                border: none;
                height: 40px;
                box-shadow: none;
                padding: 5px 10px;
                &:focus{
                    outline: none;
                    border: none;
                }
            }
            & .changeBtn{
                font-size: 13px;
                line-height: 16px;
                color: white;
                font-weight: 500;
                border-bottom: 3px solid #318120 !important;
                background-color: $theme6;
                border: 0;
                box-shadow: none;
                padding: 10px 25px;
                border-radius: 5px;
            }
        }
        & .onOffBtn{
            margin-top: 25px;
            display: flex;
            align-items: center;
         & .form-check-input{
            background-color:$theme8;
            height: 12.4px;
            width: 33.44px;
            border-radius: 100px;
            margin-bottom: 5px;
            border: none;
            &:focus{
                border: 0;
                outline: none;
                box-shadow: none;
            }
        }
        & label{
           font-size: 13px;
           line-height: 16px;
           color: $theme5;
           font-weight: 500;
           margin-left: 24px;
        }
        }
        & .form-switch .form-check-input{
            background-image: url(../images/profile/onOff.png) !important;
            height: 10px;
            width: 33px;
            background-repeat: no-repeat;
         }
    }
}

.mobileMenuTab{
    font-size: 15px;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: $theme10;
    border-radius: 5px;
    padding: 10px 40px 10px 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
        border: 0.5px solid transparent;
        min-width: 160px;
        &:first-child {
            margin-top: 0;
        }
    @include xl{
    }
    &:is(:hover, .active){
        background-color: $theme8;
        color: white;
        border: 0.5px solid $theme5;
        & .menuIocn{
            & img{
                filter: brightness(9.5);
            }
        }
    }
    & .menuIocn{
        height: 20px;
        width: 20px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        & img{
            height: 100%;
            width: 100%;
            display: flex;
            object-fit: contain;
        }
    }
}

.tabsHeading{
    font-size: 18px !important;
    line-height: 22px !important;
    color: white !important;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center !important;
    text-transform: uppercase !important;
    @include sm{
        font-size: 20px !important;
        line-height: 26px !important;
    }
    & .tabsIconHeading{
        height: 18px !important;
        width: 15px !important;
        margin-right: 16px !important;
        display: flex !important;
        @include sm{
            height: 21px !important;
            width: 17px !important;
            margin-right: 20px !important;
        }
    }
}



.AffiliateSection{
    background-color: #242937;
    border-radius: 5px;
    overflow: hidden;
    padding: 14px;
    // margin-top: 10px;
    @include sm{
        padding: 25px;
    }
    @include xl{
    padding: 35px;

    }
    & .add-icon{
        height: 15px;
        width: 15px;
        display: flex;
        @include sm{
            height: 17px;
            width: 17px;
        }
    }
    & .add-txt{
        font-size: 18px;
        line-height: 130%;
        font-weight: 600;
        text-transform: uppercase;
        @include sm{
        font-size: 20px;

        }
    }
    & .affiliateBtn{
        background-color: $theme8;
        border-radius: 5px;
        padding: 11px 35px;
        font-size: 13px;
        font-weight: 600;
        color: $theme5;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block   ;
        text-wrap: nowrap;
        &.active{
            background-color: $theme14;
            color: white;
        }
    }
    & .inputLabel{
        font-size: 15px;
        color: $theme5;
        font-weight: 600;
    }
    & .inputUser {
        background-color: #2E3443;
        border-radius: 5px;
        overflow: hidden;
        font-size: 12px;
        color: $theme5;
        width: 100%;
        border: none;
        height: 41px;
        box-shadow: none;
        padding: 5px 10px;
        &::placeholder{
            color: $theme5;
            font-size: 10px;
            line-height: 13px;
            font-weight: 600;
        }
        &:focus{
            outline: none;
        }
    }
    & .changeBtn {
        font-size: 13px;
        line-height: 16px;
        color: white;
        font-weight: 500;
        border-bottom: 3px solid #318120 !important;
        background-color: #62C412;
        border: 0;
        box-shadow: none;
        padding: 10px 25px;
        border-radius: 5px;
        text-wrap: nowrap;
    }
    & .comissionCard{
        background-color: $theme8;
        border-radius: 5px;
        padding:10px 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        & .comissionHeading{
            font-size: 15px;
            line-height: 22px;
            font-weight: 700;
            color: white;
            text-align: center;
            margin-bottom: 8px;
            
        }
        & .comissionSubheading{
            font-size: 11px;
            line-height: 18px;
            font-weight: 700;
            color: white;
            text-align: center;
        }
    }
    & .betTableNew {
        margin-top: 20px;
        & table{
            border-collapse: separate;
            border-spacing: 0 5px;
            & > * {
                border: 0;
            }
            & thead{
                border-radius: 5px;

                th {
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                    background-color: $theme8 !important;
                    white-space: nowrap;
                    color: #B2B6C5;
                    padding: 10px 17px;
                    height: 41px;
                    border: 0;
                    &:first-child {
                        border-radius: 3px 0 0 3px;
                    }
                    &:last-child {
                        border-radius: 0 3px 3px 0;
                    }
                }  
            } 
            & tbody{
               & td {
                font-size: 13px;
                font-weight: 600;
                background-color: $theme8;
                white-space: nowrap;
                color: white;
                padding: 6px 17px;
                height: 41px;
                border: 0px;
                @include lg {
                    font-size: 14px;
                }
                &:first-child {
                    border-radius: 3px 0 0 3px;
                }
                &:last-child {
                    border-radius: 0 3px 3px 0;
                }
                & .clamSection{
                    background-color: $theme14;
                    border-radius: 5px;
                    padding: 6px 15px;
                    font-size: 11px;
                    line-height: 14.3px;
                    color: white;
                    text-align: center;
                }
               }
            }
    }
    
    }
    & .affilatepera{
        font-size: 13px;
        line-height: 16.9px;
        color: $theme5;
        font-weight: 600;
        & span{
            color: $theme14;
        }
    }
}
.depositedSection{ 
    
    & .noEntryBtn{
        background-color: $theme8;
        border-radius: 5px;
        padding: 22px 42px;
        display: inline-flex;
        font-size: 18px;
        font-weight: 700;
        color: $theme5;
        height: 66.44px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
       
    }
}


// .loadingScreen{
//     position:fixed;
//     height: 100vh;
//     background-color: #15181D;
//     top: 0;
//     left: 0;
//     z-index: 1049;
//     & .logoLoading{
//         height: 40px;
//         margin-bottom: 22px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         @include md{
//             height: 45px;
//         }
//         & img {
//             object-fit: contain;
//         }
//     }
//     & .loadingheading{
//         font-size: 16px;
//         line-height: 18.19px;
//         font-weight: 400;
//         text-align: center;
//         color: white;
//         font-family: 'Press Start 2P';
//     }
//     & .progressSection{
//         width: 200.84px;
//         height: 25.38px;
//         @include md{
//             width: 232.84px;
//             height: 28.38px;
//         }
//         & .corner1{
//             height: 4px;
//             width: 4px;
//             background-color:#15181D;
//             position: absolute;
//             left: 0;
//             top: 0;
//             z-index: 11;
//         }
//         & .corner2{
//             height: 4px;
//             width: 4px;
//             background-color:#15181D;
//             position: absolute;
//             right: 0;
//             top: 0;
//             z-index: 11;
//         }
//         & .corner3{
//             height: 4px;
//             width: 4px;
//             background-color:#15181D;
//             position: absolute;
//             left: 0;
//             bottom: 0;
//             z-index: 11;
//         }
//         & .corner4{
//             height: 4px;
//             width: 4px;
//             background-color:#15181D;
//             position: absolute;
//             right: 0;
//             bottom: 0;
//             z-index: 11;
//         }
//         & .progress{
//             width: 200.84px;
//             height: 25.38px;
//             background-color: transparent;
//             margin-top: 22px;
//             border-radius: 0;
//             border: 4px solid #D9D9D9;
//             outline: none;
//             @include md{
//                 width: 232.84px;
//                 height: 28.38px;
//             }
//             & .progress-bar{
//                 width: var(--percentage);
//                 height: 100%;
//                 background-color: $theme6;
//                 transition: 0.3s ease-in-out;
//                 animation: progress 5s linear;
//             }
//         // @keyframes progress {
//         //     from {
//         //         width: 0%;
//         //     } to {
//         //         width: 100%;
//         //     }
//         // }
//         }

//     }
// }

.loadingScreen {
    background-color: #191A24;
    height: 100vh;
        .lodingDiv {
            width: 90px;
            height: 90px;
            background-color: #0080FF;
            border: 5px solid #C2FB01;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: relative;
    
            .lodingscreen_bg {
                position: absolute;
                right: -4px;
                top: 11px;
            }
    
    
        }
    
        .progress_div {
            width: 90%; 
            max-width: 564px;
            height: 15px;
            border-radius: 30px;
            background-color: #202733;
    
            .progress_bar {
                background-color: #0E95FA;
                transition: width 0.5s ease-in-out;
                border-radius: 30px;
                height: 100%;
            }
    
        }
    
        .loadingheading{
            font-size: clamp(20px, 5vw, 25px);
            font-weight: 400;
            color: white;
            font-family: "Lexend";
        }


      
    
    }


.factivationSection{
    & .leftArrow{
        height:25.6px ;
        width: 25.6px;
        border-radius: 3px;
        margin-right: 10px;
        background-color: $theme8;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        & img{
            height: 100%;
            width: 100%;
        }
    }
    & .activePera{
        font-size: 15px;
        font-weight: 600;
        color: white;
        line-height: 19.5px;
        border-bottom: 1px solid #3D4455;
        padding-bottom: 45px;
        & .googleLink{
            color: $theme14;
        }
    }
    & .perasceen{
        font-size: 15px;
        font-weight: 600;
        color: white;
        line-height: 19.5px;
    }
    & .inuptGroupUrl{
        background-color: $theme8;
        border-radius: 5px;
        border: 0.5px solid $theme5;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & input{
            background-color: transparent;
            border: 0;
            box-shadow: none;
            font-size: 13px;
            line-height: 16.9px;
            font-weight: 600;
            color: white;
            margin-right: 8px;
            &:focus{
                outline: none;
            }
            &::placeholder{ 
                font-size: 13px;
                line-height: 16.9px;
                font-weight: 600;
                color: white;

            }
        }
        & .linkBtn{
            background-color: $theme10;
            border-radius: 4.33px !important;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            width: 26px;
            margin-right: 8px;
            & img{
                height: 100%;
                width: 100%;
            }
        }
    }
    & .qrSection{
        border-bottom: 1px solid #3D4455;
        padding-bottom: 30px;

        & .qrpera{
            font-size: 13px;
            line-height: 16.9px;
            color: $theme5;
        }
        & .qrImg{
            height: 116px;
            width: 116px;
            border-radius: 6px;
            overflow: hidden;
        }
        & .form-check{
            display: flex;
            align-items: center;
            & .formInp{
                height: 20px;
                width: 20px;
                border-radius:5px;
                border: 2px solid $theme14;
                background-color: transparent;
                &:focus{
                    box-shadow: none;
                    outline: none;
                }
            }
            & .formLbl{
                font-size: 15px;
                line-height: 19.5px;
                color: white;
                font-weight: 600;
                padding-left: 7px;
            }
        }
    }
    & .otpLb{
        font-size: 15px;
        line-height: 19.5px;
        font-weight: 600;
        color: #3D4455;
        padding: 20px 0px;
    }
    & .otpInput{
        display: flex;
        align-items: center;
        gap: 3px;
        & input{
            width: 58px;
            height: 35px;
            background-color: transparent;
            border: 0.5px solid $theme5 !important;
            border-radius: 3px;
            color: $theme5;
            font-size: 15px;
            font-weight: 600;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            &::placeholder{
                color: $theme5;
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:focus{
                outline: none;
            }
            
        }
    }
    & .onePera{
        font-size: 10px;
        line-height: 13px;
        font-weight: 500;
        color: white;
        padding-top: 14px;
    }
}








.AffiliateSection{
    background-color: #242937;
    border-radius: 5px;
    overflow: hidden;
    padding: 14px;
    // margin-top: 10px;
    @include sm{
        padding: 25px;
    }
    @include xl{
    padding: 35px;

    }
    & .add-icon{
        height: 15px;
        width: 15px;
        display: flex;
        @include sm{
            height: 17px;
            width: 17px;
        }
    }
    & .add-txt{
        font-size: 18px;
        line-height: 130%;
        font-weight: 600;
        text-transform: uppercase;
        @include sm{
        font-size: 20px;

        }
    }
    & .affiliateBtn{
        background-color: $theme8;
        border-radius: 5px;
        padding: 10px 30px;
        font-size: 13px;
        font-weight: 500;
        color: $theme5;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block   ;
        text-wrap: nowrap;
        &.active{
            background-color: $theme14;
            color: white;
        }
    }
    & .inputLabel{
        font-size: 15px;
        color: $theme5;
        font-weight: 600;
    }
    & .inputUser {
        background-color: #2E3443;
        border-radius: 5px;
        overflow: hidden;
        font-size: 12px;
        color: $theme5;
        width: 100%;
        border: none;
        height: 41px;
        box-shadow: none;
        padding: 5px 10px;
        &::placeholder{
            color: $theme5;
            font-size: 10px;
            line-height: 13px;
            font-weight: 600;
        }
        &:focus{
            outline: none;
        }
    }
    & .changeBtn {
        font-size: 13px;
        line-height: 16px;
        color: white;
        font-weight: 500;
        border-bottom: 3px solid #318120 !important;
        background-color: #62C412;
        border: 0;
        box-shadow: none;
        padding: 10px 25px;
        border-radius: 5px;
        text-wrap: nowrap;
    }
    & .comissionCard{
        background-color: $theme8;
        border-radius: 5px;
        padding:10px 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        & .comissionHeading{
            font-size: 15px;
            line-height: 22px;
            font-weight: 700;
            color: white;
            text-align: center;
            margin-bottom: 8px;
            
        }
        & .comissionSubheading{
            font-size: 11px;
            line-height: 18px;
            font-weight: 700;
            color: white;
            text-align: center;
        }
    }
    & .betTableNew {
        margin-top: 20px;
        & table{
            border-collapse: separate;
            border-spacing: 0 5px;
            & > * {
                border: 0;
            }
            & thead{
                border-radius: 5px;

                th {
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    background-color: $theme8 !important;
                    white-space: nowrap;
                    color: #B2B6C5;
                    padding: 10px 17px;
                    border: 0;
                    &:first-child {
                        border-radius: 3px 0 0 3px;
                    }
                    &:last-child {
                        border-radius: 0 3px 3px 0;
                    }
                }  
            } 
            & tbody{
               & td {
                font-size: 12px;
                font-weight: 500;
                background-color: $theme8;
                white-space: nowrap;
                color: $theme5;
                padding: 6px 17px;
                border: 0px;
                @include lg {
                    font-size: 14px;
                }
                &:first-child {
                    border-radius: 3px 0 0 3px;
                }
                &:last-child {
                    border-radius: 0 3px 3px 0;
                }
                & .clamSection{
                    background-color: $theme14;
                    border-radius: 5px;
                    padding: 6px 15px;
                    font-size: 11px;
                    line-height: 14.3px;
                    color: white;
                    text-align: center;
                }
               }
            }
    }
    
    }
    & .affilatepera{
        font-size: 13px;
        line-height: 16.9px;
        color: $theme5;
        font-weight: 600;
        & span{
            color: $theme14;
        }
    }
}
.depositedSection{ 
    
    & .noEntryBtn{
        background-color: $theme8;
        border-radius: 5px;
        padding: 13px 30px;
        display: inline-flex;
        font-size: 13px;
        font-weight: 500;
        color: $theme5;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
       
    }
}


