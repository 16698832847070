


.stackingMain {
  display: block;
  @include md {
    display: flex;
  }
}

.order1 {
  @include max-md {
    order: 1;
  }
}

.order2 {
  @include max-md {
    order: 2;
  }
}

.mainContaint {

  &.stackingBg {
    // background-image: url('./../images/stacking/stackingBg2.png');
    // background-repeat: no-repeat;
    // background-size: contain;
    // position: relative;
    // background-position: bottom;
    // @include max-md {
    //   overflow-x: hidden;
    // }
    // @include md {
    //   background-size: 100% 340px;
    //   background-image: url('../../../src/assets/images/criptobg.png');
    // }
    // @include lg {
    //   background-size: contain;
    // }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 50%;
    //   height: 366px;
    //   width: 1262px;
    //   background: url(./../images/stacking/stackingBg2.png);
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-position: center;
    //   z-index: 1;
    //   transform: translateX(-50%) rotate(48deg);
    //   @include md {
    //     background-position: top 0 right 0;
    //     transform: none;
    //     left: unset;
    //     top: 0;
    //     right: 0;
    //     height: 100%;
    //     width: 100%;
    //     max-width: 500px;
    //     max-height: 500px;
    //     background-size: contain;
    //     background-image: url(./../images//stacking/stackingBgGreenLayer.png);
    //   }
    // }
  }
  & .stackingPage {
    position: relative;
    z-index: 3;
    overflow-x: hidden;
    & .commonLightBtn {
      height: 35px;
      background-color: #2C303F;
      border-radius: 3px;
      font-size: 13px;
      font-weight: 500;
      color: #B2B6C5;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      padding-inline: 20px;
      &.small {
        height: 35px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
      }
      @include lg {
        height: 45px;
        font-weight: 600;
        border-radius: 3px;
      }
    }
    & .commonBlueBtn {
      font-size: 14px;
      font-weight: 600;
      border-radius: 3px;
      background-color: transparent;
      color: $theme5;
      height: 38px;
      padding: 7px 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      border-bottom: 3px solid #318120 ;
      background-color: $theme6;
      color: white;
      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }
      @include lg {
        height: 45px;
        font-weight: 600;
        border-radius: 3px;
      }
      & .btnIcon {
        height: 16px;
        object-fit: contain;
        margin-right: 10px;
      }
      &.small {
        height: 35px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    & .sText {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      color: #B2B6C5;
      & .icon {
        height: 12px;
        object-fit: contain;
        cursor: pointer;
      }
      &.green {
        color: #5BB54F;
      }
      &.moreTxt {
        cursor: pointer;
        text-decoration: underline;
      }
      @include md {
        font-size: 14px;
      }
    }
    & .sTextBold {
      font-size: 18px;
      font-weight: 600;
      line-height: 29.5px;
      color: #ffffff;
      &.green {
        color: #5BB54F;
      }
      @include md {
        font-size: 22px;
      }
    }
    & .CommonTitle {
      font-size: 20px;
      font-weight: 600;
      color: white;
      margin-bottom: 1.05rem;
      @include lg {
        font-size: 22px;
      }
    }
    & .popluarSection{
      @include md{
        // margin-top: -80px !important;

      }
    }
    & .cryptoHeroSection {
      // display: none;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      background-position: top;
      background-size: cover;
      background-image: url('../../../src/assets/images/criptoHeroSection.svg');
      @include sm{
      padding-bottom:70px ;

    }
     
      & .subTitle {
        font-size: 18px;
        font-weight: 600;
        color: white;
        margin-bottom: 7px;
        @include lg {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      & .title {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        line-height: 1.3;
        margin-top: 15px;
        margin-bottom: 20px;
        font-family: RETROTECH;
        @include md {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 30px;
        }
        @include lg {
          font-size: 35px;
        }
      }
      & .heroCoinImg {
        margin-left: -100px;
        width: 250px;
      
        @include md{
          width: auto;
          height: 300px;
          margin-left: -199px;
          margin-top: -100px;
        }
      }
    }

    & .earningBox {
      background: $theme10;
      border-radius: 10px;
      padding: 15px 18px;
      position: relative;
      & .earnBox {
        position: absolute;
        top: -12.5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 10px;
        font-weight: 600;
        color: white;
        background-color: #3F67B1;
        border-radius: 10px 10px 10px 10px;
        padding: 4px 15px;
        display: inline-flex;
        height: 23px;
        @include md {
          left: 0;
          transform: none;
          border-radius: 10px 10px 10px 0;
          padding: 4px 7px;
        }
      }
      &.greenBg {
        background: $theme10;
      }
      & .CommonTitle {
        margin-bottom: 1.7rem;
        @include md {
        margin-bottom: 2.4rem;
        }
      }

    }
    & .offeringCard {
      background-color: #212632;
      overflow: hidden;
      // border-radius: 7px;
      border-radius: 10px;
      @include sm {
        border-radius: 10px;
      }
      & .offeringCardTop {
        // padding: 10px;
        padding: 15px 15px 22px;
        @include sm {
          padding: 15px 15px 22px;
        }
        & .currencyIcon {
          // height: 25px;
          // width: 25px;
          // min-width: 25px;
          height: 36px;
          width: 36px;
          min-width: 36px;
          & img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
          @include sm {
            height: 36px;
            width: 36px;
            min-width: 36px;
          }
        }
        & .sText {
          // font-size: 10px;
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include sm {
            font-size: 13px;
          }
        }
        & .sTextBold {
          // font-size: 14px;
          // line-height: 20px;
          font-size: 18px;
          line-height: 29.5px;
          @include sm {
            font-size: 18px;
            line-height: 29.5px;
          }
        }
      }
      & .offeringCardBottom {
        background-color: #2C303F;
        // padding: 10px;
        padding: 15px;
        border-top: 0.5px solid rgba(#B2B6C580, 0.5);
        @include sm {
          padding: 15px;
        }
        & .sText {
          // font-size: 12px;
          font-size: 13px;
          @include sm {
            font-size: 13px;
          }
        }
      }
    }
    & .stackingTypeCard {
      background-color: $theme10;
      padding: 15px;
      border-radius: 10px;
      & .cardTitle {
        font-size: 16px;
        font-weight: 600;
        color: white;
        display: flex;
        align-items: center;
        align-items: center;
        margin-bottom: 7px;
        @include md {
          font-size: 18px;
        }
        & img {
          height: 20px;
          width: 20px;
          object-fit: contain;
          margin-right: 7px;
          @include md {
            height: 22px;
            width: 22px;
          }
        }
      }
      & .sText {
        line-height: 19.5px;
        @include ellipsis(3)
      }
      & .commonLightBtn {
        font-weight: 500;
      }
    }
    & .stackingTabs {
      background-color: $theme8;
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      padding: 4px;
      @include max-lg {
          overflow-x: auto;
          width: 100%;
          &::-webkit-scrollbar {
            display: none;
          }
      }
      & .stackingTab {
        font-size: 14px;
        font-weight: 500;
        color: #B2B6C5;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37px;
        padding-inline: 22px;
        border-radius: 10px;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        white-space: nowrap;
        &.active {
          color: white;
          background:$theme14;
        }
      }
    }
    & .customDivTable {
      & .tableHeader {
        display: flex;
        align-items: center;
        & .tableHead {
          padding: 7px 15px;
          & .sText {
            font-size: 12px;
          }
          &.one {
            width: 210px;
            min-width: 210px;
            @include max-lg {
              width: calc((100% - 60px)/2);
              min-width: calc((100% - 60px)/2);
            }
          }
          &.two {
            width: 110px;
            min-width: 110px;
            // background-color: green;
            
            @include max-lg {
              width: calc((100% - 60px)/2);
              min-width: calc((100% - 60px)/2);
            }

          }
          &.three {
            flex : 1;
            // background-color: orange;
            @include max-lg {}

          }
          &.four {
            width: 160px;
            min-width: 160px;
            @include max-lg {}

            & img {
              height: 14px;
              object-fit: contain;
              cursor: pointer;
            }
            // background-color: blue;
          }
          &.five {
            width: 130px;
            min-width: 130px;
            @include max-lg {
              width: 60px;
              min-width: 60px;
            }

          }
        }
      }
      & .tableBody {
        background-color: #212632;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        & .accBtn{
          background-color: #2E3443;
          height: 35px;
          width: 35px;
          border-radius: 5px;
          padding: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          & img {
            height: 100%;
            width: 100%;
          }
          & .active{
            
              & img {
                height: 100%;
                width: 100%;
                transform: rotate(180deg);
              }

          }
        }
        &.active {
          & .accBtn {
          }
        }
        &.inner {
          background-color: #1B1F27;
          @include max-lg {
            flex-wrap: wrap;
            padding-block: 10px;
          }
          & .tbr {
            padding-block: 15px;
            &.tbr1, &.tbr4 {
              color: white;
            }
            @include max-lg { 
              padding-block: 5px;
              &.tbr1, &.tbr4 {
                width: 60%;
                min-width: 60%;
              }
              &.tbr2, &.tbr5 {
                width: 40%;
                min-width: 40%;
                text-align: end;
              }
              &.tbr3 {
                width: 100%;
                min-width: 100%;
              }
            }
          }
        }
        & .tbr {
          padding: 8px 15px;
          &.tbr1 {
            font-size: 12px;
            font-weight: 600;
            color: #B2B6C5;
            display: flex;
            align-items: center;
            width: 210px;
            min-width: 210px;
            
            @include max-lg {
              width: calc((100% - 60px)/2);
              min-width: calc((100% - 60px)/2);
            }

            & img {
              height: 22px;
              width: 22px;
              border-radius: 50px;
              overflow: hidden;
              object-fit: contain;
              margin-right: 7px;
            }
          }
          &.tbr2 {
            font-size: 12px;
            font-weight: 600;
            color: #fff;
            width: 110px;
            min-width: 110px;
            
            @include max-lg {
              width: calc((100% - 60px)/2);
              min-width: calc((100% - 60px)/2);
            }

          }
          &.tbr3 {
            font-size: 12px;
            font-weight: 600;
            color: #5BB54F;
            flex: 1;
            @include max-lg {}
            & .termBox {
              height: 30px;
              background-color: #262B36;
              border-bottom: 3px solid $theme1 !important;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-inline: 12px;
              font-size: 12px;
              font-weight: 500;
              color: #B2B6C5;
              // margin-right: 7px;
              cursor: pointer;
              &:is(.active, :hover) {
                background-color: #42B72A;
                color: white;
                border-bottom: 3px solid #388927 !important;
              }
            }
          }
          &.tbr4 {
            
            font-size: 12px;
            font-weight: 600;
            color: #fff;
            width: 160px;
            min-width: 160px;
            @include max-lg {}
            & .wagerrTxt {
              color: #B2B6C5;
              font-weight: 500;
              & img {
                height: 12px;
                object-fit: contain;
              }
            }
          }
          &.tbr5 {
            display: flex;
            justify-content: end;
            min-width: 130px;
            width: 130px;
            @include max-lg {
              width: 60px;
              min-width: 60px;
            }

         
            & .commonBlueBtn {
              font-size: 12px;
              height: 30px;
            }
          }
        }
      }
    }
    & .calculateCryptoEarning {
      padding: 15px;
      border-radius: 10px;
      background-color: $theme10;
      overflow: hidden;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        aspect-ratio: 1;
        background-image: url(../../assets/images/grid.png);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
      & .calculateCryptoEarningInner {
        position: relative;
        z-index: 3;
      }
      & .stackingTabs {
        background-color: #2C303F;
        border-radius: 5px;
        & .stackingTab {
          border-radius: 5px;
          font-size: 13px;
          @include max-sm {
            font-size: 10px;
            padding-inline: 10px;
          }

        }
      }
      & .commonBlueBtn {
        &.small {
          @include max-sm {
            font-size: 12px;
            height: 30px;
          }
        }
      }
      & .termBox {
        height: 30px;
              background-color: #262B36;
              border-bottom: 3px solid $theme1 !important;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-inline: 12px;
              font-size: 12px;
              font-weight: 500;
              color: #B2B6C5;
              // margin-right: 7px;
              cursor: pointer;
              &:is(.active, :hover) {
                background-color: #42B72A;
                color: white;
                border-bottom: 3px solid #388927 !important;
              }
              @include max-sm {
                font-size: 10px;
                padding-inline: 10px;
                height: 28px;
              }

     
      }
      & .termBoxTxt {
        font-size: 18px;
        font-weight: 600;
        color: white;
        @include max-sm {
          font-size: 12px;
        }

      }
      & .subscribeBox {
        background-color: #1B1F27;
        padding: 15px;
        border-radius: 5px;
        @include max-sm {
          padding: 12px;
        }
        & .sText {
          @include max-sm {
            font-size: 10px;
            line-height: 12px;
          }
        }
        & .sTextBold {
          @include max-sm {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      & .calculateInput {
        display: flex;
        align-items: center;
        & .usdtIcon {
          height: 28px;
          width: 28px;
          object-fit: contain;
          position: absolute;
          top: 50%;
          left: 17px;
          transform: translateY(-50%);
          border-radius: 100px;
          overflow: hidden;
          @include max-sm {
            height: 18px;
            width: 18px;
            left: 12px;
          }

        }
        & .form-control {
          border-radius: 5px;
          background-color: #1E222D;
          height: 65px;
          font-size: 14px;
          font-weight: 600;
          color: #B2B6C5;
          border: 0 !important;
          box-shadow: none !important;
          padding-left: 60px;
          &::placeholder{
            font-size: 14px;
            font-weight: 600;
            color: #B2B6C5;
          }
          @include max-sm {
            height: 50px;
            font-size: 12px;
            padding-left: 40px;
          }
        }
        & .balance {
          position: absolute;
          top: 24px;
          right: 10px;
          @include max-sm {
            font-size: 10px;
          }
        }
      }
      & .cDrop {
        & .dropdown-toggle {
          background-color: #2C303F;
          border-radius: 5px;
          border: 0;
          height: 65px;
          display: flex;
          align-items: center;
          width: 100%;
          @include max-sm {
            height: 50px;
          }

          & .usdtIconBox{
            height: 28px;
            width: 28px;
            min-width: 28px;
            overflow: hidden;
            border-radius: 50px;
            margin-right: 7px;
            @include max-sm {
              height: 18px;
              width: 18px;
              min-width: 18px;
            }
            & img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              display: flex;
            }
          }
          & .sText {
            font-size: 12px;
            text-align: left;
            @include max-sm {
              font-size: 10px;
            }

          }
          & .sTextBold {
            font-size: 18px;
            text-align: start;
            text-transform: uppercase;
            @include max-sm {
              font-size: 12px;
              line-height: 12px;
            }

          }
          &::after {
            margin-left: auto;
          }
        }
        & .dropdown-menu {
          background-color: #2C303F;
          border-radius: 5px;
          padding: 5px;
          width: 100%;
          max-height: 185px;
          overflow-y: auto;
          @include max-sm {}
          & .dropdown-item {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            color: white;
            text-transform: uppercase;
            border-radius: 5px;
            padding: 7px;
            margin-bottom: 4px;
            @include max-sm {
              font-size: 12px;
            }

            &:last-child {
              margin-bottom: 0;
            }
            & img {
              height: 25px;
              width: 25px;
              min-width: 25px;
              object-fit: contain;
              margin-right: 8px;
            @include max-sm {
              height: 18px;
              width: 18px;
              min-width: 18px;
            }

            }
            &:is(:hover, .active) {
              background-color: #3B4155;
            }
          }
        }
      }

    }
    & .stackingAccordian {
      // display: flex;
      // flex-wrap: wrap;
      & .accordion-item {
        // width: 50%;
        border-radius: 0 !important;
        background-color: transparent !important;
        border: 0 !important;
        border-bottom: 1px solid rgba(#fff, 0.3) !important;
        &:last-child {
          border-bottom: 0 !important;
        }
        & .accordion-header {
          & .accordion-button {
            border-radius: 0 !important;
            background-color: transparent !important;
            border: 0 !important;
            box-shadow: none !important;
            color: white !important;
            padding-inline: 0 !important;
            font-size: 16px;
            font-weight: 600;
            &.collapsed {}
            &:not(.collapsed) {}
            &::after {
              background-image: url('./../images/stacking/accIcon.png');
              --bs-accordion-btn-icon-width: 0.9rem !important;
            }
          }
        }
        & .accordion-collapse {
          & .accordion-body {
            font-size: 14px;
            font-weight: 500;
            color: #B2B6C5;
            padding: 0 !important;
            padding-bottom: 15px !important;
          }
        }
      }
    }
    & .stackingHistoryTable {
      & table {
        border-collapse: separate;
        border-spacing: 0 5px;
        & thead {
          & tr {
            & th {
              font-weight: 500;
              line-height: 18px;
              color: #B2B6C5;
              font-size: 14px;
              padding: 10px 15px;
              text-transform: uppercase;
              background-color: #15191F !important;
              &:first-child {
                position: sticky;
                left: 0;
              }
            }
          }
        }
        & tbody {
          & tr {
            & td {
              background-color: #212632 !important;
              color: #B2B6C5;
              font-weight: 500;
              font-size: 13px;
              padding: 8px 15px;
              vertical-align: middle;

              &.bold {
                font-size: 14px;
                color: white;
                font-weight: 600;
              }
              &:first-child {
                border-radius: 10px 0 0 10px;
                position: sticky;
                left: 0;
                & .coinImg {
                  height: 22px;
                  width: 22px;
                  min-width: 22px;
                  border-radius: 100px;
                  overflow: hidden;
                  object-fit: contain;
                  margin-right: 8px;
                }
              }
              &:last-child {
                border-radius: 0 10px 10px 0;
              }
              &.noDataTd {
                background-color: transparent !important;
              }
              & .claimBtn {
                height: 30px;
                font-size: 12px;
                padding-inline: 15px;
              }
            }
          }
        }
      }
    }
  }
}

 .criptoBtn {
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  background-color: transparent;
  color: $theme5;
  height: 38px;
  padding: 7px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-bottom: 3px solid #318120 ;
  background-color: $theme6;
  color: white;
 
}
.noDataBox {
  & img {
    height: 80px;
    object-fit: contain;
  }
  & .noDataTxt {
    font-size: 14px;
    color: #B2B6C5;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.charBox {
  background-image: url(./../images/stacking/chartGrid.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  .highcharts-credits, 
  .highcharts-yaxis-labels,
  .highcharts-xaxis,
  .highcharts-yaxis,
  .highcharts-xaxis-grid,
  .highcharts-title,
  .highcharts-subtitle,
  .highcharts-yaxis-grid {
    display: none;
  }
}

// modal 1
.firstCryptoModal{
  &.aboutStackingModal {
    & .modal-dialog {
      max-width: 677px !important;
    }
  }
  & .modal-dialog {
    &.modal-xl {
      max-width: 860px !important;
    }
  }
  & .modal-content{
    border-radius:13.2px;
    background-color:#212632;
  }
  & .modalHeader{
    padding: 20px 20px 0 20px;
    border-bottom: 0;
    margin-bottom: 22px;
    & .modalTitle{
      font-size: 21.57px;
      font-weight: 600;
      color: white;
      & img{
        width: 30px;
        height: 30px;
        object-fit: contain;
        border-radius: 50%;
      }
    }
    & .closeBTn {
      cursor: pointer;
      & svg {
        height: 22px;
        width: 22px;
        display: flex;
      }
    }
  }
  & .modalBody{
    padding: 0 20px 20px 20px;
    & .modalText{
      color: #B2B6C5;
      font-size:  14.38px;
      font-weight: 500;
      line-height: 18.69px;
    }
    & .modalSubHeading{
        font-size: 17.26px;
        font-weight: 600;
        color: white;
    }
    .tableWrapper{
      background-color: #1B1F27;
      padding: 16px 40px;
      border-radius: 8.8px;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      & .tableHead{
        font-size: 14.38px;
        font-weight: 600;
        color: white;
        position: relative;
        & .divider{
          position: absolute;
          bottom: -6px;
          left: 0;
          background: linear-gradient(to right, #B2B6C500, #B2B6C5, #B2B6C500);
          width: 100%;
          height: 1px;
        }
        // border-image-source: linear-gradient(90deg, rgba(178, 182, 197, 0) 0%, #B2B6C5 50.09%, rgba(178, 182, 197, 0) 99.87%);
        padding-bottom: 12px;
      }
      & .tableBody{
        background-color: transparent !important; 
        position: relative;
        & .textOne{
          position: absolute;
          right: 18%;
          top: 45%;
          transform: translateY(-50%);
          transform: rotate(270deg);
          font-size: 11px;
          font-weight: 500;
          color: #B2B6C5;
        }
        & .texttwo{
          position: absolute;
          right: 2%;
          top: 45%;
          transform: translateY(-50%);
          transform: rotate(270deg);
          font-size: 11px;
          font-weight: 500;
          color: #B2B6C5;
        }
        &::after{
          content: '';
          position: absolute;
          top: 13px;
          right: 29%;
          width: 1px;
          height: 90%;
          background-color: #B2B6C5;
        }
        &::before{
          content: '';
          position: absolute;
          top: 13px;
          right: 13%;
          width: 1px;
          height: 90%;
          background-color: #5BB54F;
        }
        & .textgray{
          font-size: 14.38px;
          font-weight: 600;
          color: #B2B6C5;
        }
        & .textWhite{
          font-size: 14.38px;
          font-weight: 600;
          color: white;
        }
        & .endBox{
          padding: 10px 10px;
        }
      }
    }
    & .refferalBox{
      font-size: 11.5px;
      font-weight: 500;
      padding: 12px;
      background-color:#1B1F27;
      color: #B2B6C5;
      border-radius: 4.4px
      & span{

      }
    }
  }
  & .modalFooter{
    padding: 0;
    border-top: 0;
    background-color:  $theme10;
    padding: 20px;
    & .closeBtn{
      height:  32.55px;
      width: 100%;
      background-color: #3B4155;
      color: #B2B6C5;
      font-size: 14.38px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 4.4px;
    }
    & .refBtn{
      height:  32.55px;
      width: 100%;
      background: linear-gradient(180deg, #4D7DD6 0%, #284170 100%);
      color: white;
      font-size: 14.38px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 4.4px;
    }
    & .termsLink{
      color: #5BB54F;
      font-size: 13px;
      font-weight: 600;
      text-decoration: underline;
      & svg{
        width: 20px;
        height: 20px;
      }
    }
    & .socialsLinks{
      & img{
        width: 41px;
        height: 41px;
        object-fit: contain;
      }
    }
    & .agreeCheckbox{
      background-color: #15191F;
      accent-color:#5BB54F ;
      border: 1px solid #15191F;
      width: 17px;
      min-width: 17px;
      height: 17px;
      &:checked {
        background-color: #5BB54F;
        border-color: #5BB54F;
      }
    }
    & .confirmBtn{
      height:  32.55px;
      width: 100%;
      background-color: $theme14;
      border-bottom: 3px solid #388927 !important;
      color: white;
      font-size: 14.38px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 4.4px;
    }
  }
}

.secondCryptoModal{
  & .modal-content{
    background-color: $theme10;
  }
  & .modalBody{
    & .tabs{
        padding: 0;
      & .tab-buttons{
        background-color: #1B1F27;
        border-radius: 5px;
        padding: 5px;
        display: flex;
        justify-content:space-between;
        width: 100%;
        height: 45px;
        overflow-x: auto;
        width: 100%;
        white-space: nowrap;
        overflow-y: hidden;
        & .tabBtn{
            background-color: transparent;
            border: 0;
            color: #B2B6C5;
            font-size: 13px;
            font-weight: 600;
            height: 37px;
            width: 100%;
        }
        & .active{
          background-color: #3B4155;
          font-size: 13px;
          font-weight: 600;
          color: white;
          border-radius: 5px;
          border: 0;
          height: 37px;
          width: 100%;
        }
      }
      & .tab-content{
        padding: 20px 0;
        & .contentBoxHeading{
          font-size: 15px;
          font-weight: 600;
          color: white;
          & img{
            width: 31px;
            height: 31px;
            object-fit: contain;
          }
        }
        & .textlight{
          & span{
            color: white;
          }
          color: #B2B6C5;
          font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
        }
      }
    }
  }
}
.textlight{
  color: #B2B6C5;
  font-size: 10px;
  font-weight: 500;
  line-height: 18.2px;
  // font-family: RETROTECH;
  &.textSucess{
    color: $theme6;
  }
  @include md{
          
    font-size: 13px;
  }
  @include lg{
  }
  @include xl{
  }
  @include xxl{
  }
  &.textgreen{
    color: $theme14;
  }
  & img{
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 5px;
  }
  & svg{
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 5px;
  }
  & span{
    color: white;
  }
}

.thirdCryptoModal{
  & .modal-content{
    background-color: #212632;
  }
  .divider{
    margin: 15px 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #B2B6C500, #B2B6C5, #B2B6C500);
    // border-image-source: linear-gradient(90deg, rgba(178, 182, 197, 0) 0%, #B2B6C5 50.09%, rgba(178, 182, 197, 0) 99.87%);
  }
  & .box{
    background-color: #1B1F27;
    padding: 20px;
  }
  & .banner{
    width: 100%;
    height: auto;
    @include md{
          
    }
    @include lg{
      height: 182px;
    }
    @include xl{
    }
    @include xxl{
    }
    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.fourthCryptoModal{
  & .modalHeader{
    & .modalTitleLight{
      color: #B2B6C5;
      font-size: 20px;
      font-weight: 600;
    }
  }
  & .modalBody{
    & .box1{
      background-color: #1B1F27;
      border-radius: 5px;
      padding: 8px;
      border: 2px solid #1B1F27;
      &.active {
        border-color: $theme1;
      }

      @include sm{

      }
      @include md{
        padding: 15px;
        
      }
      @include lg{
        
      }
      @include xl{
        
      }
      @include xxl{
        
      }
      & .flexBtns{
        background-color: $theme8;
        border-bottom: 3px solid $theme1 !important;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        border: 0;
        height: 35px;
        padding-inline: 14px;
        color: #B2B6C5;
        width: 100%;
        &.active{
          background-color: $theme6;
          border-bottom: 3px solid #388927 !important;
          color: white;
        }
      }
      & .bit{
        width: 23px;
        height: 23px;
        & img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    & .max{
      background-color: #2C303F;
      font-size: 14px;
      height: 100%;
      font-weight: 500;
      color: #B2B6C5;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    & .switch {
      position: relative;
      display: inline-block;
      width: 39px;
      height: 16px;
    }
    & .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    & .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    & .slider:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    & input:checked + .slider {
      background-color: #1B1F27;
    }
    & input:focus + .slider {
      box-shadow: 0 0 1px #1B1F27;
    }
    & input:checked + .slider:before {
      -webkit-transform: translateX(21px);
      -ms-transform: translateX(21px);
      transform: translateX(21px);
    }
    & .slider.round {
      border-radius: 5px;
      width: 39px;
      height: 16px;
      background-color: #1B1F27;
    }
    & .slider.round:before {
      border-radius: 5px;
    }
    & .box2{
      background-color: #3B4155;
      border-radius: 5px;
      padding: 15px;
      & .popularSpan{
        background-color: $theme1;
        border-radius: 28px;
        height: 16px;
        color: $theme14;
        font-size: 11px;
        font-weight: 600;
        padding-inline: 16px;
      }
    }
    & .track{
      &:last-child{
        & .circle{
          &:last-child{
            &::after{
              height: 36px;
              top: -25px;          
            }
          }
        }
      }
      &:first-child{
        & .circle{
          &:first-child{
            &::after{
              height: 0;
              top: 0;          
            }
          }
        }
      }
    }
    & .circle{
      width: 17px;
      height: 17px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        bottom: 6px;
        top: unset;
        left: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 64px;
        border-radius: 10px;
        background-color: #1B1F27;
        z-index: 2;
      }
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 5;
        position: absolute;
      }
    }
    & .estBox{
      background-image: linear-gradient(to right, #23262d, #14310e);
      height: 35px;
      border-radius: 5px;
      border: 0.6px solid $theme5;
      padding: 20px;
    }
    & .libox{
      background-color: #1B1F27;
      padding: 20px 20px 20px 0;
      border-radius: 5px;
      // &.marginTop{
      //   margin-top: auto;
      // }
    }
    & .triedBox{
      background-color: #1B1F27;
      border-radius: 5px;
      padding: 10px 9px;
    }
    & .borderBottom{
      position: relative;
      &::after{
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #B2B6C580;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    & .investmentBanner{
      width: 100%;
      height: auto;
      position: relative;
      background-color:#1B1F27;
      border-radius: 5px;
      @include sm{

      }
      @include md{
        
      }
      @include lg{
        
      }
      @include xl{
        
      }
      @include xxl{
        
      }
      & span{
        position: absolute;
        top: 10px;
        left:50%;
        right: unset;
        font-size: 10px;
        font-weight: 400;
        color: white;
        @include sm{
          top: 25px;
          left:50%;
          right: unset;
          font-size: 14px;
          font-weight: 400;

        }
        @include md{
          
        }
        @include lg{
          
        }
        @include xl{
          
        }
        @include xxl{
          
        }
      }
      & .tryBtn{
        position: absolute;
        bottom: 10px;
        right:5%;
        background-image: linear-gradient(to bottom, #4D7DD6, #284170);
        border: 0;
        border-radius: 5px;
        font-size: 10px;
        font-weight: 600;
        color: white;
        padding-inline: 20px;
        padding-block: 5px;
        @include sm{
          font-size: 13px;
          font-weight: 600;
          color: white;
          padding-inline: 25px;
          padding-block: 5px;
          bottom: 25px;

        }
        @include md{
          
        }
        @include lg{
          
        }
        @include xl{
          
        }
        @include xxl{
          
        }
      }
      & img{
        max-height: 135px;
        object-fit: contain;
      }
    }
    & .errorTxt {
      color: red;
      font-size: 13px;
      font-weight: 500;
    }
    & .subscriptionInput {
      position: relative;
      & .bitImg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        height: 20px;
        width: 20px;
        border-radius: 50px;
        overflow: hidden;
        & img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          display: flex;
        }
      }
      & .subLabel {
        position: absolute;
        left: 50px;
        top: 10px;
        transform: translateY(0);
        font-size: 13px;
        font-weight: 500;
        color: #B2B6C5;
      }
      & .inpAmount {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 13px;
        font-weight: 500;
        color: #B2B6C5;
      }
      & .crossBtn {
        position: absolute;
        bottom: 6px;
        right: 10px;
        color: #B2B6C5;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & svg {
          height: 22px;
          width: 22px;
        }
      }
      & .form-control {
        background-color: #1B1F27;
        border: 1px solid #1B1F27 !important;
        height: 55px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 600;
        color: white;
        padding-left: 50px;
        padding-top: 25px;
        &.error {
          border-color: red !important;
          &:is(:focus, :focus-within) {
            border-color: red !important;
          }
        }
        &:placeholder-shown {
          & ~ .subLabel {
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &:is(:focus, :focus-within) {
          border-color: #4D7DD6 !important;
        }
        &:focus{
          & ~ .subLabel {
            top: 10px;
            transform: translateY(0);
          }
        }
      }

    }
  }
}




// about stacking page
.aboutStackingWrapper{
  & .AboutStackingHero{
    background-image: url(../images/Pattern.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  & .stackingHeading{
    font-size: 20px;
    font-weight: 600;
    color: white;
    line-height: 22px;
    margin-top: 20px;
    @include md{
      font-size: 35px;
      line-height: 45px;
    }
    @include lg{
      font-size: 35px;
      line-height: 40px;
    }
    @include xl{
      font-size: 35px;
    }
    @include xxl{
      font-size: 45px;
    }
  }
  & .stackingsubHeading{
    font-size: 12px;
    font-weight: 500;
    color: white;
    line-height: 15.6px;
  }
  & .stackingBanner{
    // width: 492px;
    // height: 250px;
    width: 100%;
    height: auto;
    @include md{
          
    }
    @include lg{
    }
    @include xl{
      // width: 492px;
      height: 250px;
    }
    @include xxl{
    }
    & img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  & .stakeBtn{
    border-bottom: 3px solid #318120 !important;
    background-color: #62C412;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 3px;
    height: 38px;
    padding: 7px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    @include md{
          
    }
    @include lg{ 
      font-size: 15px;
      font-weight: 600;
      color: white;
      padding-inline: 40px;
    }
    @include xl{
    }
    @include xxl{
    }
  }
  & .percentageBox{
    border-right: 1px solid rgba(#B2B6C5, 0.5);
    &.percentageBox2{
      border-right: 0;
      @include md{
      }
      @include lg{
        border-right: 1px solid rgba(#B2B6C5, 0.5);
      }
      @include xl{
      }
      @include xxl{
      }
    }
    &.percentageBox3{
      @include md{
      }
      @include lg{
        border-right: 0;
      }
      @include xl{
      }
      @include xxl{
      }
    }
    & .textBold{
      font-weight: 600;
      font-size: 14px;
      color: white;
      @include md{
      font-size: 22px;
          
      }
     
      
      @include xxl{
        font-size: 27px;
      }
    }
  }
  & .aboutHeading{
    color: white;
    font-size: 25px;
    font-weight: 600;
  }
  & .textlightabout{
    color: white;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    @include md{
          
    }
    @include lg{
      font-size: 15px;
    }
    @include xl{
    }
    @include xxl{
    }
    &.textlightaboutGreen{
      & span{
        color: #5BB54F;
      }
    }
    & span{
      font-size: 15px;
      font-weight: 600;
      color: #B2B6C5;
    }
  }
  & .box1{
    background-color: #1B1F27;
    border-radius: 5px;
    padding: 8px;
    border: 1px solid rgba(#B2B6C580, 0.3);
    @include sm{

    }
    @include md{
      padding: 15px;
      
    }
    @include lg{
      
    }
    @include xl{
      
    }
    @include xxl{
      
    }
    & .bit{
      width: 23px;
      height: 23px;
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  & .flexBtns{
    background-color: $theme8;
    border-bottom: 3px solid $theme1 !important;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    border: 0;
    height: 35px;
    padding-inline: 14px;
    color: #B2B6C5;
    width: 100%;
    &.active{
      background-color: $theme6;
      border-bottom: 3px solid #388927 !important;
      color: white;
    }
  }
  & .calculatorBox{
    background-color: #212632;
    padding: 20px;
    border-radius: 15px;
    @include md{
          
    }
    @include lg{
      padding: 26px;
    }
    @include xl{
    }
    @include xxl{
    }
  }
  & .faqHeading{
    font-size: 20px;
    font-weight: 600;
    color: white;
    line-height: 26px;
    @include md{
          
    }
    @include lg{
    }
    @include xl{
      font-size: 35px;
      font-weight: 600;
      color: white;
      line-height: 45.5px;
    }
    @include xxl{
    }
  }
  & .imageBox{
    background-color: #212632;
    border-radius: 10px;
    padding: 20px 10px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    @include md{
          
    }
    @include lg{
    }
    @include xl{
    }
    @include xxl{
    }
    & .boximg{
      width: 35px;
      height: 35px;
      margin: auto;
      margin-bottom: 5px;
      @include md{
          
      }
      @include lg{
        width: 50px;
        height: 50px;
      }
      @include xl{
      }
      @include xxl{
      }
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    & .boxtxt{
      font-size: 10px;
      font-weight: 600;
      color: white;
      text-align: center;
      @include md{
          
      }
      @include lg{
        font-size: 13px;
      }
      @include xl{
      }
      @include xxl{
      }
    }
  }
  & .rectangle{
    width: 29px;
    height: 29px;
    position: relative;
    &.rectangleHeight{
      &::before{
        height: 100% !important;
      }
    }
    &.rectangle1{
      &::after{
        content: '2';
      }
    }
    &.rectangle2{
      &::after{
        content: '3';
      }
      &::before{
        display: none;
      }
    }
    &::after{
      content: '1';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 12px;
      font-weight: 600;
    }
    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 450%;
      background-color: rgba(#B2B6C5, 0.5);
      @include sm{
        height: 300%;
      }
      @include md{
          height: 282%;
      }
      @include lg{
        height: 282%;
      }
      @include xl{
      }
      @include xxl{
      }
    }
    & img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  & .stackingBtn{
    height: 35px;
    background: $theme14;
    border-bottom: 3px solid #388927 !important;

    font-size: 13px;
    font-weight: 600;
    color: white;
    border: 0;
    border-radius: 5px;
    padding-inline: 20px;
  }
  & .stackingBtn1{
    background-color: #212632 !important;
    font-size: 13px;
    font-weight: 600;
    color: white;
    border: 0;
    border-radius: 5px;
    padding-inline: 20px;
    height: 35px;
  }
  & .textHeading{
    font-size: 20px;
    font-weight: 600;
    color: white;
    @include md{
          
    }
    @include lg{
      font-size: 25px;
    }
    @include xl{
    }
    @include xxl{
    }
  }
  & .bitBtn{
    background-color: #212632;
    // height: 64px;
    padding: 10px 20px;
    // width: 160.19px;
    width: 50%;
    border-radius: 9.19px;
    border: 0;
    font-size: 10px;
    font-weight: 600;
    color: white;
    @include md{
          
    }
    @include lg{
      width: 202.19px;
      font-size: 14px;
    }
    @include xl{
    }
    @include xxl{
    }
    & img{
      height: 17px;
      width: 17px;
      object-fit: contain;
      margin-right: 5px;
      @include md{
          
      }
      @include lg{
        height: 23px;
        width: 23px;
      }
      @include xl{
      }
      @include xxl{
      }
    }
  }
}

.seprator{
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #B2B6C500, #B2B6C5, #B2B6C500)
}


// dgw stacking

.DgwStackingWrapper{
  & .heading{
    font-size: 25px;
    font-weight: 600;
    color: white;
    @include md{
    }
    @include lg{
    }
    @include xl{
      font-size: 35px;
    }
    @include xxl{
    }
  }
  & .dgwBtns{
    border-radius: 10px;
    background-color: $theme8;
    width: 100%;
    height: 45px;
    border: 0;
    font-size: 11px;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    padding-inline: 11px;
    &.dgwBtnsCol{
      background: $theme14;
      border-bottom: 3px solid #388927 !important; 
    }
    @include md{
    }
    @include lg{
    }
    @include xl{
    }
    @include xxl{
      font-size: 13px;
      padding-inline: 15px;
    }
    & img{
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin-right: 5px;
      @include md{
      }
      @include lg{
      }
      @include xl{
      }
      @include xxl{
        margin-right: 8px;
      }
    }
  }
  & .stackingPoolWrapper{
    background: $theme8;
    width: 100%;
    // height: 100%;
    padding: 10px;
    border-radius: 15px;
    @include md{
    }
    @include lg{
    }
    @include xl{
      padding: 10px;
    }
    @include xxl{
      padding: 21px;
    }
    &:last-child{
      & .poolBox{
        &:last-child{
          border-right: 0;
        }
      }
    }
    & .poolBox{
      & .poolLight{
        font-size: 12px;
        font-weight: 500;
        color: #B2B6C5;
        @include md{
        }
        @include lg{
        }
        @include xl{
          font-size: 12px;
        }
        @include xxl{
          font-size: 13px;
        }
        & img{
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
      }
      & .poolNumber{
          font-size: 14px;
          font-weight: 600;
          color: white;
          @include md{
          }
          @include lg{
          }
          @include xl{
            font-size: 18x;
          }
          @include xxl{
          }
      }
    }
    & .darkBox{
      background-color: $theme1;
      border-radius: 15px;
      padding: 10px;
      @include md{
      }
      @include lg{
      }
      @include xl{
        padding: 15px;
      }
      @include xxl{
        padding: 21px;
      }
    }
    & .coinsImg{
      width: 100%;
      height: auto;
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    & .textwhite{
      font-size: 11px;
      font-weight: 600;
      color: white;
      // line-height: 10px;
      @include md{
      }
      @include lg{
      }
      @include xl{
        font-size: 13px;
      }
      @include xxl{
        font-size: 15px;
      }
      & span{
        color:#B2B6C5;
      }
    }
    & .textgray{
      color: #B2B6C5;
      font-size: 13px;
      @include md{
      }
      @include lg{
      }
      @include xl{
        font-size: 12px;
      }
      @include xxl{
        font-size: 13px;
      }
    }
    & .iconsgroup{
      width: 100%;
      height: 14px;
      @include md{
      }
      @include lg{
      }
      @include xl{
        height: 20px;
      }
      @include xxl{
      }
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    & .anchorstcking{
      font-size: 10px;
      font-weight: 600;
      color: #5BB54F;
      & svg{
        width: 12px;
        height: 12px;
      }
    }
    & .icons{
      width: 20px;
      height: 20px;
      object-fit: contain;
      @include md{
      }
      @include lg{
      }
      @include xl{
        width: 20px;
        height: 20px;
      }
      @include xxl{
        width: 30px;
        height: 30px;
      }
      & img{
        width: 100%;
        height: 100%;
      }
    }
  }
  & .boxRyt{
    background-image: url(../../../public/assets/img/stacking/dblbg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 100%;
    border-radius: 15px;
    padding: 20px;
    & .rytImg{
      width: 150px;
      
      height: 70px;
      margin: auto;
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    & .rytTxt{
      font-size: 20px;
      font-weight: 600;
      line-height: 32.5px;
      color: white;
      @include md{
      }
      @include lg{
        font-size: 25px;
      }
      @include xl{
      }
      @include xxl{
      }
    }
    & .rytSubTxt{
      color: #B2B6C5;
      font-size: 13px;
      font-weight: 500;
    }
    & .poolBox{
      border-right: 1px solid rgba(#B2B6C5, 0.5);
      & .poolLight{
        font-size: 15px;
        font-weight: 500;
        color: #B2B6C5;
        & img{
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
      }
      & .poolNumber{
          font-style: 25px;
          font-weight: 600;
          color: white;
      }
    }
    & .stakeBTn{
      width: 100%;
      border: 0;
      background: $theme14;
      border-bottom: 3px solid #388927 !important;
      height: 45px;
      font-size: 15px;
      font-weight: 600;
      padding-inline: 20px;
      color: white;
      border-radius: 10px;
    }
  }
  & .tableResponse{
    width: 100%;
    overflow-y: auto;
    white-space: nowrap;
  }
  & .tableBox{
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  & .tabsWrapper{
    & .formSelectWrapper{
      background-color: #212632;
      border-radius: 5px;
      height: 35px;
      border: 0;
      font-size: 13px;
      font-weight: 600;
      color: white;
      box-shadow: none;
      @include md{
      }
      @include lg{
      }
      @include xl{
        height: 45px;
      }
      @include xxl{
      }
      // --bs-form-select-bg-img:none;
      position: relative;
      &::after{
        // content: '';
        // background-image: url(./../public/../images/stacking/down.png);
        // background-repeat: no-repeat;
        // background-size: contain;
        // position: absolute;
        // right: 10px;
        // top: 50%;
        // transform: translateY(-50%);
      }
    }
    & .tabsBtns{
      background:$theme8;
      border-radius: 5px;
      height: 35px;
      font-size: 12px;
      font-weight: 600;
      color: white;
      border: 0;
      padding-inline: 10px;
      @include md{
      }
      @include lg{
        font-size: 13px;
        padding-inline: 20px;
      }
      @include xl{
        height: 45px;
      }
      @include xxl{
      }
    }
    & .active{
      background: #3B4155;
      padding-inline: 20px;
      border: 0;
      height: 35px;
      font-size: 13px;
      font-weight: 600;
      color: white;
      border-radius: 5px;
      @include md{
      }
      @include lg{
      }
      @include xl{
        height: 45px;
      }
      @include xxl{
      }
    }
    & .tabContentWrapper{
      & .trBack{
        background-color: #212632;
        border-radius: 10px;
        height: 45px;
      }
      & .tableHead{
        font-size: 12px;
        font-weight: 500;
        color: #B2B6C5;
        & .tdwidth{
          width: 16%; 
          @include md{
            width: 33.33%; 
          }
          @include lg{
          }
          @include xl{
            font-size: 15px;
          }
          @include xxl{
          }
        }
      }
      & .tableData{
        font-size: 10px;
        font-weight: 600;
        color: #B2B6C5;
        @include md{
        }
        @include lg{
        }
        @include xl{
          font-size: 13px;
        }
        @include xxl{
        }
        & img{
                 width: 28px;
        height: 24px;
          object-fit: contain;
          @include md{
          }
          @include lg{
          }
          @include xl{
            width: 17px;
            height: 17px;
          }
          @include xxl{
          }
        }
      }
      & .tableBody{
        & tr{
          & td{
            border-left: 5px;
          }
        }
      }
    }
  }
}

.estimateText{
  font-size: 15px;
  font-weight: 600;
  color: white;
}
.estimategreenText{
  color: #5BB54F;
  font-size: 25px;
  font-weight: 600;
}
.estimatelightText{
  font-size: 14px;
  font-weight: 500;
  color: #B2B6C5;
  @include max-sm {
    font-size: 12px;
    margin-bottom: 8px;
  }

}
.graphTabs{
  // background-color: rgba(#B2B6C5, 0.3);
  // border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: 4px;
.graphBtns{
  width: 25%;
  background-color: transparent;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  border: 0;
  height: 32px;
  @include max-sm {
    font-size: 12px;
    font-weight: 500;
    height: 30px;
  }

}

& .active{
  width: 25%;
  background-color: $theme14;
  border-bottom: 3px solid #388927;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  border: 0;
  height: 32px;
}
}

.shareviamodalwidth{
  & .modal-dialog {
    max-width: 450px;
  }
}
.modalcryptiearningtable {
  & .modal-dialog {
    max-width: 555px;
  }
}

.dropboxouter{
  background-color: transparent !important;
  border: 0 !important;
  &:hover{
    background-color: transparent !important;
  }
  &::after{
    display: none !important;
  }
  &:focus{
    outline: unset;
  }
}
.questiondropwrapper{
  background-color: transparent !important;
  border: 0 !important;
  &:hover{
    background-color: transparent !important;
  }
  &::after{
    display: none !important;
  }
}
.dropMenuWrapper{
  background-color: #212632 !important;
  margin: 20px;
  & .dropmenuList{
    font-size: 11px;
    font-weight: 600;
    color: white !important;
    &:hover{
      background-color: #15191F !important;
    }
    & img{
      width: 13px;
      height: 13px;
      object-fit: contain;
      margin-right: 6px;
    }
    & span{
      color: #B2B6C5;
      margin-left: 6px;
    }
  }
}
.dropwrapper{
  width: 69px;
  height: 30px;
  background-color: #212632 !important;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  color: white !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 12px;
  position: relative;
}

.dropList{
  background-color: #212632 !important;
  border-radius: 5px;
  padding: 10px !important;
  & .dropListing{
    list-style: none;
    font-size: 13px;
    font-weight: 600;
    color: white !important;
    text-align: center;
    padding: 10px !important;
    &:hover{
      background-color: #15191F;
      border-radius: 5px;
      padding: 5px;
    }
  }
}


.stackingDropdown {
  & .dropdown {
    & .dropdown-toggle {
      background-color: #212632;
      border: 1px solid #212632;
      border-radius: 5px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: #B2B6C5;
      display: flex;
      align-items: center;
      & .calendarIcon {
        height: 22px;
        filter: invert(1);
        object-fit: contain;
        margin-right: 8px;
      }
      & span {
        color: white;
        // font-weight: 600;
      }
      &:is(:hover, :focus, :focus-visible, :active) {
        background-color: #212632;
        border: 1px solid #212632;
        color: #B2B6C5;
      }
      &::after {
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
    & .dropdown-menu {
      background-color: #212632;
      width: 100%;
      padding: 10px;
      max-height: 210px;
      overflow-y: auto;
      & .searchInp {
        position: relative;
        margin-bottom: 8px;
        & img {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          height: 15px;
          width: 15px;
          object-fit: contain;
        }
        & input {
          padding-left: 35px;
          background-color: #15191F;
          border: none !important;
          font-size: 12px;
          color: #B2B6C5;
          height: 36px;
          &::placeholder{
          color: #B2B6C5;
          font-size: 12px;


          }
        }
      }
      & .dropdown-item {
        font-size: 13px;
        font-weight: 500;
        color: white;
        text-transform: uppercase;
        margin-bottom: 3px;
        border-radius: 5px;
        padding: 7px 8px;
        display: flex;
        align-items: center;
        &:is(.active, :hover) {
          background-color: #2C303F;
        }
        &:last-child {
          margin-bottom: 0;
        }
        & img {
          height: 20px;
          width: 20px;
          object-fit: cover;
          border-radius: 50px;
          overflow: hidden;
          margin-right: 8px;
        }
      }
      &.calendarMenu {
        width: 300px;
        max-height: 302px;
        & .rdp {
          color: white !important;
          margin: 0;
          --rdp-accent-color: #4D7DD6;
          --rdp-background-color: #4D7DD6;
        }
      }
    }
  }
}
// .tokenBannerMob {
//   background-image: url(../../../public/assets/img/stacking/earncard.svg);
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
//   height: 260px;
//   width: 100%;
// }

.chartAccountDbl{
  background-image: url(../../../public/assets/img/stacking/chartbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding: 10px;

  height: 100%;
  & .chartImgtrade{
    width: 30px;
    height: 30px;
    @include md{
      width: 50px;
      height: 50px;
    }
  }
  & .chartSubHeading{
    font-size: 12px;
    color: white;
  }
  & .chatValue{
    display: inline-flex;
    flex-direction: column;
    background-color: $theme1;
    padding: 5px;
    align-items: center;
    border-radius: 5px;
    gap: 5px;
    & li{
      width: 32px;
      height: 19px;
      display: flex ;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: white;
      font-weight: 600;
      &.active{
        padding: 5px;
        border-radius: 5px;
        background-color: $theme5;

      }
    }
  }
  & .headingChart{
    font-size: 18px;
    color: white;
    font-weight: 600;
  }
  & .chartImg{
    height: 100px;
    display: flex ;
    align-items: center;
    & img{
      width: 100%;
      height: 100%;
      @include md{
    height: 150px;

      }
    }
  }
}