@mixin media-breakpoint($name) {
    @if $name == basic {
        @content;
    }
}

@mixin media-breakpoint-up($name) {
    @if $name ==sm {
        @media (min-width: 576px) {
            @content;
        }
    } @else if $name ==md {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $name ==lg {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $name ==xl {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $name ==xxl {
        @media (min-width: 1400px) {
            @content;
        }
    }
}

@mixin media-breakpoint-down($name) {
    @if $name ==sm {
        @media (max-width: 575.98px) {
            @content;
        }
    } @else if $name ==md {
        @media (max-width: 767.98px) {
            @content;
        }
    } @else if $name ==lg {
        @media (max-width: 991.98px) {
            @content;
        }
    } @else if $name ==xl {
        @media (max-width: 1199.98px) {
            @content;
        }
    } @else if $name ==xxl {
        @media (max-width: 1399.98px) {
            @content;
        }
    }
}

// .affiliatepagenew {
//   padding: 62px 50px 120px;
// }
.affiliateheading {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
}

.affiliatepagenew {
    & p {
        font-size: 14px;
    }

    & .affiliate_program_sec {
        & .program_content {
            & .btn_group {
                & button {
                    padding: 9px 38px;
                    transition: all 500ms;
                    box-shadow: 0 0 2px #cbcbcb;
                }

                & .btn_colored {
                    background: linear-gradient(to bottom, #7bc6ff, #187ac5);
                    color: #fff;

                    &:hover {
                        background: #242937;
                    }
                }
                & .btn_trans {
                    background: #242937;
                    color: #fff;

                    &:hover {
                        background: #424b61;
                    }
                }
            }
        }
    }

    & .work_sec {
        & .work_box {
            & .work_img {
                & .work_bg_img {
                    min-height: 150px;
                }
            }

            & .step_txt {
                font-size: 24px;
                color: #fff;
                flex-shrink: 0;
            }
        }
    }

    & .about_affiliate_sec {
        & .about_img {
            img {
                min-height: 240px;
                object-fit: contain;
            }
        }

        & .about_input {
            background: #191a24;
            & input {
                color: #fff;
                &::placeholder {
                    color: #fff;
                }
                &:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }
            }

            & button {
                background: #242937;
                color: #fff;
            }
        }
    }

    & .faq_sec {
        & .accordion {
            & .accordion-item {
                & .accordion-button {
                    border: none;
                    border-radius: 30px;
                    background: #202733;
                    color: #fff;
                    box-shadow: 0 0 2px #575757;

                    &::after {
                        filter: brightness(82.5);
                    }
                }
            }
        }
    }

    // affilaite after login css
    & .affiliate_after_login {
        & .affiliate_tabs {
            & .tab_list {
                background: #202733;

                & .nav-tabs {
                    overflow-x: auto;
                    cursor: grab;
                    user-select: auto;

                    &::-webkit-scrollbar {
                        width: 3px;
                        height: 3px;
                        background: #191a24;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #191a24;
                        border-radius: 10px;
                        height: 3px;
                    }

                    &::-webkit-scrollbar-track {
                        background: transparent;
                        height: 3px;
                    }

                    & .nav-link {
                        border: none !important;
                        color: #485b7c;
                        border-radius: 30px;
                        padding: 10px 35px;

                        &.active {
                            background: #333d4f;
                            color: #fff;
                        }
                    }
                }
            }
        }

        & .bg_main_color {
            background: #202733;
            border-radius: 11px;
        }
        & .rounded_bg {
            background: #191d27;
        }

        & .invite_code_sec {
            & .invite_list {
                & .list_item {
                    background: #191d27;
                    color: #0e95fa;
                    font-size: 14px;
                }
            }
            & .invite_refral {
                .refral_link {
                    background: #191d27;

                    & input {
                        outline: none !important;
                        color: #fff;

                        &::placeholder {
                            color: #fff;
                        }

                        &:focus {
                            outline: none !important;
                            box-shadow: none !important;
                        }
                    }
                    & button {
                        background: #202733;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        box-shadow: 0 0 2px #888888;
                        flex-shrink: 0;
                    }
                }
            }

            & .invite_social_links {
                & .social_items {
                    background: #191d27;

                    & img {
                        height: 35px;
                        width: 35px;
                        object-fit: contain;
                    }
                }
            }
        }

        & .invite_group_box {
            & .group_bx {
                background: #191d27;
                transition: all 500ms;

                & .group_img img {
                    transition: all 500ms;
                    height: 45px;
                    object-fit: contain;
                }

                &:hover {
                    & .group_img img {
                        transform: scale(1.1);
                    }
                }

                & .grp_txt {
                    font-size: 13px;
                }
            }
        }
        & .activity_sec {
            & .activity_content {
                & .empty_img {
                    & img {
                        height: 84px;
                        object-fit: contain;
                    }
                }
            }
        }

        & .live_reward_sec {
            & .live_bx {
                & .user_txt {
                    font-size: 14px;
                }

                & .live_sm_txt {
                    color: #0e95fa;
                    font-size: 14px;
                }
            }
            & .brd_live_right {
                border-right: 1px solid #1a496c;

                &:last-child {
                    border-right: none;
                }
            }
            & .brd_live_tp {
                border-top: 1px solid #1a496c;
            }
        }
    }
}

// partnership program  Section Start
// .partnershipSection {
//   .partnershipCardWrapper{
//       & .partnershipcolAuto{
//           background-color: $theme8;
//           border-radius: 12.774px;
//           padding: 35px;
//       }
//       & .partnerShipColAuto1 {
//           width: 260px;
//       }
//       & .partnerShipCol {
//           width: calc(100% - 260px);
//       }
//       & .cardTitle {
//           color: #FFF;
//           font-size: 14px;
//           font-weight: 600;
//           line-height: normal;
//           // margin-top: 10px;
//           // margin-bottom: 15px;
//           @include lg {
//             font-size: 22px;
//           }
//       }
//       & .carddetail {
//           color: #FFF;
//           font-size: 14px;
//           // font-weight: 500;
//           line-height: normal;
//           margin-top: 15px;
//       }
//       & .gamename{
//           color: #FFF;
//           font-size: 26px;
//           font-style: normal;
//           font-weight: 600;
//           line-height: normal;
//       }
//       & .gotoBtn{
//           border-radius: 8px;
//           background: $theme10;
//           box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
//           width: 210px;
//           height: 60px;
//           // color: $coloring1;
//           display: flex;
//           font-weight: 600;
//           align-items: center;
//           justify-content: center;
//           border: 0;
//           font-size: 18px;
//           color: white;
//           & img{
//               width: 20px;
//               height: 20px;
//               margin-left: 10px;
//               filter: brightness(100);
//           }
//       }
//       & .casinoicon {
//           width: 60px;
//           height: 60px;
//           margin-bottom: 10px;
//           & img {
//               object-fit: contain;
//               display: flex;
//           }
//       }
//   }
//   & .partnershiptitle {
//       color: $theme3;
//       font-size: 18px;
//       font-weight: 500;
//       line-height: normal;
//   }
//   & .parnershipCardMain {
//       margin-top: 1.5rem;
//       & .parnershipCard {
//           border-radius: 12px;
//           border: 1px solid #D6F050;
//           background: #161A25;
//           padding: 20px;
//           & .cardHeading {
//               color: #D6F050;
//               font-size: 24px;
//               font-weight: 800;
//               line-height: normal;
//               gap: 10px;
//               & .casinoicon {
//                   width: 25px;
//                   height: 25px;
//                   & img {
//                       object-fit: contain;
//                       display: flex;
//                   }
//               }
//           }
//           & .cardTitle {
//               color: #FFF;
//               font-size: 14px;
//               font-weight: 800;
//               line-height: normal;
//               margin-top: 10px;
//           }
//           & .carddetail {
//               color: #FFF;
//               font-size: 14px;
//               font-weight: 500;
//               line-height: normal;
//               margin-top: 15px;
//           }
//           & .gotocasino {
//               color: #D6F050;
//               font-size: 14px;
//               font-weight: 700;
//               line-height: normal;
//               gap: 5px;
//               margin-top: 10px;
//               & .gotocasinoicon {
//                   width: 12px;
//                   height: 12px;
//                   transition: all 0.3s ease-in-out;
//                   & img {
//                       object-fit: contain;
//                       display: flex;
//                   }
//               }
//           }
//           &:hover {
//               & .gotocasino {
//                   & .gotocasinoicon {
//                       transform: translateX(5px);
//                   }
//               }
//           }
//       }
//   }
// }
// partnership program  Section end

// Сommission plan Section Start
// .commissinplanSection {
//   padding-top: 40px;
//   & .commissioncard {
//       margin-top: 15px;
//       border-radius: 12px;
//       background: $theme8;
//       padding: 20px;
//       @include lg {
//         padding: 30px;
//         margin-top: 25px;
//       }
//       & .commissionData {
//           color: #FFF;
//           font-size: 16px;
//           font-weight: 500;
//           line-height: normal;
//       }
//       & .commissionTitle {
//           color: $theme3;
//           font-size: 16px;
//           font-weight: 400;
//           line-height: normal;
//           padding-top: 25px;
//       }
//   }
// }
// Сommission plan Section end

// Why becoming a  affiliate partner Section start
// .becomepartnerSection {
//   padding-top: 40px;
//   & .partnerCardMain {
//       background-color: $theme8;
//       border-radius: 12.774px;
//       padding: 35px;
//       margin-top: 25px;
//       & .partnerCard {
//           border-radius: 12px;
//           // border: 0.5px solid #D6F050;
//           background: $theme1;
//           padding: 35px 20px 20px;
//           @include lg {
//             padding: 30px;
//           }
//           & .partnerHeading {
//               color: #FFF;
//               font-size: 22px;
//               font-weight: 600;
//               line-height: normal;
//           }
//           & .partnerDetail {
//               color: #B2B6C5;
//               font-size: 16px;
//               font-weight: 500;
//               line-height: normal;
//               margin-top: 10px;
//           }
//           & .cardfooter {
//               position: absolute;
//               top: 0;
//               right: 0;
//               border-radius: 0px 12.774px;
//               background: $theme3;
//               width: 204.77px;
//               height: 44.331px;
//               & .ngrtitle {
//                   color: white;
//                   font-size: 14px;
//                   font-weight: 500;
//                   line-height: normal;
//                   white-space: nowrap;
//               }
//               & .cardlink {
//                   color: white;
//                   font-size: 14px;
//                   font-weight: 500;
//                   line-height: normal;
//                   gap: 8px;
//                   & .linkicon {
//                       width: 12px;
//                       height: 12px;
//                       transition: all 0.3s ease-in-out;
//                       filter: brightness(100);
//                   }
//               }
//           }
//           &:hover {
//               & .cardfooter {
//                   & .cardlink {
//                       & .linkicon {
//                           transform: translateX(7px);
//                       }
//                   }
//               }
//           }
//           & .ngrtitle1{
//               text-align: end;
//               color: $theme3 !important;
//               margin-top: 5px;
//           }
//       }
//   }
// }
// Why becoming a  affiliate partner Section end

// guidelines Section start
// .guidelinesSection {
//   padding-top: 40px;
//   & .guidelinescardmain {
//       margin-top: 15px;
//       & .guidelinescard {
//           padding: 20px ;
//           border-radius: 10px;
//           background: $theme8;
//           & .guidelinesimg {
//               height: 50px;
//               width: 50px;
//             //   margin-inline: auto;
//               & img {
//                   width: 100%;
//                   height: 100%;
//                   object-fit: contain;
//               }
//           }
//           & .guidelinedata {
//               color: #FFF;
//             //   text-align: center;
//               font-size: 15px;
//               font-weight: 500;
//               line-height: normal;
//               margin-top: 20px;
//           }
//           &.cardlast {
//               padding: 40px ;
//               gap: 35px;
//               & .guidelinesimg {
//                   min-width: 75px;
//                   max-width: 75px;
//                   height: 70px;
//                   & img {
//                       height: 100%;
//                       width: 100%;
//                       object-fit: contain;
//                   }
//               }
//           }
//       }
//   }
// }
// guidelines Section end

// faq section start
// .faqpage {
//   padding-top: 40px;
//   & .faqMain {
//       gap: 35px;
//   }
//   & .faqCard {
//       background-color: $theme8;
//       border-radius: 10px;
//       padding: 35px;
//       margin-top: 30px;
//       & .faqinner {
//           border-bottom: 1px solid #B2B6C5;
//           padding-bottom: 15px;
//           & .question {
//               color: #FFF;
//               font-size: 25px;
//               font-weight: 600;
//               line-height: normal;
//               gap: 15px;
//               & .faqcount {
//                   color: $theme3;
//                   font-size: 50px;
//                   font-weight: 900;
//                   line-height: 100%;
//               }
//           }
//           & .answer {
//               color: #B2B6C5;
//               font-size: 16px;
//               font-weight: 500;
//               line-height: normal;
//               margin-top:8px;
//           }
//       }
//   }
// }
// faq section end

// // Join Us section start
// .JoinusSection {
//   padding-top: 40px;
//   & .joinuscard {
//       margin-top: 30px;
//       margin-bottom: 30px;
//       background-color: $theme8;
//       padding: 20px;
//       border-radius: 10px;
//       @include lg {
//         padding: 35px
//       }
//       & .signuptitle {
//           color: #FFF;
//           font-size: 25px;
//           font-weight: 700;
//           margin-bottom:10px;
//           line-height: normal;
//           @include lg {
//             margin-bottom: 20px;
//           }
//       }
//   }
//   & .inputmain {
//       height: 33px;
//       border-radius: 5px;
//       border: 1px solid rgba(255, 255, 255, 0.50);
//       & .affiliateInput {
//           color: #fff;
//           font-size: 13px;
//           font-weight: 400;
//           line-height: normal;
//           &::placeholder {
//             color: #b2b6c5;
//           }
//       }
//       & .affiliateinputLabel {
//           color: rgba(255, 255, 255, 0.50);
//           font-size: 13px;
//           font-weight: 400;
//           line-height: normal;
//           left: 10px;
//           top: 50%;
//           transform: translateY(-50%);
//           transition: all 0.3s ease-in-out;
//           padding: 0px 7px;
//       }
//       & .affiliateInput:focus ~ .affiliateinputLabel {
//           top: 0;
//           background-color: #161A25;
//       }
//       & .passwordvisible {
//           height: 15px;
//           width: 15px;
//           margin:0 13px;
//       }
//   }
//   & .socialmedia {
//       & .instagramdropdown {
//           & .instabtn {
//               border-radius: 5px;
//               background: #0D131C;
//               height: 38px;
//               color: #FFF;
//               font-size: 13px;
//               font-weight: 400;
//               line-height: normal;
//               padding: 0 17px;
//               gap: 22px;
//               border: none;
//               &::after {
//                   font-size: 17px;
//               }
//           }
//           & .instamenu  {
//               min-width: auto;
//               background-color:#1b202d;
//               margin-top: 3px;
//               padding: 8px;
//               & .socialmedialink {
//                   color: #FFF;
//                   font-size: 13px;
//                   font-weight: 400;
//                   line-height: normal;
//                   padding: 7px 0 7px 13px;
//                   border: 1px solid #1b202d;
//                   border-radius: 3px;
//                   transition: all 0.3s ease-in-out;
//                   margin-top: 4px;
//                   &:hover {
//                       border-color: $theme3;
//                   }
//                   &.active {
//                       border-color: $theme3;
//                   }
//               }
//           }
//       }
//   }
//   & .checkinput {
//       max-height: 18px;
//       min-height: 18px;
//       min-width: 18px;
//       max-width: 18px;
//       border-radius: 3px;
//       border: 0.8px solid $theme3;
//       background-color: transparent;
//       background-size: 12px 9px;
//       &:checked {
//           background-color: $theme3;
//           // background-image: url(../img/affiliatenew/check2.png);
//       }
//   }
//   & .termsservice {
//       color: #FFF;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: normal;
//       gap: 10px;
//       cursor: pointer;
//       & .conditiontitle {
//           color: $theme3;
//       }
//   }
//   & .resisterbtn {
//       border-radius: 5px;
//       background: $theme3;
//       height: 37px;
//       color: #fff;
//       font-size: 14px;
//       letter-spacing: 0.5px;
//       text-transform: uppercase;
//       font-weight: 600;
//       line-height: normal;
//       margin-top: 15px;
//       transition:all 0.3s ease-in-out;
//       &:active {
//          background: $theme3;
//       }
//       &:hover {
//          background: $theme3;
//       }
//   }
// }

// Join Us section end

@include media-breakpoint-down(md) {
    //   .affiliatepagenew {
    //       // padding: 30px 20px 70px;
    //   }
    //   .affiliateheading {
    //       font-size: 24px;
    //   }

    // partnership program  Section Start
    //   .partnershipSection {
    //       & .partnershiptitle {
    //           font-size: 14px;
    //       }
    //       & .parnershipCardMain {
    //           & .parnershipCard {
    //               & .cardHeading {
    //                   font-size: 20px;
    //                   & .casinoicon {
    //                   }
    //               }
    //               & .cardTitle {
    //               }
    //               & .carddetail {
    //                   font-size: 14px;
    //               }
    //               & .gotocasino {
    //                   font-size: 14px;
    //                   & .gotocasinoicon {
    //                   }
    //               }
    //               &:hover {
    //                   & .gotocasino {
    //                       & .gotocasinoicon {
    //                       }
    //                   }
    //               }
    //           }
    //       }
    //   }
    // partnership program  Section end

    // Сommission plan Section Start
    //   .commissinplanSection {
    //       & .commissioncard {
    //           & .commissionData {
    //               font-size: 14px;
    //           }
    //           & .commissionTitle {
    //               font-size: 14px;
    //           }
    //       }
    //   }
    // Сommission plan Section end

    // Why becoming a  affiliate partner Section start
    //   .becomepartnerSection {
    //       & .partnerCardMain {
    //           & .partnerCard {
    //               & .partnerHeading {
    //                   font-size: 18px;
    //               }
    //               & .partnerDetail {
    //                   font-size: 14px;
    //               }
    //               & .cardfooter {
    //                   & .ngrtitle {
    //                       font-size: 14px;
    //                   }
    //                   & .cardlink {
    //                       font-size: 14px;
    //                       & .linkicon {
    //                       }
    //                   }
    //               }
    //               &:hover {
    //                   & .cardfooter {
    //                       & .cardlink {
    //                           & .linkicon {
    //                           }
    //                       }
    //                   }
    //               }
    //           }
    //       }
    //   }
    // Why becoming a  affiliate partner Section end

    // guidelines Section start
    //   .guidelinesSection {
    //       & .guidelinescardmain {
    //           & .guidelinescard {
    //               & .guidelinesimg {
    //                   height: 45px;
    //               }
    //               & .guidelinedata {
    //                   font-size: 14px;
    //                 //   text-align: center !important;
    //               }
    //               &.cardlast {
    //                   flex-direction: column;
    //                   gap: 20px;
    //                   padding: 20px;
    //                   & .guidelinesimg {
    //                       height: 50px;
    //                       max-width: 50px;
    //                       min-width:50px;
    //                   }
    //               }
    //           }
    //       }
    //   }
    // guidelines Section end

    // faq section start
    //   .faqpage {
    //       padding-top: 50px;
    //       & .faqMain {
    //           gap: 25px;
    //       }
    //       & .faqCard {
    //           padding: 20px;
    //           margin-top:20px;
    //           & .faqinner {
    //               & .question {
    //                   font-size: 16px;
    //                   & .faqcount {
    //                       font-size: 40px;
    //                   }
    //               }
    //               & .answer {
    //                   font-size: 14px;
    //               }
    //           }
    //       }
    //   }
    // faq section end

    // Join Us section start
    //   .JoinusSection {
    //       & .joinuscard {
    //           & .signuptitle {
    //               font-size: 18px;
    //           }
    //       }
    //       & .inputmain {
    //           & .affiliateInput {
    //           }
    //           & .affiliateinputLabel {
    //           }
    //           & .affiliateInput:focus ~ .affiliateinputLabel {
    //           }
    //           & .passwordvisible {
    //           }
    //       }
    //       & .socialmedia {
    //           & .instagramdropdown {
    //               & .instabtn {
    //                   &::after {
    //                   }
    //               }
    //               & .instamenu  {
    //                   & .socialmedialink {
    //                       &:hover {
    //                       }
    //                       &.active {
    //                       }
    //                   }
    //               }
    //           }
    //       }
    //       & .checkinput {
    //           &:checked {
    //           }
    //       }
    //       & .termsservice {
    //           font-size: 13px;
    //           gap: 15px;
    //           & .conditiontitle {
    //           }
    //       }
    //       & .resisterbtn {
    //           margin-top: 27px;
    //           &:active {
    //           }
    //           &:hover {
    //           }
    //       }
    //   }
    // Join Us section end
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .becomepartnerSection {
        & .partnerCardMain {
            & .partnerCard {
                & .partnerHeading {
                    font-size: 20px;
                    margin-top: 10px;
                }
                & .partnerDetail {
                    font-size: 14px;
                }
                & .cardfooter {
                    width: 190.77px;
                    height: 35.331px;
                    margin-bottom: 8px;
                    & .ngrtitle {
                    }
                    & .cardlink {
                        font-size: 13px;
                        & .linkicon {
                        }
                    }
                }
                &:hover {
                    & .cardfooter {
                        & .cardlink {
                            & .linkicon {
                            }
                        }
                    }
                }
                & .ngrtitle1 {
                }
            }
        }
    }
    .partnershipSection {
        .partnershipCardWrapper {
            & .partnershipcolAuto {
                padding: 20px;
            }
            & .cardTitle {
            }
            & .carddetail {
            }
            & .gamename {
                font-size: 25px;
            }
            & .gotoBtn {
                width: 200.945px;
                height: 55.637px;
                & img {
                }
            }
            & .casinoicon {
                & img {
                }
            }
        }
        & .partnershiptitle {
        }
        & .parnershipCardMain {
            & .parnershipCard {
                & .cardHeading {
                    & .casinoicon {
                        & img {
                        }
                    }
                }
                & .cardTitle {
                }
                & .carddetail {
                }
                & .gotocasino {
                    & .gotocasinoicon {
                        & img {
                        }
                    }
                }
                &:hover {
                    & .gotocasino {
                        & .gotocasinoicon {
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .becomepartnerSection {
        & .partnerCardMain {
            & .partnerCard {
                & .partnerHeading {
                    font-size: 20px;
                    margin-top: 10px;
                }
                & .partnerDetail {
                    font-size: 14px;
                }
                & .cardfooter {
                    width: 190.77px;
                    height: 35.331px;
                    margin-bottom: 8px;
                    & .ngrtitle {
                    }
                    & .cardlink {
                        font-size: 10px;
                        & .linkicon {
                        }
                    }
                }
                &:hover {
                    & .cardfooter {
                        & .cardlink {
                            & .linkicon {
                            }
                        }
                    }
                }
                & .ngrtitle1 {
                }
            }
        }
    }
    .partnershipSection {
        .partnershipCardWrapper {
            & .partnershipcolAuto {
                padding: 20px;
            }
            & .cardTitle {
            }
            & .carddetail {
            }
            & .gamename {
                font-size: 25px;
            }
            & .gotoBtn {
                width: 200.945px;
                height: 55.637px;
                & img {
                }
            }
            & .casinoicon {
                width: 40px;
                height: 40px;
                & img {
                }
            }
        }
        & .partnershiptitle {
        }
        & .parnershipCardMain {
            & .parnershipCard {
                & .cardHeading {
                    & .casinoicon {
                        & img {
                        }
                    }
                }
                & .cardTitle {
                }
                & .carddetail {
                }
                & .gotocasino {
                    & .gotocasinoicon {
                        & img {
                        }
                    }
                }
                &:hover {
                    & .gotocasino {
                        & .gotocasinoicon {
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .becomepartnerSection {
        & .partnerCardMain {
            & .partnerCard {
                & .partnerHeading {
                    font-size: 20px;
                    margin-top: 10px;
                }
                & .partnerDetail {
                    font-size: 14px;
                }
                & .cardfooter {
                    width: 190.77px;
                    height: 35.331px;
                    margin-bottom: 8px;
                    & .ngrtitle {
                    }
                    & .cardlink {
                        font-size: 12px;
                        & .linkicon {
                        }
                    }
                }
                &:hover {
                    & .cardfooter {
                        & .cardlink {
                            & .linkicon {
                            }
                        }
                    }
                }
                & .ngrtitle1 {
                }
            }
        }
    }
    .partnershipSection {
        .partnershipCardWrapper {
            & .partnershipcolAuto {
                padding: 20px;
            }
            & .cardTitle {
            }
            & .carddetail {
            }
            & .gamename {
                font-size: 25px;
            }
            & .gotoBtn {
                width: 178.945px;
                height: 42.637px;
                & img {
                }
            }
            & .casinoicon {
                & img {
                }
            }
        }
        & .partnershiptitle {
        }
        & .parnershipCardMain {
            & .parnershipCard {
                & .cardHeading {
                    & .casinoicon {
                        & img {
                        }
                    }
                }
                & .cardTitle {
                }
                & .carddetail {
                }
                & .gotocasino {
                    & .gotocasinoicon {
                        & img {
                        }
                    }
                }
                &:hover {
                    & .gotocasino {
                        & .gotocasinoicon {
                        }
                    }
                }
            }
        }
    }
    .affiliatepagenew {
        & .affiliate_after_login {
        }
    }
}

@media (max-width: 767px) {
    .affiliateheading {
        font-size: 22px;
    }
    // .becomepartnerSection {
    //     & .partnerCardMain {
    //         padding: 0;
    //         background-color: transparent;
    //         & .partnerCard {
    //             background-color: $theme8;
    //             & .partnerHeading {
    //                 font-size: 20px;
    //                 margin-top: 10px;
    //             }
    //             & .partnerDetail {
    //                 font-size: 14px;
    //             }
    //             & .cardfooter {
    //                 width: 190.77px;
    //                 height: 35.331px;
    //                 margin-bottom: 8px;
    //                 & .ngrtitle {
    //                 }
    //                 & .cardlink {
    //                     font-size: 10px;
    //                     & .linkicon {
    //                     }
    //                 }
    //             }
    //             &:hover {
    //                 & .cardfooter {
    //                     & .cardlink {
    //                         & .linkicon {
    //                         }
    //                     }
    //                 }
    //             }
    //             & .ngrtitle1 {
    //             }
    //         }
    //     }
    // }
    // .partnershipSection {
    //     .partnershipCardWrapper {
    //         & .partnershipcolAuto {
    //             padding: 0px;
    //         }
    //         & .partnerShipColAuto1 {
    //             width: 100%;
    //             & .partnershipcolAuto {
    //                 border-radius: 10px 10px 0 0;
    //                 & .iconCol {
    //                     width: 110px;
    //                     height: 110px;
    //                     border-right: 8px solid $theme1;
    //                     border-bottom: 8px solid $theme1;
    //                     border-radius: 0 0 20px 0;
    //                 }
    //                 & .txtCol {
    //                 }
    //             }
    //         }
    //         & .partnerShipCol {
    //             width: 100%;
    //             & .partnershipcolAuto {
    //                 border-radius: 0 0 10px 10px;
    //             }
    //         }
    //         & .cardTitle {
    //         }
    //         & .carddetail {
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             display: -webkit-box;
    //             -webkit-line-clamp: 3;
    //             -webkit-box-orient: vertical;
    //             font-size: 12px;
    //         }
    //         & .gamename {
    //             font-size: 22px;
    //             margin-top: 10px;
    //         }
    //         & .gotoBtn {
    //             width: 160.945px;
    //             height: 32.637px;
    //             font-size: 12px;
    //             margin-bottom: 10px;
    //             margin-left: auto;
    //             & img {
    //                 width: 15px;
    //                 height: 15px;
    //             }
    //         }
    //         & .casinoicon {
    //             width: 50px;
    //             height: 50px;
    //             margin-bottom: 0;
    //             & img {
    //             }
    //         }
    //     }
    //     & .partnershiptitle {
    //     }
    //     & .parnershipCardMain {
    //         & .parnershipCard {
    //             & .cardHeading {
    //                 & .casinoicon {
    //                     & img {
    //                     }
    //                 }
    //             }
    //             & .cardTitle {
    //             }
    //             & .carddetail {
    //             }
    //             & .gotocasino {
    //                 & .gotocasinoicon {
    //                     & img {
    //                     }
    //                 }
    //             }
    //             &:hover {
    //                 & .gotocasino {
    //                     & .gotocasinoicon {
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .affiliatepagenew {
        & .affiliate_after_login {
            & .invite_code_sec {
                & .invite_list {
                    & li {
                        width: 100%;
                    }
                }
            }

            & .live_reward_sec {
                & .brd_live_right {
                    border-bottom: 1px solid #1a496c;
                    &:nth-child(even) {
                        border-right: none !important;
                    }
                }
                & .brd_live_tp {
                    border-top: none;
                }

                & button {
                    & .btn_img {
                        & img {
                            height: 16px;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        & .about_affiliate_sec {
            & .about_input button {
                font-size: 13px;
            }
        }

        & .affiliate_program_sec {
           & .program_content {
              &  .btn_group {
                  justify-content: space-between;
              button {
                  width: 100%;
                  padding: 9px 20px;
                  font-size: 14px;
                }
            }
            }
        }
    }
}

@media (min-width: 360px) and (max-width: 575px) {
    // .becomepartnerSection {
    //     & .partnerCardMain {
    //         & .partnerCard {
    //             & .partnerHeading {
    //                 font-size: 20px;
    //                 margin-top: 10px;
    //             }
    //             & .partnerDetail {
    //                 font-size: 14px;
    //             }
    //             & .cardfooter {
    //                 width: 190.77px;
    //                 height: 35.331px;
    //                 margin-bottom: 8px;
    //                 & .ngrtitle {
    //                 }
    //                 & .cardlink {
    //                     font-size: 10px;
    //                     & .linkicon {
    //                     }
    //                 }
    //             }
    //             &:hover {
    //                 & .cardfooter {
    //                     & .cardlink {
    //                         & .linkicon {
    //                         }
    //                     }
    //                 }
    //             }
    //             & .ngrtitle1 {
    //             }
    //         }
    //     }
    // }
}
