.seachNewPop{
    position: fixed;
    inset: 0;
    background-color: #202733;
    backdrop-filter: blur(10px);
    height: 100%;
    width: 100%;
    z-index: 99999;
    & .innerContainer{
        padding: 8px;
        height: 100vh;
        overflow: hidden;
        @include md{
        padding: 20px;
        }
        & .sideBar{
            & .sideMenuBar{
                padding: 3px;
                @include lg{
                    overflow-y: auto;
                }
                & .sideMenu{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    @include lg{
                        display: flex;
                        flex-direction: column;
                    }
                    & .sideMenuLink{
                        display: flex;
                        align-items: center;
                        color: #B2B6C5;
                        font-size: 14px;
                        font-weight: 400;
                        text-decoration: none;
                        text-wrap: nowrap;
                        width: 100%;
                        cursor: pointer;
                        text-transform: capitalize;
                        padding: 6px 7px 6px 7px !important;
                        & .linkIcon{
                            height: 25px;
                            width: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 5px;

                            & img{
                                filter: brightness(17.5);
                            }
                        }
                        &.active{
                            color: #fff;
                            background: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%);
                            & .linkIcon{
                               background: transparent;
                               border-color: #fff;
                            }
                        }
                    }
                }
            }
        }
        & .flex11auto{
            flex: 1 1 auto;
            & .mainContent{
                height: 100%;
                background-color: #191D27;
                overflow: hidden;
                border-radius: 10px;
                @include lg{
                    
                    padding: 18px 7px;
                }
                & .innerSection{
                    height: 100%;
                    // background-color: rgba(#3B6397,0.2);

                    overflow: hidden;
                    @include lg {
                        padding: 8px 5px;

                    }
                    & .innerSection2{
                        overflow: hidden;
                        
                        height: 100%;
                        @include lg {
                            padding: 13px 10px;

                        }
                        & .innerSection3{
                            overflow: hidden;
                            
                            height: 100%;
                            padding: 10px;
                            @include lg{
                                padding: 24px 24px;
                                padding-top: 0;
                                
                            }
                            & .SerachGroup{
                                background-color: #242937;
                                height: 40px;
                                width: 100%;
                                border-radius: 30px;
                                & .iconSearch{
                                    height: 100%;
                                    background-color: transparent;
                                    border: none;
                                    & img{
                                        height:15px;
                                        width: 15px;
                                        @include sm{
                                            height:17px;
                                            width: 17px;
                                        }
                                    }
                                }
                                & .inputSearch{
                                    color: #B2B6C5;
                                    font-size: 12px;
                                    font-weight: 500;
                                    background-color: transparent;
                                    border: none;
                                    padding-left: 0;
                                    &::placeholder{
                                        color: #B2B6C5;
                                        font-size: 12px;
                                        font-weight: 500;
                                        @include sm{
                                            font-size: 14px;
                                            font-weight: 500;
                                        }
                                    }
                                    &:focus{
    
                                        box-shadow: none;
                                        border: none;
                                    }
                                    @include sm{
                                        font-size: 14px;
                                        font-weight: 500;
                                    }
                                }
                            }
                            & .dropDown{
                                position: relative;
                                & .dropBtn{
                                    color: #B2B6C5;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    padding: 10px 16px;
                                    border-radius: 30px;
                                    line-height: normal;
                                    text-transform: uppercase;
                                    background-color: $theme10;
                                    box-shadow: none;
                                    border: 0;
                                    height: 40px;
                                    text-align: start;
                                    width: 100%;
                                    @include sm{
                                        font-size: 13px;
                                        font-weight: 600;
                                    }
                            
                                    & span{
                                        color: #fff;
                                        margin-left: 5px;
                                    }
                                 
                                }
                                & .dropMenu{
                                    min-width: 5rem !important;
                                    &.dropSort{
                                        height: 135px;
                                        width: 100%;
                                    }
                                    width: 200px;
                                    height: 328px;
                                    background-color: $theme10;
                                    padding: 12px;
                                    overflow: hidden;
                                    position: absolute;
                                    top: 100% !important;
                                    right: 0 !important;
                                    border-radius: 4px;
                                    z-index: 6;
                                    margin-bottom: 10px;
                                    transform: translate(0,0) !important;
                                    &.show {
                                        display: block !important;
                                    }
                                    & .dropHeader{
                                        // margin-bottom: 10px;
                                        // padding-bottom: 10px;
                                        // border-bottom: 1px solid #394956;
                                        & .dropSearch{
                                            width: 100%;
                                            background-color: #15191F;
                                            border: 0;
                                            border-radius: 4px;
                                            position: relative;
                                            color: rgb(178, 182, 197);
                                            height: 35px;
                                            font-size: 12px; 
                                            padding-left: 30px;
                                            padding-right: 10px;
                                            &::placeholder{
                                                color: rgb(178, 182, 197) !important;
                                            }
                                            &:focus-visible{
                                                outline: unset;
                                            }
                                        }
                                        & .serachImg{
                                            height: 13px;
                                            width: 13px;
                                            position: absolute;
                                            top: 50%;
                                            left: 10px !important;
                                            transform: translateY(-50%);
                                            & img{
                                                height: 100%;
                                                width: 100%;
                                                object-fit: contain;
                                                display: flex;
                                            }
                                        }
                                    }
                                    & .bodyWrapper{
                                        overflow-y: auto;
                                        height: 260px;
                                        padding-bottom: 20px;
                                        & .dropBody{
                                            & .formCheck{
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                padding-left: 0;
                                                & .dropdownInp {
                                                    &:checked {
                                                        & ~ .dropdownLabel {
                                                            & .dropdownCheckbox {
                                                                background-color: #15191F;
                                                                &::before {
                                                                    display: flex;
                                                                }
                                                        }
                                                    }
                                                    }
                                                }
                                                & .dropdownLabel {
                                                    width: 100%;
                                                    cursor: pointer;
                                                    & .dropdownCheckbox {
                                                        height: 17px;
                                                        width: 17px;
                                                        margin: 0px 8px 0 0 ;
                                                        min-width: 17px;
                                                        display: flex;
                                                        border: 2px solid $theme3;
                                                        border-radius: 4px;
                                                        cursor: pointer;
                                                        position: relative;
                                                        &::before {
                                                            content: "";
                                                            position: absolute;
                                                            top: 40%;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%) rotate(45deg);
                                                            height: 10px;
                                                            width: 7px;
                                                            border-right: 3px solid $theme3;
                                                            border-bottom: 3px solid $theme3;
                                                            border-radius: 2px;
                                                            display: none;
                                                        }
                                                    }
                                                    & .dropdownTxt {
                                                        color: white;
                                                        font-size: 13px;
                                                    }
                                                    & .dropdownValue{
                                                        background-color: #2F3A47;
                                                        width: 28px;
                                                        height: 28px;
                                                        border-radius: 50%;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        color: white;
                                                        font-size: 10px;
                            
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                             
                            }
                            & .dropdownsimple{
                                & .dropBtn{
                                    color: #B2B6C5;
                                    font-size: 12px;
                                    font-weight: 400;
                                    font-style: normal;
                                    padding: 10px 16px;
                                    border-radius: 30px;
                                    line-height: normal;
                                    text-transform: uppercase;
                                    background-color: $theme10;
                                    box-shadow: none;
                                    border: 0;
                                    text-align: start;

                                    height: 40px;
                                    width: 100%;
                                    @include sm{
                                        font-size: 13px;
                                        font-weight: 600;
                                    }
                                    & span{
                                        color: #fff;
                                        margin-left: 5px;
                                    }
                                 
                                }
                                & .DropMenuSimple{
                                    min-width: 5rem !important;
                                    background-color: #242937;
                                    padding: 12px;
                                    overflow: hidden;
                                    border-radius: 4px;
                                    & .dropdownTxt{
                                        color: white;
                                        font-size: 13px;
                                        &:hover{
                                            background-color: transparent;
                                        }
                                    }
                                    
                                }
                            }
                            & .bottomCont{
                                flex: 1 1 auto;
                                & .scrollPart{
                                    overflow-y: auto !important;
                                    height: 100% !important;
                                    overflow-x: hidden;
                                }
                                & .loadmoremain {
                                    background-color: $theme8;
                                    font-size: 14px;
                                    display: inline-flex !important;
                                    padding: 4px 22px;
                                    height: 36px;
                                    margin-inline: auto;
                                    border-radius: 30px;
                                }
                            }
                         
                          
                        }
                    }
                }
            }
        }
        & .closeBtn{
            height: 30px;
            width: 100%;
            padding: 8px;
            background-color: #191d27;
            display: flex;
            border: 0;
            align-items: end;
            justify-content: end;
            cursor: pointer;
            @include sm{
            padding: 9px;

            }
            & img{
                height: 100%;
                object-fit: contain;
            }
            &::after{
                position: absolute;
                    content: "";
                    top: 15px;
                    left: -44px;
                    height: 9px;
                    width: 50px;
                    z-index: -1;
                    background-color: #2E3443;
                    display: none;
                    @include lg{
                        display: block;
                    }
            }
        }
    }
}

@media (min-width: 450px) and (max-width: 576px) {
    .searchColsWrapper {
        & > * {
            width: 25% !important;
        }
    }
}

@media (min-width: 1120px) and (max-width: 1200px) {
    .searchColsWrapper {
        & > * {
            width: 16.66% !important;
        }
    }
}

@media (min-width: 1320px) and (max-width: 1500px) {
    .searchColsWrapper {
        & > * {
            width: 14.28% !important;
        }
    }
}

@media (min-width: 1500px){
    .searchColsWrapper {
        & > * {
            width: 12.5% !important;
        }
    }
}
