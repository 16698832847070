body{
  .rotate-90{
    transform: rotate(90deg);
   }
  &.sidebarToggle{
    & .appLayout{
      & .sidebar{
        width: 64px;
        & .sidebarInner {
          // display: none !important;
          padding: 15px 10px;
          & .closeside {
            height: 44px;
            width: 44px;
            & img {
              transform: rotate(180deg);
            }
          }
          & .smallHiddden {
            // display: none !important;
            width: 0 !important;
            height: 0 !important;
            overflow: hidden;
          }
          & .sidebarLink {
            min-height: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:not(:hover, .active) {
              background-color: transparent;
              border-color: transparent;
            }
            & .sidebarImg {
               
              &.small {
                & img {
                  height: 18px;
                  width: 18px;
                }
              }
              & img {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
        & .sidebarSmall{
          padding: 100px 0 0 0;
          transition: 0.3s linear;
          @include lg{
            padding: 0px 0 0 0;
          }
        }
        & .openSidebar{
          width: 34px;
          height: 34px;
          border-radius: 5px;
          background-color: $theme8;
          display: flex;
          justify-content: center;
          align-items: center;
          & img{
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }
        & .sidebarlinks{
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:is(:hover, .active){
            width: 50px;
            height: 50px;
            background-color: $theme8;
            border-radius: 3px;
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          & img{
            width: 22px;
            height: 22px;
            object-fit: contain;
          }
        }
      }
      & .scrollPart{
        width: 100%;
        @include lg{
          width: calc(100% - 64px) !important;
        }
      }
    }
  }
  &:not(.sidebarToggle){
    & .sidebarSmall{
      // display: none !important;
    }
  }
}



.sidebar {
  & .sidebarInner {
    padding: 15px;
    @include lg {
      padding: 15px;
    }
  }
  & .searchInput {
    & input {
      background-color: $theme1;
      color: $theme5;
      // font-family: retrotech;
      font-size: 12px;
      border: 0;
      height: 40px;
      border-radius: 5px;
      padding-left: 35px;
      &::placeholder {
        color: $theme5;
      }
    }
    & img {
      height: 16px;
      object-fit: contain;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  & .groupDropdown {
    height: 40px;
    padding-block: 5px;
    border-radius: 5px;
    background-color: $theme1;
    display: flex;
    align-items: center;
    & .countryDropdown {
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba($theme5, 0.2);
      cursor: pointer;
      &:last-child {
        border-right: 0;
      }
      & .countryImg {
        & img {
          height: 19px;
          width: 19px;
          object-fit: contain;
        }
      }
    }
  }
  & .sidebarLink {
    padding: 10px 12px;
    background-color: $theme8;
    // margin-bottom: 6px;
    cursor: pointer;
    min-height: 35px;
    border-radius: 5px;
    border: 1px solid $theme8;
    &:is(:hover, .active) {
      // border: 1px solid $theme5;
      border-radius: 5px;
      background-color: #363B4B;
      & .sidebarImg{
        // filter: brightness(10);
      }
    }
    &.sidebarLinkbody{
      &:is(:hover, .active){
        border: 1px solid $theme5;
        border-radius: 5px;
        background-color: #363B4B;
        & .sidebarImg{
          // filter: brightness(10);
        }
      }
    }
    & .sidebarImg {
      & img {
        height: 26px;
        width: 26px;
        object-fit: contain;
        transition: 0.3s linear;
      }
      &.small {
        & img {
          height: 22px;
          width: 22px;
        }
      }
    }
    & .accrodianBtnTxt {
      font-size: 14px;
      font-weight: 500;
      color: white;
      &.accrodianBtnTxtBody{
        color: $theme5;
      }
      &.accrodianBtnsmall{
        font-size: 11px;
        font-weight: 500;
        color: $theme5;
      }
      &.tilted {
        font-family: RETROTECH;
      }
      & .pokerSpan {
        font-size: 9px;
        color: $theme3;
        padding-left: 4px;
        filter: drop-shadow(0 0 2px $theme3);
      }
    }
    & .dambleCoin {
      font-size: 15px;
      color: white;
      font-weight: 600;
      text-transform: uppercase;
      line-height: normal;
      & span {
        font-size: 12px;
        color: $theme5;
        font-weight: 600;
        font-family: retrotech;
      }
    }
    & .buyPlz {
      font-size: 9px;
      color: #C7A1FD;
      font-weight: 600;
      font-family: retrotech;
    }
    & .rightArrow {
      transition: 0.3s ease-in-out;
      transform: rotate(90deg);
      & img {
        height: 15px;
        object-fit: contain;
      }
      &.small {
        & img {
          height: 12px;
        }
      }
    }
    &:is(:hover, .active) {
      // background-color: $theme1;
      // border-radius: 5px;
    }
  }
  & .accordion {
    & .accordion-item {
      border-radius: 0 !important;
      background-color: transparent !important;
      border: 0 !important;
      & .accordion-header {
          .collapsed{
          .rotate-90{

    transform: rotate(0deg);
   }
          }
          .rotate-90{
            transform: rotate(90deg);
           }
        & .accordion-button {
          border-radius: 0 !important;
          background-color: transparent !important;
          border: 0 !important;
          box-shadow: none !important;
          padding: 0 !important;
          &::after {
            display: none !important;
          }
          & .sidebarLink {
            & .rightArrow {
              & img {
                opacity: 0.65;
              }
            }
          }
          &.collapsed {
          }
          &:not(.collapsed) {
            border-bottom: 1px solid #363B4B;
            // background-color: $theme1 !important;
            // border-bottom: 1px solid #363B4B !important;
            border-radius: 5px 5px 0 0 !important;
            overflow: hidden;
            & .sidebarLink {
              border-radius: 5px 5px 0 0 ;
              // border: 1px solid $theme5;
              border-radius: 5px;
              background-color: #363B4B;
              & .sidebarImg{
                // filter: brightness(10);
              }
              & .accrodianBtnTxt {
                color: white;
              }
              & .rightArrow {
                transform: rotate(270deg);
                & img {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      & .accordion-collapse {
        // background-color: $theme1;
        border-radius: 0 0 5px 5px;
        &.collapse {
        }
        &.show {}
        & .accordion-body {
          padding: 0;
          // & .sidebarLink {
          //   border-radius: 0;
          //   &:is(:hover, .active) {
          //     background-color: $theme2;
          //   }
          // }
        }
      }
    }
  }
  & .sidebarBottom {
    // position: fixed;
    // bottom: 0px;
    // left: 0;
    // right: 0;
    background-color: $theme10;
    padding: 15px 0;
    // background-color: ;
    & .getCryptoBtn {
      font-size: 15px;
      font-weight: 600;
      color:$theme5;
      background-color: #32394B;
      border-radius: 3px;
      height: 40px;
      border-bottom: 4px solid #272D3D !important;
    }
    & .paymentMethod {
      margin-top: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & img {
        height: 17px;
        display: flex;
        object-fit: contain;
      }
    }
  }
  & .dblTxt{
    & img{
      height: 21.31px;
    }
    & span{
      font-size: 15px;
      font-weight: 500;
      color: $theme5;
    }
  }
  & .dblTxtsub{
    font-size: 17px;
    font-weight: 600;
    color: white;
    & span{
      font-size: 13px;
      font-weight: 600;
      color: #FFC700DB;
      & img{
        height: 12.75px;
        width: 12.75px;
        object-fit: contain;
      }
    }
  }
  & .closeside{
    width: 34px;
    height: 34px;
    border-radius: 5px;
    background-color: $theme8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & img{
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }
  & .headerBtns{
    height: 35px;
    background-color: $theme8;
    font-size: 13px;
    font-weight: 500;
    color: white;
    border-radius: 5px;
    width: 100%;
    border: 0;
  }
}
.paddingBottom{
  // padding-bottom: 128px;
}
.accorduinBodyBg{
  background-color: $theme8;
  border-radius: 0 0 5px 5px;
  & .sidebarLink{
    &:last-child{
      border-radius: 0 0 5px 5px !important;
    }
  }
}


@media (min-width: 992px) and (max-width: 1200px) {
  .appLayout{
    & .mainContent {
      padding-left: 64px;
    }
    & .sidebar{
      width: 64px !important;
      position: absolute !important;
      top: 0;
      left: 0;

      & .sidebarInner {
        // display: none !important;
        // padding: 75px 10px 10px !important;
        & .closeside {
          height: 44px;
          width: 44px;
          & img {
            transform: rotate(180deg);
          }
        }
        & .smallHiddden {
          // display: none !important;
          width: 0 !important;
          height: 0 !important;
          overflow: hidden;
        }
        & .sidebarLink {
          min-height: 35px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:not(:hover, .active) {
            background-color: transparent;
            border-color: transparent;
          }
          & .sidebarImg {
            &.small {
              & img {
                height: 18px;
                width: 18px;
              }
            }
            & img {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      & .sidebarSmall{
        padding: 100px 0 0 0;
        transition: 0.3s linear;
        @include lg{
          padding: 0px 0 0 0;
        }
      }
      & .openSidebar{
        width: 34px;
        height: 34px;
        border-radius: 5px;
        background-color: $theme8;
        display: flex;
        justify-content: center;
        align-items: center;
        & img{
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
      }
      & .sidebarlinks{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:is(:hover, .active){
          width: 50px;
          height: 50px;
          background-color: $theme8;
          border-radius: 3px;
          display: block;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & img{
          width: 22px;
          height: 22px;
          object-fit: contain;
        }
      }
    }
    & .scrollPart{
      width: 100% !important;
    }
  }

  body {
    &.sidebarToggle {
      & .appLayout {
        & .mainContent {
          & .sidebar {
            width: 260px !important;
            & .sidebarInner {
         
              & .sidebarLink {
                // min-height: 45px !important;
                width: 100% !important;
                // height: 45px !important;
                justify-content: start !important;
              }
              & .smallHiddden {
                overflow: unset !important;
                height: auto !important;
                width: auto !important;
              }
            }
          }
          & .scrollPart {
            width: 100% !important; 
          }
        }
      }
    }
  }
}


@media only screen and (min-width:320px) and (max-width:992px){

          .sidebar {
               width: 80% !important;
               @include max-sm{
                width: 100% !important;
               }
            & .close_sideBar{
                  display: flex;
                  align-items: center;
                  justify-content: end;
                  height: 30px;
                  width: 30px;
                  background: #202733;
                  color: #fff;
                  box-shadow: 0 0 1px #818181;
                  border-radius: 4px;
                  margin: 0 0 12px auto;
                }
            }
        }
