.profile-cta-btn{
  border: 0;
  border-top: 2px solid  #3DACFF;
  box-shadow: 0px 0px 9px 0px #00000066;
  background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
  font-weight: 600;
  font-size: 12px;
  padding: 8px 22px;
  border-radius: 25px;
  color: #fff;
  @include md{
    font-size: 14px;
    padding: 12px 30px;
  }
}

.profile_section{
  & .sub-heading{
    font-weight: 600;
    font-size: 13px;
  }
  & .sub-desc{
    font-weight: 500;
    font-size: 13px;
    color: #D4D4D4;
  }
 
    & .profile-main-heading{
      & h6{
        font-weight: 600;
        font-size: 15px;
      }
      border-bottom: 1px solid  #6A798C;
    }
    & .profile-tabs{
      background-color: $theme10;
      padding: 15px;
      border-radius: 15px;
      & .nav-pills{
        & .nav-link{
          border: 0;
          color: #D4D4D4 !important ;
          background-color: $theme1 !important;
          margin-block: 5px;
          border-radius: 30px !important;
          padding: 5px;
          & .DropTxt{
            font-size: 13px;
          }
          &.active{
            background: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%) !important;
            border: 0 ;
            color: white !important;
            & .dropIcon{
                background: #fff;
            }
          }
          &:hover{
            border:0;
            background: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%) !important;
            color: white !important;
            & .dropIcon{
                background: #fff;
            }
          }
          & .dropIcon{
              height: 25px;
              width: 25px;
              display: flex;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #202733;
              box-shadow: 0 0 2px #969696;
              & img{
                  height: 11px;
                  width: 100%;
              }
          }
        }
      }
    }
  
  & .profile_account_info{
    
    & .profile-img{
      border: 3px solid #F8BA28;
      height: 60px;
      width: 60px;
      border-radius: 50%;
    }
    .commonInp:-webkit-autofill {
      -webkit-text-fill-color: #b2b6c5 !important;
      background-color: #2e3443 !important; /* Use this instead of box-shadow */
      caret-color: #b2b6c5;
      -webkit-transition: background-color 5000s ease-in-out 0s;
      transition: background-color 5000s ease-in-out 0s;
    }
  

    & .contact-desc{
      & h6{
        font-weight: 500;
      font-size: 13px;
      color: #D4D4D4;
      }
    }
    & .connection-box{
      border-bottom: 1px solid  #6A798C;
      & .connection-img{
        background-color: #191D27;
        height: 60px;
        width: 60px;
        border-radius: 5px;
      }
    }
    & .dropdown{
      & .dropdown-toggle{
        background-color: #191D27;
        border: 0;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        border-radius: 25px;
        padding: 6px 12px;
        &::after{
          content:none !important;
        }
      }
      & .dropdown-menu{
        background-color: #191D27;
        & .dropdown-item{
          color: #fff;
          padding: 6px 16px !important;
          &:hover{
            background-color: #202733 !important;
          }
        }
        
      }
    }
    & .account-list{
      font-weight: 600;
      font-size: 14px;
      color: #D4D4D4  ;
    }
    & .form-switch{
      & .switch {
        position: relative;
        display: inline-block;
        width: 47px;
        height: 20px;
        & input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        & .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #181c24; 
          transition: 0.4s;
          border-radius: 20px;
          &::before {
            position: absolute;
            content: "";
            height: 16px;
            width: 27px;
            left: 2px;
            bottom: 2px;
            background-color: #6c7684;
            transition: 0.4s;
            border-radius: 25px;
          }
          
        }

      }
    }
  }
  & .security-box{
    background-color:#191D27;
    border-radius: 10px;
     & .security-btn{
      border:0;
      background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
      border-top: 2px solid  #3DACFF;
      color: #fff;
      width: 100%;
      font-weight: 600;
      font-size: 14px;
      border-radius: 25px;
      padding-block: 7px;
    }
  }
}
input:checked + .slider:before {
  transform: translateX(15px);
  background-color: #c6ff00 !important;
}

//   mobile_tab_btn


 .drop_mb_tab{
    color: #B2B6C5 !important;
    background-color: #1E222D;
    margin-block: 10px;
    padding: 6px 6px;
    border: 1px solid #2E3443;
    border-radius: 25px;
    & .DropTxt{
      font-size: 13px;
    }
    & .dropIcon {
        height: 28px;
        width: 28px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        background: #202733;
        box-shadow: 0 0 2px #969696;
  
        & img{
            height: 14px;
            width: 100%;
            object-fit: contain;
        }
    }
  
    &.active {
        background-image: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%) !important;
        color: white !important;
    
    & .dropIcon {
        background: #fff;
    }
  }
  }

#EditUsername{
  & .modal-content{
    overflow: hidden;
    box-shadow: 0 0px 15px 5px #3f3f3f52;
    & .modal-header{
      border-bottom: 0 !important;
      background-color: #202733;
      & .btn-close{
        filter: invert(1);
      }
    }
    & .modal-body{
      background-color: #191a24;
      & .form-control{
        background-color: #202733;
        border-radius: 10px;
        border: 0 !important;
        color: #fff;
        padding: 15px 10px;
        &:focus{
          box-shadow: none !important;
          border: 0 !important;
        }
        &::placeholder{
          color: #B2B6C5;
        }
      }
    }
  }
}
input:-webkit-autofill {
  -webkit-text-fill-color: #b2b6c5 !important;
  background-color: transparent  !important;
  caret-color: #b2b6c5;
  border: none;
  -webkit-box-shadow: 0 !important;
  transition: background-color 5000s ease-in-out 0s;
}