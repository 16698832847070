.bitCoindropBtn{
    &.bitCoinDrop{
        & .bitCoinDropDown{
            display: block;
        }
    }
}

.nativedropBtn{
    &.nativeDrop{
        & .nativeDropDown{
        display: block;
    } 
    }
}
.nativeDropDown{
    display: none;
    background-color: $theme10;
    position: absolute;
    z-index: 99;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    & .dropList{
        height: 40px;
        border: 1px solid $theme10;
        border-radius: 5px;
        padding-inline: 10px;
        transition: 0.3s linear;
        &.active{
            border: 1px solid #93A6C4;
            border-radius: 5px;
            height: 40px;
            padding-inline: 10px;
            & .btcDigit{
                & span{
                    color: white;
                }
            }
        }
        &:hover{
            border: 1px solid #93A6C4;
            border-radius: 5px;
            height: 40px;
            padding-inline: 10px;
            & .btcDigit{
                & span{
                    color: white;
                }
            }
        }
    }
    & .btcText{
        font-size: 15px;
        font-weight: 600;
        color: $theme5;
        & img{
            width: 16px;
            height: 16px;
            object-fit: contain;
            margin-right: 8px;
        }
    }
    & .btcDigit{
        font-size: 13px;
        font-weight: 600;
        color: white;
        & span{
            font-size: 13px;
            font-weight: 600;
            color: $theme5;
            transition: 0.3s linear;
        }
    }
}

.bitCoinDropDown{
    display: none;
    background-color: $theme10;
    position: absolute;
    z-index: 99;
    border-radius: 5px;
    & .searchInpDrop{
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        padding-inline: 12px;
        padding-top: 12px;
        & .searchImgg{
            position: absolute;
            width: 35px;
            height: 35px;
            object-fit: contain;
            color: #93A6C4;
            top: 60%;
            left: 14px;
            transform: translateY(-50%);
            padding-left: 18px;
        }
        & .inp{
            background-color: $theme8;
            border: 1px solid #93A6C4;
            border-radius: 5px;
            height: 50px;
            color: white;
            padding-left: 32px;
            width: 100%;
            &::placeholder{
                padding-left: 18px;
                font-size: 15px;
                font-weight: 600;
                color: #93A6C4;
            }
        }
    }
    & .dropList{
        height: 40px;
        border: 1px solid $theme10;
        border-radius: 5px;
        padding-inline: 10px;
        transition: 0.3s linear;
        margin-inline: 12px;
        text-transform: uppercase;
        &:hover{
            border: 1px solid #93A6C4;
            border-radius: 5px;
            height: 40px;
            padding-inline: 10px;
        }
    }
    & .btcText{
        font-size: 15px;
        font-weight: 600;
        color: $theme5;
        & img{
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin-right: 8px;
            border-radius: 50px;
            overflow: hidden;
        }
    }
    & .btcDigit{
        font-size: 15px;
        font-weight: 600;
        color: white;
        & span{
            font-size: 15px;
            font-weight: 600;
            color: $theme5;
        }
    }
    & .dropFooter{
        background-color: $theme8;
        height: 56px;
        padding: 20px;
        border-radius: 0px 0px 5px 5px;
        & .footerTxt{
            font-size: 15px;
            font-weight: 600;
            color: white;
            line-height: 19.5px;
        }
    }
    & .bitListHeight{
        height: 325px;
    }
    & .footerToggle{
        & .form-switch{
            & .form-check-input:focus{
                & .--bs-form-switch-bg{
                    background-image: url(./../images/toggle.png);
                }
            }
        }
        & .form-check-input{
            background-color: #242937;
            height: 18px;
            width: 38px;
            border-radius: 3px;
            border: 0;
            &:focus{
                box-shadow: unset;
            }
        }
    }
}

.euroDropdown{
    & .euroDropbtn{
        background-color: unset;
        border: 0;
        padding: 0;
        &::after{
            display: none;
        }
        &.show{
            background-color: unset;
        }
    }
    & .dropDownMenuList{
        position: absolute;
        // transform: translate(-7px, 48px) !important;
        // transform: translate(-50px, 48px) !important;
        inset: unset;
        transform: translate(unset);
        right: 0 !important;
        left: unset !important;
        top:100%;
        background-color: $theme10;
        padding: 12px;
        border-radius: 10px;
        width: 280px;
        & .searchInp{
            background-color: $theme8;
            height: 34px;
            border-radius: 5px;
            border: 0;
            padding-left: 30px;
            width: 100%;
            color: white;
            font-size: 12px;
            &:focus{
                outline: unset;
            }
            &::placeholder{
                font-size: 12px;
                font-weight: 500;
                color: $theme5;
                padding-left: 8px;
            }
        }
        & .serchh{
            width: 14px;
            height: 12px;
            position: absolute;
            top: 30%;
            transform: translateY(-50%);
            left: 14px;
            & img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }   
        & .dropDownMenuUl{
            background-color: #161a25;
            // background-image: linear-gradient(to bottom, $theme8 , #1B1F29);
            padding: 14px !important;
            border-radius: 4.85px;
            margin-top: 12px !important;
            display: flex;
            gap: 4px;
            flex-direction: column;
            padding-bottom: 30px !important;
            & .dropDownMenuLi{
                background-color: $theme10;
                padding: 7px 6px;
                border: 1px solid $theme10;
                border-radius:4.85px;
                transition: 0.3s linear;
                list-style: none;
                // height: 35px;
                // display: flex;
                // align-items: center;
                &:hover{
                    border: 1px solid $theme5;
                }
                & .country{
                    width: 25px;
                    height: 25px;
                    border-radius: 5px;
                    background-color: #15191F;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & img{
                        width: 15px;
                        height: 15px;
                        object-fit: contain;
                    }
                }
                & .countryTxt{
                    font-size: 12px;
                    font-weight: 600;
                    color: white;
                    margin-left: 8px;
                }
                & .midTxt{
                    font-size: 10px;
                    font-weight: 500;
                    color: $theme5;
                }
                & .countryTxt{

                }
            }
        }
    }
    & .linkstxt{
        font-size: 12px;
        font-weight: 500;
        color: $theme5;
        border-right: 1px solid #B2B6C5;
        padding-right: 6px;
    }
    & .links{
        & img{
            width: 100%;
            height: 8.53px;
            object-fit: contain;
        }
    }
}