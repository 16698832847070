// footer
.footer{
    background-color: $theme1;
    padding-block: 20px;
    padding-inline: 10px;
    @include sm{

    }
    @include md{
        
    }
    @include lg{
        
    }
    @include xl{
    }
    @include xxl{
    }
    & .footerHeader{
        font-size: 12px;
        color: white;
        font-weight: 600;
        @include sm{

        }
        @include md{
            
        }
        @include lg{
            font-size: 20px;
            
        }
    }
    & .footerAnchor{
        color: $theme5;
        font-size: 12px;
        text-decoration: none;
        @include sm{

        }
        @include md{
            
        }
        @include lg{
            font-size: 14px;
            
        }
    }
    & .borderLight{
        // border-bottom: 0.1px solid rgba($theme5, 0.5);
    }
    & .footerTxtttt {
        font-size: 10px;
        color: $theme5;
        margin-top: 22px;
        @include sm{
            // font-size: 14px;
        }
        @include md{
            // font-size: 18px;
        }
        @include lg{
            font-size: 14px;
            
        }
    }
    & .copyRightTxt {
        margin-top: 25px;
        text-align: center;
        font-size: 10px;
        color: $theme5;
    }
    & .logoImg {
        height: 32px;
        object-fit: contain;
        @include sm{

        }
        @include md{
            
        }
        @include lg{
            height: 35px;
            
        }
    }
    & .guracoImg {
        
        height: 32px;
        object-fit: contain;
        @include sm{

        }
        @include md{
            
        }
        @include lg{
            height: 35px;
            
        }
    }
    & .brandImage{
        height: 37px;
        object-fit: contain;
    }
    & .dividerFooter{
        width: 100%;
        height: 1px;
        background-color: #B2B6C533;
        margin: 20px 0;
    }
    & .socialBox{
        border-radius: 7.53px;
        border: 1px solid white;
        padding: 6px 10px;
        & .apple{
            width: 27px;
            height: 27px;
            & img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        & .socialTxtGray{
            font-size: 9.04px;
            font-weight: 500;
            color: $theme5;
        }
        & .socialTxtwhite{
            font-size: 12.81px;
            font-weight: 600;
            color: white;
        }
        & .question{
            width: 12.81px;
            height: 28px;
            & img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    & .brandImg{
        height: 16.07px;
        @include sm{
            height: 19.07px;
        }
        @include md{
            height: 19.07px;
        }
        @include lg{
            
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: flex;
        }
    }
    & .socialImg{
        width: 34.65px;
        height: 34.65px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .partnershipt{
        font-size: 15px;
        font-weight: 500;
        color: $theme5;
        text-align: center;
    }
    & .sigmaImg{
        // height: 51.03px;
        height: 38px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: flex;
        }
    }
    & .networkImg{
        width: 24.03px;
        height: 24.03px;
        @include sm{
            width: 38px;
            height: 38px;
        }
        @include lg{
            width: 30px;
            height: 30px;
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}


// footer for desktop

.containerfluidFooterDesktop{
    @include lg {
        padding-top: 35px;
        border-top: 0.5px solid rgba(#B2B6C5, 0.2);
    }
    & .footerHead{
        font-weight: 600;
        color: white;
        margin-bottom: 20px;
        @include sm{

        }
        @include md{
            
        }
        @include lg{
            
        }
        @include xl{
            font-size: 16px;
            
        }
        @include xxl{
            // font-size: 20px;
            
        }
    }
    & .footerlinks{
        font-weight: 600;
        color: $theme5;
        text-decoration: none;
        margin-bottom: 9px;
        display: block;
        &:hover {
            color: white;
        }
        @include sm{

        }
        @include md{
            
        }
        @include lg{
            
        }
        @include xl{
            font-size: 13px;
            
        }
        // @include xxl{
        //     font-size: 16px;
        // }
    }
    & .logo{
        height: calc(3.5vw);
        // height: calc(2.5vw);
        max-width: 225px;
        margin-bottom: 20px;
        cursor: pointer;
        @include sm{

        }
        @include md{
            
        }
        @include xl{
        }
        @include xxl{
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .game{
        // height: 43px;
        margin-bottom: 20px;
        height: calc(2.5vw);
        max-width: 110px;
        @include sm{

        }
        @include md{
            
        }
        @include xl{
        }
        @include xxl{
            // height: 38px;
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .footerPara{
        font-weight: 500;
        color: $theme5;
        @include sm{

        }
        @include md{
            
        }
        @include xl{
            line-height: 19.6px;
            font-size: 13px;
        }
        // @include xxl{
        //     font-size: 16px;
        //     line-height: 20.8px;
        // }
    }
    & .socialBox{
        border-radius: 7.53px;
        border: 0.5px solid white;
        padding: 6px 10px;
        cursor: pointer;
        &:hover {
            background-color: $theme8;
        }
        @include sm{

        }
        @include md{
            
        }
        @include lg{
            
        }
        @include xl{
            
        }
        & .apple{
            width: 27px;
            height: 27px;
            @include sm{

            }
            @include md{
                
            }
            @include lg{
                
            }
            @include xl{
                
            }
            & img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        & .socialTxtGray{
            font-size: 9.04px;
            font-weight: 500;
            color: $theme5;
            @include sm{

            }
            @include md{
                
            }
            @include lg{
                
            }
            @include xl{
                
            }
        }
        & .socialTxtwhite{
            font-size: 12.81px;
            font-weight: 600;
            color: white;
            @include sm{

            }
            @include md{
                
            }
            @include lg{
                
            }
            @include xl{
                
            }
        }
        & .question{
            width: 12.81px;
            height: 28px;
            @include sm{

            }
            @include md{
                
            }
            @include lg{
                
            }
            @include xl{
                
            }
            & img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    & .dividerFooter{
        width: 100%;
        height: 1px;
        background-color: rgba(#B2B6C5, 0.2);
        margin: 20px 0;
    }
    & .socialImg{
        margin-right: 8px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        &:hover {
            transform: translateY(-5px);
        }
        @include xl{
            width: 28.03px;
            height: 28.03px;
        }
        @include xxl{
            // width: 34.65px;
            // height: 34.65px;
            
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .brandImg{
        @include sm{

        }
        @include xl{
            height: 20.07px;
            margin-left: 20px;
        }
        @include xxl{
            // height: 31.07px;
            // margin-left: 30px;
            
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .partnershipt{
        font-size: 15px;
        font-weight: 500;
        color: $theme5;
        text-align: center;
    }
    & .networkImg{
        @include sm{

        }
        @include xl{
            width: 28.03px;
            height: 28.03px;
        }
        @include xxl{
            // width: 43.17px;
            // height: 43.17px;
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .sigmaImg{
        // height: 51.03px;
        @include sm{

        }
        @include xl{
            height: 39.67px;
        }
        @include xxl{
            // height: 52.67px;
            
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .copyRightTxt {
        margin-top: 25px;
        text-align: center;
        font-size: 10px;
        color: $theme5;
    }
}