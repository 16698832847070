.bonus-page{
    & .heading{
        font-weight: 800;
        font-size: 18px;
        line-height: 22.5px;
        letter-spacing: 0%;
        
        @include md{
            font-size: 22px;
        }
        @include lg{
            font-size: 25px;
        }
        & span{
            color: #EEB338;
        }
    }
    & .redeem-form{
        & .formControl{
            background-color: $theme10;
            color: $theme5;
            border: 0;
            padding-left: 20px;
            &::placeholder{
                color: $theme5;
                font-size: 14px;
            }
        }
        & .redeem-btn{
            background-color: $theme3;
            color: white;
            border-bottom: 5px solid #388927;
            font-size: 15px;
            border-radius: 5px !important;
            padding: 6px 10px;
            @include sm{
                padding: 9px 30px;
            }
        }
    }
    & .bonus-details{
        background-color: $theme10;
        border-radius: 5px;
        margin: 0;
        & .total-bonus-wrapper{
            border-right: 1px solid $theme5;
            & .dollar-img{
                height: 40px;
                width: 40px;
                @include sm{
                    height: 50px;
                    width: 50px;
                }
                & img{
                    height: 100%;
                    width: 100%;
                }
            }
        }
        & .description{
            width: fit-content;
            margin-inline: auto;
        }
        & .border-div{
            border-right: 1px solid $theme5;
            @include xl{
                border: none;
            }
        }
        & .head{
            font-size: 12px;
            font-weight: 500;
            line-height: 130%;
            color: $theme5;
            @include sm{
                font-size: 13px;
            }
        }
        & .sub-para{
            font-size: 18px;
            font-weight: 600;
            line-height: 130%;
            color: $theme3;
        }
        & .sub-head{
            font-weight: 600;
            line-height: 130%;
            padding-block: 5px;
        }
        & .details-btn{
            font-size: 14px;
            font-weight: 500;
            line-height: 130%;
            background-color: $theme1;
            color: $theme5;
            border: none;
            padding: 8px 35px;
            border-radius: 5px;
        }
    }
    & .general-bonus-container{
        & .deposit-bonus-card{
            background-color: $theme10;
            padding: 15px;
            height: 100%;
            border-radius: 5px;
            & .head{
                font-size: 18px;
                font-weight: 600;
                line-height: 130%;
            }
            & .details-wrapper{
                border-bottom: 1px solid $theme3;
                width: fit-content;
            }
            & .more-details{
                font-weight: 500;
                line-height: 100%;
                color: $theme3;
            }
            & .right-arrow{
                width: 10px;
                height: 8px;
                display: flex;
                & img{
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    & .depositBounsModal{
        & .depositeheading{
            font-size: 15px;
            font-weight: 500;
            color: white;
            display: flex;
            align-items: center;
            @include sm{
            font-size: 18px;
            }
            & .quetionImg{
             
                display: inline-flex;
                align-items: center;
                text-align: center;
                -webkit-transform: translateZ(0); /* webkit flicker fix */
                -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
                position: relative;
                margin-left: 10px;
                & img{
                    height: 17px;
                    width: 17px;
                }
                .tooltip {
                    left: 50%;
                    width: max-content;                            
                    max-width: 250px;
                    text-align: center;
                    background: $theme10;
                    border-radius: 5px;
                    bottom: 100% !important;
                    transform: translateX(-50%) translateY(-10px);
                    color: $theme5;
                    font-size: 8px;
                    display: block;
                    margin-bottom: 15px;
                    opacity: 0;
                    padding: 10px;
                    pointer-events: none;
                    position: absolute;
                    transition: all .25s ease-out;
                    box-shadow: 2px 2px 6px rgba(0, 0, 10, 0.50);

                    & .toltipContent{
                        color: $theme5;
                        font-size: 8px;
                        line-height: normal;
                        text-align: left;
                        @include sm{
                        font-size: 10px;

                        }


                    }
                    &:before {
                        bottom: -20px;
                        content: " ";
                        display: block;
                        height: 20px;
                        left: 0;
                        position: absolute;
                        width: 100%;
                    }
                    &:after {
                        border-left: solid transparent 10px;
                        border-right: solid transparent 10px;
                        border-top: solid $theme10 10px;
                        bottom: -10px;
                        content: " ";
                        height: 0;
                        left: 50%;
                        margin-left: -13px;
                        position: absolute;
                        width: 0;
                    }
                }
                &:hover {
                    .tooltip {
                        opacity: 1;
                        pointer-events: auto;
                      
                    }
                }
            }
        }
        & .moreDetails{
            border: none;
            background-color: transparent;
            font-size: 14px;
            color: $theme6;
            font-weight: 500;
            text-decoration: underline;
            display: flex;
            align-items: center;
            & .arrow{
                height: 10px;
                width: 8px;
                display: flex;
                margin-left: 10px;
                & img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        & .depositCard{
            background-color: transparent;
            border-radius: 5px;
            padding: 10px;
            transition: all 0.3s ease-in-out;
            &.active{
            background-color: $theme8;
            }
            &:hover{
                background-color: $theme8;
            }

            & .bonusHeading{
                font-size: 12px;
                font-weight: 500;
                color: $theme5;
                @include sm{
                font-size: 14px;
                }

            }
            & .bonusValue{
                font-size: 12px;
                font-weight: 500;
                color: white;
                @include sm{
                    font-size: 13px;
                }
            }
        }
        & .bonusheadingHunt{
            font-size: 14px;
            font-weight: 500;
            color: $theme5;
            @include sm{
            font-size: 14px;
            }
            & span{
                font-weight: 600;
                color: white;
            }
        }
        & .huntImg{
            width: 100px;
            height: 70.4px;
        }
        & .spinwheel{
            height: 80px;
            width: 80px;
        }
        & .bonusSmall{
            font-size: 12px;
            color: $theme5;
            text-align: end;
        }
        & .registernowBtn{
            height: 38px;
            background-color: $theme3;
            border: 0;
            padding: 7px 30px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;
            color: white;
            border-bottom: 3px solid $theme12;
            @include sm{
                font-size: 15px;
                height: 43px;
            }
            &.disabled {
                background-color: $theme8;
                border: 0 !important;
                width: 100%;
                text-transform: uppercase;
                max-width: 280px;
                @include sm {
                    font-size: 13px;
                }
            }
        }
        & .bounesHistory{
        background-color: $theme8;
        border-bottom: 3px solid $theme1 !important;
        height: 38px;
        border: 0;
        padding: 7px 13px;
        border-radius: 4px;
        text-wrap: nowrap;
        font-size: 12px;
        font-weight: 500;
        color: white;
        width: 100%;
        max-width: 280px;
        &.unlockDbl{
            color:$theme3;
            font-weight: 400;
        }
        @include sm{
            font-size: 13px;
            height: 43px;
            }
            & .icon{
                height: 18px;
                width: 18px;
                display: flex;
                align-items: center;
                & img{
                    width: 100%;
                    height: 100%;
                }
            }

        }
        & .commingSoon{
            background-color: $theme8;
            color: $theme5;
            font-size: 14px;
            padding: 8px 18px;
            border-radius: 5px;
            border: none;
        }
        & .vipCardTitle{
            font-size: 18px;
            color: white;
            font-weight: 600;
            line-height: normal;
        }
        & .subTitleVip{
            font-size: 12px;
            line-height: normal;
            color: $theme5;
            font-weight: 400;
        }
        & .upimg{
            width: 50px;
            height: 20.78px;
            display: flex;
            align-items: center;
            & img{
                height: 100%;
                width: 100%;
            }
        }
        & .arowplanImg{
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            & img{
                height: 100%;
                width: 100%;
            }
        }
        & .spicalBounsCard{
            padding: 70px 40px;
            & .imgSpicalBouns{
                height: 120px;
                width: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include sm{
                    height: 150px;
                    width: 150px;
                }
                & img{
                    height: 100%;
                    width: 100%;
                }
            }
            & .spicalbounseHeading{
                font-size: 14px;
                color: $theme5;
                font-weight: 400;
                text-align: center;
                margin-top: 3px;
                @include sm{
                font-size: 16px;

                }
            }

        }
    }
}
.bonuscard{
    background: radial-gradient(79.74% 574.24% at 79.74% 34.4%, rgba(14, 149, 250, 0.5) 0%, #2F364C 100%) ;
    padding-inline: 15px;
    padding-block: 10px;
    border-radius: 10px;
    @include sm{
        padding-inline: 12px;
         padding-block: 15px;
        }

 .bg-layer{
      position: relative;
      padding-inline: 6px;
    border-radius: 10px;
    padding-block: 10px;
      overflow: hidden;
      z-index: 20;
      @include sm{
        padding-inline: 10px;
         padding-block: 15px;
        }
      .layer{
        position: absolute;
        background-color: #2F364C;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // z-index: -10;
        opacity: 50%;

      }
       
    & .tittle{
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0%;
        margin-bottom: 3px;
        @include sm{
        margin-bottom: 17px;
        line-height: 17.5px;
        font-size: 14px;

        }
    
     }
     
     
 }
}
.text-base{
    font-weight: 700;
    font-size: 12px;
    line-height: 22.5px;
    letter-spacing: 0%;
    @include sm{
        font-size: 18px;

    }
     }
.textxs{
     font-weight: 500;
     font-size: 8px;
     line-height: 10px;
     letter-spacing: 0%;

    @include sm{
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0%;

        
    }

    }
    .text-sm{
        font-size: 9px;
        line-height: 11.25px;
        
        @include sm{
        font-weight: 600;
        font-size: 14px;
        line-height: 17.5px;
        letter-spacing: 0%;
        margin-top: 7px;
        }

    }
    .detailbtn{
        width: max-content;
        border: 1px solid white;
        border-radius: 5px;
        background-color: transparent;
        font-weight: 600;
        font-size: 9px;
        line-height: 11.25px;
        letter-spacing: 0%;
        text-align: center;

        color: white;
        display: flex;
        gap: 7px;
        align-items: center;
        padding-block: 7px;
        padding-inline: 20px;
        margin-top: 11px;
        @include sm{
            font-weight: 600;
            font-size: 14px;
            line-height: 17.5px;
            letter-spacing: 0%;
            padding-block: 11px;
            margin-top: 17px;
            padding-inline: 27px;
            }
    }
    .right{
        width: 5px;
        height: 8px;
        display: inline-flex;

    }
    .bonus-img{
        width: 223px;
        height: 100%;
        right: -10px;
        top: 0;
        @include md{
            height: 100%;
            width: 328px;
        }
    }
    .text-2{
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0%;
        color: #D4D4D4;
         
    }
    .text-3{
        font-weight: 700;
        font-size: 14px;
        line-height: 17.5px;
        letter-spacing: 0%;
        color: white;

    }
    .iconinfo{
        width: 17px;
        height: 17px;
        cursor: pointer;
    }
    .process{
        display: flex;
        margin-top: 17px;
        justify-content: space-between;
    }
    .treasure{
        width: 44px;
        height: 44px;
    }
    .surprize{
        width: 34px;
        height: 34px;
    }
    .proceesbar{
        width: 95%;
        margin-inline: auto;
        height: 8px;
        background-color: #191D27;
        margin-top: 17px;
        position: relative;
        
    }
    .proceesbar:before{
        content:"";
        position: absolute;
        width: var(--width) !important;
        height: 100% !important;
        background-color: #EEB338 !important;
        top: 0;
        left: 0;
        z-index: 100;
        transition-duration: .3s;
    }
    .one{
        width: 14px;
        height: 14px;
        transform: rotate(45deg) translateY(-50%);
        background-color: #191D27 !important;
        margin-top: 2px;

    }
    .treasure-2{
        transform: rotate(-45deg) translateY(-50%) !important;
        background-color: #EEB338 !important;
        width: 14px;
        margin-top: 2px;
        height: 14px;

    }
    .one.active ~ .one {
        background-color: #EEB338 !important;
      }
    //   .active{
    //     background-color: #EEB338 !important;

    //   }
      .bonusnum{
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0%;
            margin-top: 10px;
      }
     .bonusEnd{
        margin-top: 12px;
     }
     .depositBtn{
        background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
        border-top:2px solid #3DACFF ;
        border: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 17.5px;
        letter-spacing: 0%;
        text-align: center;
        padding-block: 11px;
        padding-inline: 15px;
        box-shadow: 0px 0px 9px 0px #00000066;
        color: #FFFFFF;
        border-radius: 20px;

      
     }
     .claimCard{
        background: linear-gradient(180deg, #E5AB3D -39.04%, #202733 100%);
        padding-block: 21px;
        padding-inline: 28px;
        border-radius: 10px;
    & .voult{
        width: 343px;
        height: auto;
    }
    & .claimDetail{
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .atext{  
       font-weight: 800;
       font-size: 18px;
       line-height: 22.5px;
       letter-spacing: 0%;
       display: flex;
       align-items: start;
       gap: 5px;
       span{
        width: 18px;
        height: 18px;
        display: flex;
       }
        }
        & .btext{
            font-weight: 600;
            font-size: 15px;
            line-height: 18.75px;
            letter-spacing: 0%;

        }
    }
    .claimbar{
        margin-top: 8px;
        padding: 5px;
        background-color: #191D27;
        border-radius: 40px;
        padding-inline-start: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dulltext{
            font-weight: 600;
            font-size: 15px;
            line-height: 18.75px;
            letter-spacing: 0%;
            color: #B3B3BE;

        }
        .claimbtn{
          background-color: #2F364C;
          border-radius: 38px;

          font-weight: 600;
          font-size: 15px;
          line-height: 18.75px;
          letter-spacing: 0%;
          text-align: center;
          width: 156px;
          padding-block: 11px;
            color: #B3B3BE;
            border: none;
            border: none;
            cursor: pointer;

        }
    }
    & .claimtext{
        font-weight: 500;
        font-size: 15px;
        line-height: 18.75px;
        letter-spacing: 0%;
        text-align: center;
        color: #B3B3BE;
        margin-top: 17px;

    }

    }
    .claimshedule{
        background-color: #202733;
        padding-top: 24px;
        padding-bottom: 10px;
        padding-inline: 18px;
        border-radius: 10px;
        & .dropbtn{
           width: 25px;
           height: 25px;
        }
        & .rotate-90{
            transform: rotate(180deg);
            margin-inline-start: 3px;
        }
        & .bonusplate{
            border-radius: 5px;
            background-color: #2F364C;
            transition: .3s all;
            border: 1px solid transparent;
            height: 55px;
            padding-inline: 16px;
            &:hover{
                border: 1px solid var(--Icons-Color, #2F93DD)
                
            }
            & .number{
                background-color: #191D27;
                font-weight: 600;
                font-size: 15px;
                line-height: 18.75px;
                letter-spacing: 0%;
                text-align: center;
                width: 40px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;

            }
            & .save{
                padding-inline-start: 12px;
                & .day{
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18.75px;
                    letter-spacing: 0%;
                }
                & .month{
                    color: #B3B3BE;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16.25px;
                    letter-spacing: 0%;
                }
            }
            .nobonus{
                font-weight: 500;
                font-size: 13px;
                line-height: 16.25px;
                color: #B3B3BE;
                letter-spacing: 0%;

            }

        }

    }
    .bonusDay{
        display: flex !important;
        flex-direction: column;
        row-gap: 5px;
        margin-top: 9px;
        
    }

    .deposit_card{
        background: radial-gradient(187.39% 50% at 50% 50%, rgba(61, 109, 229, 0.4) 0%, #202733 100%);
        border-radius: 10px;
        padding-block: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-inline: 12px;
        height: 100%;

        & .dImage{
            height: 102px;
            width: 109px;
            transform: rotate(-151.87 deg);
            img{
                object-fit: contain;
            }
        }
        & .dep_text{
            font-weight: 800;
            font-size: 18px;
            line-height: 22.5px;
            letter-spacing: 0%;
            margin-top: 10px;
            
        }
        & .bonus_detail{
            background-color: #191D27;
            padding-block: 15px;
            padding-inline: 10px;
            border-radius: 5px;
            width: 100%;
            margin-top: 14px;
            font-weight: 600;
            font-size: 13px;
            line-height: 16.25px;
            letter-spacing: 0%;
            & .deposit_btn{
                border: none;
                border-radius: 64px;
                background-color: #2F364C;
                font-weight: 600;
                font-size: 13px;
                line-height: 16.25px;
                letter-spacing: 0%;
                text-align: center;
                color: white;
                width: 100%;
                padding-block: 7px; 
                margin-top: 10px;
            }}
            
            


        }
    .fadeText{
        color: #B3B3BE;
    }
    .stopwatch{
        margin-top: 6px;
    }

    .vipcard{
        background: radial-gradient(187.39% 50% at 50% 50%, rgba(61, 109, 229, 0.4) 0%, #202733 100%);
        border-radius: 10px;
        padding: 16px;
        & .text{
            
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0%;
            margin-inline-start: auto;

        }
          & .vipbonus{
          width: 93.73px;
          height: 147.71px;
        }
          & .vipDetail{
            padding-inline-start: 30px;
        }
        & .viptext{
            font-weight: 800;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: 0%;
            margin-bottom: 18px;

        }
        & .viptext-sm{
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0%;
            color: #B3B3BE;

        }
        & .infoImg{
             height: 15px;
             width: 15px;
        }
        & .progressBar{
            background-color: #191D27 !important;
            border-radius: 5px !important;
            margin-top: 12px !important;
            position: relative;
            overflow: visible !important;
            & .processbtn{
                border: 6.9px solid #47596B;
                border-radius: 50%;
                height: 23px !important;
                width: 23px !important;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10;
                background-color : #6E96C6;

                & .btninner{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateX(-50%);
                    border-radius: 50%;
                    height: 13px !important;
                    width: 13px !important;
                } 
            }
           
        }
        & .vipbottom{
            margin-top: 30px;
        }
        .vipbutton{
            margin-top: 8px;
            background-color: #191D27;
            color:  #B3B3BE;
            font-weight: 600;
            font-size: 13px;
            line-height: 16.25px;
            letter-spacing: 0%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-block: 13px;
            width: 100%;
            border-radius: 50px;
            gap: 10px;
             


        }

    }
    .lock{
        width: 15px;
        height: 20px;
    }
    .rocketimg{
        width: 67px;
        height: 116px;
        margin-inline: auto;
        img{
            object-fit: contain;
        }
    }
    .vipcardtext{
        font-weight: 800;
        font-size: 18px;
        line-height: 22.5px;
        letter-spacing: 0%;
        text-align: center;

    }
    .cardbottom{
        background-color: #191D27;
        color:  #B3B3BE;
        padding-block: 17px;
        border-radius: 5px;
        width: 100%;
        font-weight: 600;
        font-size: 13px;
        line-height: 16.25px;
        letter-spacing: 0%;
        height: 117px;
        display: flex;
        flex-direction: column;
        justify-content:space-between ;
        align-items: center;
        margin-top: 14px;

    }
    