// sidebar css

li {
  list-style: none;
}

.main_heading {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
}
.bg_main_color {
  background: #202733;
  border-radius: 11px;
}
.rounded_bg {
  background: #191d27;
}

.sidebarLink {
  & .sidebarImg {
    height: 25px;
    width: 25px;
    background-color: $themeColor9;
    border: 1px solid $themeColor5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.small {
      & img {
        height: 13px !important;
        width: 13px !important;
      }
    }
  }

  // &:active .sidebarImg{
  //   background-color: #fff;
  //   border: 1px solid #C6D3FF;
  // }
  &:hover .sidebarImg {
    border: 1px solid $themeColor12;
  }
}

.sidebarLink {
  padding: 6px 7px 6px 7px !important;
  border-radius: 25px !important;
  border: 1px solid $themeColor5;
  background-color: $themeColor9 !important;
  &:is(.active) {
    border-radius: 25px !important;
    background: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%);
  }
  &:is(:hover) {
    background-color: $themeColor5 !important;
    border: 1px solid $themeColor12 !important;
  }
  &.sidebarLinkbody {
    &:is(:hover, .active) {
      border-radius: 25px !important;
      background: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%);
    }
  }
}

.accordion {
  & .accordion-item {
    border-radius: 0 !important;
    background-color: transparent !important;
    border: 0 !important;
    & .accordion-header {
      & .accordion-button {
        &:not(.collapsed) {
          & .sidebarLink {
            border-radius: 25px !important;
            background: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%);
          }
        }
        &:not(.collapsed) {
          border-bottom: 0 !important;
        }
      }
    }
  }
}

.accorduinBodyBg {
  background-color: transparent !important;
}

.accrodianBtnTxt {
  font-size: 12px !important;
}

.sidebarLink.active .sidebarImg {
  background-color: #fff;
  border: 1px solid #c6d3ff;
}

.sidebar {
  background-color: #191d27 !important ;
  border: 1px solid $themeColor5;
  border-left: 0;
}

.sidebar-divider {
  border: 1px solid transparent;
  border-image: linear-gradient(270deg, #191d27 0%, #2f364c 50.4%, #191d27 100%);
  border-image-slice: 1;
}

// footer css

// footer for desktop

.containerfluidFooterDesktop,
.footer {
  & .footerHead {
    font-weight: 700;
    font-size: 15px;
  }
  & .footerlinks {
    font-size: 12px;
    font-weight: 500;
    color: #b2b6c5;
    text-decoration: none;
  }
  & .footerSocialIcons {
    background-color: #2f364c;
    height: 34px;
    width: 35px;
    border-radius: 5px;
  }
  & .footerLanguage {
    background-color: $themeColor5;
    border-radius: 25px;
    padding: 3px 3px 3px 15px;

    & h6 {
      font-size: 15px;
    }

    & .footerdropdown {
      height: 34px;
      width: 34px;
      border-radius: 50%;
      background-color: $themeColor9;
      border: 1px solid $themeColor5;
      & img {
        height: 13px;
      }
    }
  }
  & .itchimg {
    height: 30px;
  }

  .networkImg {
    height: 22.51px;
    width: 22.51px;
  }
  & .footer-heading {
    font-size: 13px;
  }
  & .footer-para {
    font-size: 12px;
    font-weight: 400;
    color: #b3b3be;
  }
  & .footer-GCB-Logo {
    width: 109px;
    & img {
      height: 100%;
      width: 100%;
    }
  }
  & .footer-desktop {
    width: 44px;
    height: 44px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .footer6 {
    background-color: #f7931b;
  }
  & .footer5 {
    background-color: #7690f5;
  }
  & .footer4 {
    background-color: #bfbbbb;
  }
  & .footer3 {
    background-color: #50af95;
  }
  & .footer2 {
    background-color: #d9d9d9;
  }
  & .footer1 {
    background-color: #0070ed;
  }
  & .footer0 {
    background-color: #191d27;
  }

  & .footer-middle-section {
    background-color: #191d27;
    padding-block: 10px 14px;
  }
  .footerLanguage {
    width: 280px;
  }
}
.footer-bottom-section {
  background-color: #202733;

  & .plus-more {
    font-size: 12px;
    font-weight: 400;
  }
}

.scrollPart {
  @include lg {
    // width: calc(100% - 225px) !important;
    &.withoutSidebar {
      width: 100%;
    }
  }
}

.footer {
  & .itchimg {
    height: 44px;
  }
  & .partnershipBox {
    background-color: #191d27;
    border-bottom: 1px solid #2f364c;
    border-radius: 10px;
    margin: 15px;
    padding-block: 0px 20px;
    & .partnershipt {
      background-color: #2f364c;
      border-radius: 10px 10px 0px 0px;
      padding: 10px 12px;
      margin: 0;
    }
  }
  & .logoLinkCol {
    & .logoLink {
      width: 225px;
    }
  }
}

.copyRightTxt {
  font-size: 13px !important;
}

// authModal css

.modal-content {
  border-radius: 20px !important;
  background-color: transparent !important;

  & .tabList {
    background-color: $body;
    padding: 5px;
    border-radius: 25px;
    & .nav-item {
      & .nav-link {
        border-radius: 20px;
        color: $themeColor10;
        font-size: 15px;
        &.active {
          background-color: $themeColor1 !important;
          color: #fff;
        }
      }
    }
  }
  & .loginWithIcons {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    background-color: #2f364c;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .allMethods {
    & h6 {
      color: #6a798c;
      font-size: 15px;
    }
  }
  & .divider {
    width: 100%;
    height: 1px;
    background-color: #6a798c;
  }
}

// profile page css

.profile-bg {
  background-image: url("../images/profile/profile-bg.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  border-radius: 11px;

  & .balance-box {
    & .sml_txt {
      font-size: 13px;
    }
    & .balance {
      background: linear-gradient(180deg, #5e0290 0%, #b40a47 100%);
      padding: 4px 6px;
      border-radius: 5px;

      & .balance-money {
        font-size: 11px;
      }
    }
  }
  & .user-name {
    & h6 {
      font-size: 18px;
    }
    & p {
      font-size: 12px;
    }
  }

  & .profle-img {
    position: relative;
    bottom: 10px;

    & .avtar_img {
      & .bg_avatar {
        min-height: 180px;
      }
      & .avatar_logo_img {
        // top: 104px;
        // left: 73px;
        top: 89px;
        left: 61px;
        z-index: 9;
        cursor: pointer;
        height: 73px;
        width: 73px;
        background: #191A24;
        border-radius: 500px;
        display: flex;
        align-items: end;
        justify-content: center;
        & img {
          height: 60px;
          // object-fit: contain;
        }
      }
      & .img_point {
        background: #f8ba28;
        height: 26px;
        width: 26px;
        bottom: 8px;
        right: 27px;
        color: #000;
      }
    }

    & .profile_counts {
      & ul {
        & li {
          & .list_count {
            background: #252836;
            padding: 3px 12px;
            border-radius: 6px;
          }
        }
      }
    }
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.profilemodal-content {
  background-color: #202733 !important ;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  width: 40% !important;
  // height: 400px;
 
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.circle {
  width: 230px;
  border-radius: 50%;
  overflow: hidden;
  margin: 20px auto;
  position: relative;
  background-color: #2E3443;
  height: 230px;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camra-icon{
  left: 66%;
  bottom: 18%;
  background: #191A24;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  cursor: pointer;
  height: 40px;
  width: 40px;
  box-shadow: 0 0 9px 3px #0000006e;
}




.rewards_sec {
  background-color: #252836;
  height: 150px;
  position: relative;
  margin-top: 150px;
  border-radius: 11px;

  & .reward_prize_box {
    & .reward_box {
      background: linear-gradient(to bottom, #1f2028, #1f2028);
      padding: 17px;
      border-radius: 8px;
      position: relative;
      top: -40px;
      box-shadow: 0px -2px 1px #12336b;
      & .multi_txt {
        & .bonus_txt {
          background: #e69d3f;
          font-size: 12px;
          padding: 2px 5px;
          border-radius: 4px;
        }
      }
    }
  }
  & .rewards_list {
    & .list_content {
      & .list_num {
        background: #252836;
        & .list_num_count {
          background: linear-gradient(to bottom, #a5cae5, #5757cc);
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}

.latest_sec {
  margin-top: 140px;
  & .latest_txt {
    color: #556573;
  }

  & .latest_box {
    background: #252836;
    padding: 20px;
    border-radius: 11px;
    & .latest_list {
      & .user_list {
        background: #1f2028;
        border-radius: 8px;

        & .user_img {
          & img {
            height: 40px;
            width: 40px;
          }
        }
        & .user_name {
          & .nick_name {
            color: #0e95fa;
            font-size: 13px;
          }
        }

        & .user_counts {
          width: 35px;
          height: 35px;
          background: #15161b;
          color: #fff;
        }
      }
    }
    & .stats-box{
      background: linear-gradient(180deg, #0E95FA 0%, #0A1925 64.46%);
      border-radius:20px 20px 10px 10px;
      overflow: hidden;
      & .stats-box-upper{
        border-bottom: 1px solid #C2FB01;
        padding: 10px;
        & .stats-img-box{
          background-color: #202733;
          border: 1px solid #2F364C;
          height: 30px;
          width: 27px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & .stats-heading{
          color: #D4D4D4;
          font-size: 14px;
        }
      }
      & .stats-box-lower{
        padding: 5px 10px;
        background-color: #2F364C !important;
        border-radius: 0px 0px 10px 10px;
        text-align: center;
        & .stats-amount{
          font-weight: 600;
          font-size: 14px;
          color: #fff;
          
        }
      }
      
    }
  

    & .latest_inner_box {
      background: #1f2028;
      border-radius: 11px;

      & .latest_plt_num {
        background: #e5ab3d;
        border-radius: 6px;
        font-weight: 500;
      }

      & .plat_img {
        & .mid_img {
          min-height: 230px;
        }

        & .earning_box {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        & .ellipse_img {
          top: -92px;
          left: auto;
        }
        & .line_box_vector {
          top: -123px;
          right: 40px;

          & .line_top {
            & span {
              font-size: 13px;
              top: -14px;
            }
          }
        }
        & .line_box_vector_bottom {
          bottom: -123px;
          right: 40px;
          & .line_bottom {
            & span {
              font-size: 13px;
              top: 22px;
            }
          }
        }
      }
    }
  }
}

.news_latter {
  background: #202733;
  border-radius: 11px;
  position: relative;
  overflow: hidden;
  z-index: 99;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 82%;
    background-image: url("../images/Rectangle-bg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  & .news_content {
    & .news_txt {
      & .crown_img {
        background: #20232b;
        box-shadow: 0 0 2px #bababa;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        height: 71px;
        width: 80px;
        flex-shrink: 0;
      }
      & .news_title {
        font-size: 20px;
      }
    }
    .user_abt {
      & span {
        font-size: 10px;
      }
      & .user_list {
        & li {
          margin-right: -8px;
          & span img {
            height: 22px;
          }
        }
      }
    }
  }

  & .news_input {
    & .input_bx {
      background: #23252f;
      height: 40px;
      overflow: hidden;
      border-radius: 5px;

      & input {
        border: none;
        box-shadow: none;
        color: #fff;
        font-family: 13px;
        &::placeholder {
          color: #fff;
          font-size: 13px;
        }
        &:focus {
          outline: none !important;
          box-shadow: none;
        }
      }

      & button {
        background-image: linear-gradient(45deg, #b80843, #521ea1);
        color: #fff;
        border-radius: 5px;
        width: 14%;
      }
    }
  }
}

.player_graph_sec {
  & .player_box {
    background: #252836;
    padding: 20px;
    border-radius: 11px;
    & .player_txt {
      color: #556573;
    }

    & .player_inner_bx {
      background: #1f2028;
      border-radius: 11px;
      overflow: hidden;
    }
  }
}

// wallet after login css

.deposit_txt{
  color: #c2fb01;
}
.dropDownMenu {
  & .dropIcon {
    & img {
      object-fit: contain;
    }
  }
}

.form-check-input:focus {
  box-shadow: none;
}
.form-check-input:checked {
  box-shadow: none;
}

.wallet_sec {
  & .wallet_box {
    & .wallet_balance_sec {
      & .wallet-search {
        & input {
          &:focus {
            outline: none !important;
            box-shadow: none !important;
          }
        }

        & .search_btn {
          left: 12px;
          top: 7px;
          opacity: 0.5;
        }
      }

      & .hide_balance {
        & .hide_txt {
          font-size: 14px;
          opacity: 0.5;
        }

        & .form-check-input {
          width: 38px;
          height: 23px;
        }
      }

      & .total_balance_bx {
       & .border_right{
          border-right: 1px solid #4c4c4c;

          &:last-child{
            border-right: none;
          }
        }
        & .balance_box {

          & .balance_icon {
            & img {
              height: 26px;
              width: 26px;
              object-fit: contain;
            }
          }

          & .balance_content {
            & .balance_head {
            }
            & .balance_txt {
              color: #c2fb01;
            }
          }
        }
      }

      & .flat_sec{
         
        & .flat_row{

          
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .rewards_sec {
    height: auto;
    margin-top: 0;
  }
  .latest_sec {
    margin-top: 0;
  }
  .news_latter {
    &::before {
      width: 100%;
    }

    & .news_content {
      & .news_title {
        font-size: 12px !important;
      }
    }

    & .bet_content {
      position: absolute;
      top: 20px;
      justify-content: start !important;
      text-align: start !important;
      left: 113px;

      & .bt_head {
        font-size: 14px !important;
      }
      & h2 {
        font-size: 15px;
      }
      & .bt_txt {
        font-size: 10px;
      }
    }
    & .news_img {
      top: -38px !important;
      right: 0;
      z-index: -1;

      & img {
        width: 229px;
      }
    }

    & .news_input {
      background: #202733;
      border-radius: 10px;
      padding: 32px 12px;
      position: relative;
      right: -20px;

      & .input_bx {
        border: 1px solid #b10949;
      }
    }
  }

  .profile-bg {
    height: 100%;

    & .balance-box {
   
      & .balance {
        padding: 4px;
        font-size: 12px;
  
        & .balance-money {
          font-size: 11px;
        }
      }
    }
    & .balance-box {
      & .sml_txt {
        font-size: 13px;
      }
      & .balance {
        background: linear-gradient(180deg, #5e0290 0%, #b40a47 100%);
        padding: 4px 6px;
        border-radius: 5px;
  
        & .balance-money {
          font-size: 11px;
        }
      }
    }

    & .profle-img {
  
      & .avtar_img {
        & .bg_avatar {
             min-height: 130px;
        }
        & .avatar_logo_img {
          // top: 85px;
          // left: 60px;
          top: 71px;
          left: 49px;
          height: 59px;
          width: 59px;
      
          & img {
            height: 47px;
            // object-fit: contain;
          }
        }
        & .img_point {
          height: 22px;
          width: 22px;
        }
      }
  
      & .profile_counts {
        & ul {
          & li {
            & .list_count {
              padding: 3px 7px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

// rewards Code css

#nav-rewards{
  & .bg_main_color{
    padding: 20px;
    & .avaliable-rewards {
      background-color: #191d27;
      border-radius: 10px;
      padding: 10px;
      height: 100px;
      & .rewards-text {
        font-size: 12px;
        color: #d4d4d4;
      }
      & .reward-money {
        font-size: 20px;
        font-weight: 700;
        color: #0e95fa;
      }
      & .total-recieved {
        font-size: 12px;
        font-weight: 400;
        color: #d4d4d4;
        & span {
          font-weight: 500;
        }
      }
      & #CommissionRewards,
      #ReferralRewards {
        & .modal-header {
          background-color: #202733;
          border-bottom: 0 !important;
          padding: 15px 20px;
          & .modal-title {
            font-size: 18px;
            font-weight: 700;
          }
          & .custom-close-btn {
            background-color: #2f364c;
            position: absolute;
            right: 20px;
            // padding: 5px;
            // border-radius: 5px;
            & .btn-close {
              filter: invert(1);
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
            }
          }
        }
        & .modal-body {
          background-color: #191d27;
          padding: 15px 20px;
          & h6 {
            font-size: 15px;
            font-weight: 300;
            color: #d4d4d4;
            & span {
              font-weight: 700;
              display: inline-block;
            }
          }
        }
      }

      & .swap-btn {
        background-color: #2f364c;
        border-radius: 25px;
        color: #6a798c;
        font-size: 14px;
        border: 0;
        padding: 12px 29px;
        box-shadow: 0px 0px 9px 0px #00000066;
      }
      & .withdraw-btn {
        background: linear-gradient(270deg, #7bc6ff 0%, #0e95fa 100%);
        padding: 12px 29px;
        border-radius: 25px;
        color: #fff;
        border: 0;
        box-shadow: 0px 0px 9px 0px #00000066;
        font-size: 14px;
      }
    }
    & .rewards-tabs {
      padding: 15px;
      border-bottom: 1px solid #1d4a6c;
      & .nav-link {
        border: none !important;
        color: #485b7c;
        border-radius: 30px;
        padding: 8px 23px;
        font-size: 12px;
        &.active {
          background: #333d4f !important;
          color: #fff !important;
        }
      }
    }
    & .rules-btn,
    .history-btn {
      background: #333d4f !important;
      color: #fff !important;
      border-radius: 30px;
      padding: 8px 23px;
      background: #333d4f !important;
      font-size: 12px;
    }
    & .tab-content {
      padding: 15px;
      & .no-rewards-text {
        font-size: 18px;
        color: #b3b3be;
        font-weight: 500;
      }
      & .registration-date {
        background-color: #2f364c;
        border-radius: 25px;
        padding: 5px 10px;
        & h6 {
          font-size: 13px;
          font-weight: 500;
          & span {
            font-weight: 700;
          }
        }
        & .cross-btn {
          background-color: #202733;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    & .refferal-table {
      white-space: nowrap;
      & thead {
        & th {
          background-color: #191d27 !important;
          font-size: 13px;
          color: #6a798c;
          font-weight: 500;
          border-bottom: 0 !important;
          text-align: center;
        }
        & th:first-child {
          background-color: #191d27 !important;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
        & th:last-child {
          background-color: #191d27 !important;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }
      & tbody {
        & td {
          background-color: transparent !important;
          border-bottom: 1px solid #1d4a6c !important;
          color: #d4d4d4;
          padding: 12px;
          font-size: 13px;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
}

#History , #HistoryFriend{
  & .modal-header{
    border-bottom: 0;
    & .modal-title {
      font-size: 18px;
      font-weight: 700;
    }
    & .custom-close-btn {
      background-color: #2f364c;
      position: absolute;
      right: 20px;
      // padding: 5px;
      // border-radius: 5px;
      & .btn-close {
        filter: invert(1);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }
  }
  & .modal-body {
    & .refferal-modal-table {
      white-space: nowrap;
      & th,
      td {
        background-color: transparent !important;
        border-bottom: 1px solid #1d4a6c !important;
        color: #d4d4d4;
        padding: 12px;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
      }
    }
    & .dropdown-toggle {
      background-color: #2f364c;
      border: 0;
      border-radius: 25px;
      color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 16px;
      font-size: 13px;
      font-weight: 500;
      &::after {
        content: "\f107"; /* Use a backslash before the Unicode */
        font-weight: 900;
        font-family: "FONTAWESOME"; /* Ensure this is correct */
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #202733;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 0;
        color: white; /* Ensure the icon is visible */
      }
    }
    & .dropdown-menu {
      background-color: #2f364c;
      & li {
        & a {
          color: #fff;
          &:hover {
            background-color: #202733;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .rules-btn,
  .history-btn {
    font-size: 8px !important;
    padding: 8px 16px !important;
  }
  .no-rewards-text {
    font-size: 13px !important;
  }
}

.info-icon {
  top: 0;
  right: 0;
}
.special-arrow {
  width: 28px;
  height: 20px;
  background-color: #202733;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  & i {
    font-size: 12px;
  }
}

// referral Code css
#nav-referralCode{
  & .create-code-section{
    background-color: #202733;
    padding: 20px;
    border-radius:10px;
    & .Referral-Code{
      background-color: #191D27;
      border-radius: 10px;
      padding: 15px;
      height: 100px;
      & .rewards-text{
        font-size: 12px;
        color: #D4D4D4;
        font-weight: 400;
      }
      & .total-recieved{
        font-size: 20px;
        font-weight: 700;
        & span{
          color: #6A798C;
        }
      }
      & .create-btn{
        background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
        border: 0;
        border-top: 2px solid #3DACFF;
        border-radius: 25px;
        padding: 10px 49px;
        color: white;
        font-size: 14px;
        font-weight: 600;
      }
      & #CreateCode{
        & .modal-content{
          box-shadow: 5px 5px 5px 0px #00000080;
          border-radius: 10px !important;
          overflow: hidden;
        & .modal-header{
          background-color: #202733;
          border-bottom: 0 !important;
          padding: 15px 20px;
          & .modal-title{
            font-size: 18px;
            font-weight: 700;
          }
          & .custom-close-btn{
            background-color: #2F364C;
            position: absolute;
            right: 20px;
            // padding: 5px;
            // border-radius: 5px;
            & .btn-close{
              filter: invert(1);
              display: flex ;
              align-items: center;
              justify-content: center;
              padding: 0;
            }
          }
        }
        & .modal-body{
          background-color:#191D27 ;
          padding:25px;
          & .modal-heading{
            font-size: 15px;
            font-weight: 600;
            color: #D4D4D4;
          }
          & .createCode-input{
            background-color: #2F364C;
            width: 100%;
            border-radius: 25px;
            border: 0;
            padding: 13px;
            font-size: 15px;
            color: #fff;
            &:focus{
              outline: 0 !important;
            }
            &::placeholder{
              font-size: 15px;
              font-weight: 500;
              color: #6A798C;
            }
          }
          & .create-campaign{
            background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
            border: 0;
            border-top: 2px solid  #3DACFF;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            border-radius: 25px;
            width: 100%;
            padding: 11px;
          }
        }
      }
      }
    }
  }
  & .referral-tabs{
    padding: 15px;
    border-bottom: 1px solid #1D4A6C;
    & .nav-link{
      border: none !important;
      color: #485b7c;
      border-radius: 30px;
      padding: 8px 23px;
      font-size: 12px;
      &.active{
        background: #333d4f !important;
        color: #fff !important;
      }
    }
    & .rules-btn , .history-btn{
      background: #333d4f !important;
      color: #fff !important;
      border-radius: 30px;
      padding: 8px 23px;
      background: #333d4f !important;
      font-size: 12px
    }
  }
  & .refferal-content{
    padding: 35px 25px;
    & .dropdown-toggle{
      background-color: #191D27;
      border: 0;
      border-radius: 25px;
      color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 5px 5px 20px;
      font-size: 13px;
      font-weight: 500;
      &::after{
        content: "\f107"; /* Use a backslash before the Unicode */
        font-weight: 900;
        font-family: "FONTAWESOME"; /* Ensure this is correct */
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #202733;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 0;
        color: white; /* Ensure the icon is visible */
      }
    }
    & .dropdown-menu{
      background-color: #2F364C;
      & li{
        & a{
          color: #fff;
          &:hover{
            background-color: #202733;
          }
        }
        
      }
    }
    & .username-search-wrapper{
      background-color: #191D27;
      border-radius: 25px;
      overflow: hidden;
      padding: 8px 16px;
      & i{
        font-size: 14px;
        color: #B2B6C5;
      }
      & input{
        background-color: transparent;
        border: 0 !important;
        font-size: 13px;
        color: #fff;
        &:focus{
          outline: none !important;
        }
        &::placeholder{
          color: #6A798C;
          font-size: 13px;
        }
      }
    }
    & .registration-date{
      background-color: #191D27;
      border-radius: 25px;
      padding: 5px 5px 5px 20px;
      & h6{
        font-size: 13px;
        font-weight: 500;
        & span {
          font-weight: 700;
        }
      }
      & .cross-btn{
        background-color: #202733;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    & .registration-friend-date{
      background-color: #2F364C;
      border-radius: 25px;
      padding: 5px 5px 5px 20px;
      & h6{
        font-size: 13px;
        font-weight: 500;
        & span {
          font-weight: 700;
        }
      }
      & .cross-btn{
        background-color: #202733;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    & .wager-date{
      background-color: #191D27;
      border-radius: 25px;
      padding: 12px;
      & h6{
        font-size: 13px;
        font-weight: 500;
        & span {
          font-weight: 700;
        }
      }
    }
    & .refferalCode-table{
      white-space: nowrap;
      & thead {
        & th{
          background-color: #191D27 !important;
          font-size: 13px;
          color: #6A798C;
          font-weight: 500;
          border-bottom: 0 !important;
          text-align: center;
        }
        & th:first-child {
          background-color: #191D27 !important;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
        & th:last-child {
          background-color: #191D27 !important;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }

      }
      & tbody{
        & tr{
          position: relative;
          border-bottom: 1px solid #1D4A6C !important;
          & td{
            background-color: transparent !important;
            
            color: #D4D4D4;
            padding: 15px;
            font-size: 13px;
            font-weight: 400;
            text-align: center;
            & .action-btn{
              background-color: #191D27;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0;
              & i{
                color: #fff;
              }
            }
            &.action-data{
              position: absolute;
              top: 10px;
              right: 25px;
              border-bottom: 0 !important;
              padding: 0 !important;
            }
          }
        }
      
      }
    }
    #actionModel , #actionFriendModel{
      & .modal-dialog{
        max-width: 720px;
        & .modal-header{
          border-bottom: 0;
          background-color: #202733;
          & .modal-title{
            font-size: 18px;
            font-weight: 700;
          }
          & .custom-close-btn{
            background-color: #2F364C;
            position: absolute;
            right: 20px;
            // padding: 5px;
            // border-radius: 5px;
            & .btn-close{
              filter: invert(1);
              display: flex ;
              align-items: center;
              justify-content: center;
              padding: 0;
            }
          }
        }
        & .modal-body{
          background-color: #191D27;
          padding: 25px;
          /* width */
          &::-webkit-scrollbar {
            width: 10px;
            display: block;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #202733; 
          }
          
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #2F364C; 
            border-radius: 25px;
          }

          & .action-details-row{
            border-bottom: 1px solid #1D4A6C ;
            padding: 0px 12px;
            height: 64px;
            &:last-child{
              border-bottom: 0 !important;
            }
            & h6{
              color: #6A798C;
              font-size: 15px;
              font-weight: 500;
            }
            & p{
              color: #FFFFFF;
            }
            & .copy-data{
              background-color: #2F364C;
              border-radius: 25px;
              padding: 10px 20px;
              &.detail-text{
                font-size: 13px;
                font-weight: 500;
              }
            }
          }
          
          & .wager-box , .Commission-box{
            background-color: #202733;
            border-radius: 10px;
            overflow: hidden;
            & .modal-heading{
              background-color: #2F364C;
              padding: 7px;
              & h6{
                font-size: 15px;
                font-weight: 600;
              }
            }
            & .modal-data{
              padding: 20px;
              & .modal-data-upper{
                border-bottom: 1px solid #1D4A6C;
                padding-bottom: 15px;
                & h6{
                  font-size: 15px;
                  font-weight: 500;
                  color: #6A798C;
                }
                & p{
                  font-size: 15px;
                  font-weight: 500;
                }
              }
              & .modal-data-lower{
                padding-top: 15px;
                & h6{
                  font-size: 15px;
                  font-weight: 500;
                  color: #6A798C;
                }
                & p{
                  font-size: 15px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
     
    }
  }
 
}


@media (max-width:900px) {
  #nav-referralCode{
   & .action-data{
      right: 0 !important;
    }
  
   & th , td{
      font-size: 8px !important;
    }
  
  }
  #nav-rate{
    .ViewRules{
      top: 8px !important;
    }
  }
  
}


// rate and rules css

#nav-rate{
  & .bg_main_color{
    padding: 20px;
    & .commisson-rate{
      border-bottom: 3px solid;
      border-image-source: linear-gradient(90deg, #1D4A6C 0%, rgba(57, 145, 210, 0) 100%);
      border-image-slice: 1;  
      & h6{
        font-weight: 700;
        font-size: 14px;
      }
    }
    & .original-game-box{
      & h6{
        font-weight: 500;
        font-size: 13px;
        color: #6A798C;
      }
      & .original-game-text{
        background-color: #191D27;
        border-radius: 25px;
        padding: 10px 12px;
        
        & p{
          font-weight: 500;
          font-size: 13px;
          color: #6A798C;
          & span{
            color: #fff;
          }
        }
      }
      & .dropdown{
        & .dropdown-toggle{
          background-color: #191D27;
          border: 0;
          border-radius: 25px;
          color: #fff;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 5px 5px 20px;
          font-size: 13px;
          font-weight: 500;
          &::after{
            content: "\f107"; /* Use a backslash before the Unicode */
            font-weight: 900;
            font-family: "FONTAWESOME"; /* Ensure this is correct */
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #202733;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 0;
            color: white; /* Ensure the icon is visible */
          }
        }
        & .dropdown-menu{
          background-color: #2F364C;
          & li{
            & a{
              color: #fff;
              &:hover{
                background-color: #202733;
              }
            }
            
          }
        }
      }
    }
    & .ViewRules{
      top: 15px;
      right: 20px;
      
      & .dropdown-toggle{
        background-color: #2F364C;
        border-radius: 25px;
        color: #fff;
        border: 0;
        font-weight: 500;
        font-size: 12px;
        padding: 10px 18px;
        &::after{
          content: "\f107"; 
          font-weight: 900;
          font-family: "FONTAWESOME"; 
          font-style: normal;
          border: 0;
          color: white; 
          margin-left: 6px !important;
          vertical-align: 0 !important;
        }
      }
    }
    & .refferal-steps{
      background-color: #191D27;
      padding: 21px 16px;
      border-radius: 10px;
      & .steps-headline{
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        & span{
          color: #0E95FA;
        }
      }
      & .steps-detail{
        font-weight: 400;
        font-size: 12px;
        color: #D4D4D4;
        line-height:15px;

      }
    }
    & .Referral-Reward{
      border-bottom: 1px solid #1D4A6C;
    }
    & .vip-table{
      background-color: #191D27;
      border-radius: 10px;
      & .vip-table-header{
        border-bottom: 1px solid #1D4A6C;
        padding:15px 25px;
        & .heading{
          font-weight: 700;
          font-size: 12px;
        }
      }
      & .vip-table-body{
        padding:25px;
        & .vip-table-row{
          & .table-data{
            font-weight: 700;
            font-size: 12px;
            &.table-data-green{
              color: #C2FB01;
            }
          }
        }
      }
    }
   
  }
}

.bg_lang{
 background: #191a24;
}