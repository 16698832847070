.skeleton {
  background-color: $theme10;
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    background-image:	linear-gradient(-55deg, rgba(#2E3443, 0) , rgba(#2E3443, 0) , rgba(#2E3443, 1), rgba(#2E3443, 0) , rgba(#2E3443, 0) );
    animation: shine 1s linear infinite;
  }
  &.withoutLoader {
      &::after {
          display: none !important;
      }
  }
  &.heroSkeletonLoader {
      width: 100%;  
      aspect-ratio: 2.37;
    height: 100%;
      min-height: 150px;
      border-radius: 10px;
      @include md {
        //   aspect-ratio: 3.97;
      }
  }
  &.spinWheelSkeletonLoader {
      width: 370px;
      aspect-ratio: 1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          filter: blur(5px);
      }
      @include max-450 {
          width: 330px !important;
      }
  }
  &.sportsCardSkeletonLoader {
      width: 100%;
      border-radius: 10px;
      aspect-ratio: 1.51;
      height: 280px;
  }
  
  &.gameCardSkeletonLoader {
      width: 100%;
      border-radius: 10px;
      aspect-ratio: 0.7245;
  }
  &.providerCardSkeletonLoader {
      width: 100%;
      border-radius: 10px;
      height: 75px;
  }
  &.titleSkeletonLoader {
      height: 24px;
      width: 140px;
      border-radius: 5px;
  }
  &.seeAllBtnSkeletonLoader {
      height: 24px;
      width: 90px;
      border-radius: 5px;
  }
  &.casinoCatLoaderrrr {
      height: 40px;
      width: 100%;
      margin-bottom: 4px;
  }
}

@keyframes shine {
0% {
  transform: translateX(-70%);
  opacity: 0;
}
50% {
  transform: translateX(-35%);
  opacity: 1;
}
100% {
  transform: translateX(0%);
  opacity: 0;
}
}


.skeletonGameRow {
  & > * {
    width: 110px !important;
      padding-inline: 4px !important;
  }
}


@media (min-width : 992px) {
  .skeletonGameRow {
      & > * {
          width: 140px !important;
      }
  }
}
