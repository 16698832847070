.header {
  &.sportsHeader {
    box-shadow: none !important;
  }
  & .upperHeader {
    padding: 10px 20px;
    @include max-md {
      padding: 10px;
    }
    @include lg {
      height: 100%;
      padding: 0px;
    }
    & .headerPaddingCol {
      @include lg{
        padding-inline: 40px;
      }
    }
    & .loginSignUpBtns {
      & .authBtn {
        font-size: 14px;
        font-weight: 600;
        border-radius: 25px;
        background-color: transparent;
        color: $theme5;
        height: 35px;
        // height: 38px;
        padding: 7px 22px;
        // padding: 7px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        box-shadow: 1px 2px 4px 0px #00000040 ,0px 0.65px 0.78px 0px #0E95FA inset ;
        &:is(:hover, .fill) {
          background-color: #0E95FA;
          color: white;
          box-shadow: 0px 1px 2px 0px #00000040 , 0.5px 0.5px 0.5px 0px #fff inset;
        }
      }
    }
    & .mobAuthBtn {
      font-size: 11px;
      font-weight: 600;
      color: white;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      border: 0;
      margin-left: 7px;
      border-radius: 25px;
      background-color: #242937;
      box-shadow: 1px 2px 4px 0px #00000040 ,0px 0.65px 0.78px 0px #0E95FA inset ;
      & img {
        height: 14px;
        object-fit: contain;
      }
      &.dark {
        background-color: #0E95FA;
        color: white;
        box-shadow: 0px 1px 2px 0px #00000040 , 0.5px 0.5px 0.5px 0px #fff inset;
        // border: 2px solid #363B4B;
        // border-bottom: 3px solid #363B4B ;
      }
    }
    & .logoLinkCol {
      @include lg {
        width: 195px;
        padding-left: 21px !important;
        position: relative;
        display: flex;
        align-items: center;
        // justify-content: center;
        height: 100%;
        // box-shadow: 2px 0px 8px -1px rgba(0,0,0,0.5);
        // &::before {
        //   content: '';
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   width: 3px;
        //   height: 100%;
        //   box-shadow: 1px 1px 20px 10px rgba(0, 0, 0, 0.5);
        // }
      }
    }
    & .logoLink {
      height: 35px;
      width: 100px;
      @include lg {
        height: 60px;
        // height: 32px;
        width: 136px;
        margin-top: 4px;
      }
      & img {}
    }
    & .userCurrencyImg {
      & .headerIcons{
        height: 20px;
        width: 20px;
        line-height: normal;
        & img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    & .userBalance {
      height: 31px;
      width: 110px;
      background : $theme2;
      padding: 4px 4px 4px 0px;
      border-radius: 25px;
      // padding-left: 10px;
      @include lg {
        height: 40px;
        // height: 36px;
        min-width: 150px;
        width: auto;
        padding: 5px;
        border: 1px solid #363B4B;
        border-right: 0;
      }
      @include xl {

      }
      
      @include xxl {
        
      }
      & .userBal {
        // padding-left: 10px;
        margin: 2px;
        font-size: 9.77px;
        color: white;
        font-weight: bold;
        display: block;
        & .amountSpan {
          max-width: 66px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.userBal1{
          display: flex;
          align-items: center;
          & img{
            width: 15px;
            height: 15px;
            object-fit: contain;
            margin-right: 4px;
          }
        }
        @include lg {
          font-size: 13px;
        }
      }
      & .plusBtn {
        // height: 25px;
        // width: 25px;
        aspect-ratio: 1;
        height: 100%;
        background-color: #0E95FA;
        box-shadow: 0px 1px 2px 0px #00000040 , 0.5px 0.5px 0.5px 0px #fff inset;
        border-radius: 25px;
        // border-bottom: 3px solid #318220;
        cursor: pointer;
        &.grayBtn {
          background-color: rgba($theme5, 0.3);
          border: 0 !important;
        }
        @include lg {
          // height: 32px;
          // width: 32px;
          height: 30px;
          width: 30px;
          border-radius: 25px;
        }
        & img {
          height: 11px;
          display: flex;
        }
      }
    }
    & .headerIcons{
      cursor: pointer;
      & .col-auto {
        &:is(:hover, .active) {
          & .icontext {
            color: white;
          }
          & .nonActiveImg {
            display: none !important;
          }
          & .activeImg {
            display: flex !important;
          }
        }
      }
      & .icons{
          margin: auto;
          @include lg{
            width: 24px;
            height: 24px;
          }
          @include xl{
            // width: 20px;
            // height: 20px;
            width: 19px;
            height: 19px;
          }
          @include xxl{
            // width: 24px;
            // height: 24px;
          }
        & img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      & .icontext{
        font-weight: 600;
        color: $theme5;
        text-align: center;
        line-height: normal;
        margin-top: 2px;
        @include lg{
          font-size: 12px;
        }
        @include xl{
          font-size: 13px;
          // font-size: 14px;
        }
        @include xxl{
          // font-size: 15px;
        }
      }
    }
    & .iconHeader{
      cursor: pointer;
      display: flex;
      position: relative;
      &.active {
        &::before {
          content: '';
          position: absolute;
          top: -2px;
          right: -2px;
          height: 8px;
          width: 8px;
          background-color: #FF0000;
          color: white;
          font-size: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
      // height: 35.44px;
      @include lg{
        height: 18px;
        width: 18px;
      }
      @include xl{
        // height: 20px;
        // height: 22px;
      }
      @include xxl{
        // height: 25px;
      }
      &.iconHeaderN{
        // height: 30px;
        @include lg{

        }
        @include xl{

        }
        @include xxl{
          
        }
      }
      & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: flex;
      }
    }
  }
  & .bottomHeader {
    display: flex;
    align-items: center;
    & .bottomBarBtn {
      cursor: pointer;
      // width: 20%;
      width: 16.66%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & .icon {
        height: 18px;
        width: 18px;
        display: flex;
        &.tokenIcon{
          // width: 36px;
          // margin-left: 6px;
        }
        & img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      & .txt {
        margin-top: 4px;
        font-size: 9.63px;
        font-weight: bold;
        color: $theme5;
      }
      &:is(:hover, .active) {
        & .txt {
          color: white;
        }
        & .grayImg{
          display: none;
        }
        & .activeImg{
          display: block !important;
          &.giftImg {
            filter: brightness(10);
          }
        }
      }
    }
  }
  .languageDropMenu{
    width: 308px !important;
    top: 22px !important;
    padding: 15px !important;

 & .searchInpDrop{
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-inline: 12px;
    background: #191d27;
    padding: 16px;
    border-radius: 12px;
    & .searchImgg{
        position: absolute;
        width: 35px;
        height: 35px;
        object-fit: contain;
        color: #93A6C4;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        padding-left: 18px;
    }
    & .inp{
      background-color: #212632 !important;
        border: 1px solid #93A6C4;
        border-radius: 5px;
        height: 42px;
        color: white;
        padding-left: 32px;
        width: 100%;
        &::placeholder{
            padding-left: 10px;
            font-size: 15px;
            font-weight: 600;
            color: #93A6C4;
        }
    }

}
& .currency_search_list {
  height: 300px;
  background-color: #191d27;
  border-radius: 12px;
  padding: 2px !important;
  & .dropList {
      border: 1px solid transparent;
      color: #fff;
      background-color: #212632 !important;
      border-radius: 25px;
      padding: 5px 10px !important;
      &:hover {
          border-color: #fff;
      }
      &.selected {
        background: linear-gradient(90deg, #0e95fa 0%, #085894 100%) !important;
      }
      & .form-check-input{
          -bs-form-check-bg: #6A798C !important;
          background-color: #6A798C !important;
          border: 4px solid #2F364C !important;
      }
      & .form-check-input:checked[type=radio]{
          --bs-form-check-bg-image:none !important;
          background-color: #0e95fa !important;
          border: 4px solid #ffffff !important;
      }
      & img{
          height:25px;
      }
  }
}
}
& .dropList{
    height: 40px;
    border: 1px solid $theme10;
    border-radius: 5px;
    padding-inline: 10px;
    transition: 0.3s linear;
    margin-inline: 12px;
    // text-transform: uppercase;
    &:hover{
        border: 1px solid #93A6C4;
        border-radius: 5px;
        height: 40px;
        padding-inline: 10px;
    }
}
& .btcText{
    font-size: 15px;
    font-weight: 600;
    color: $theme5;
    & img{
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 8px;
        border-radius: 50px;
        overflow: hidden;
    }
}
}


.react-datepicker__input-container{
  & input{
    background-color: #212632 !important;
    padding: 0;
    color: white !important;
    border: none !important;
    outline: none !important;
    &::placeholder{
      color: white !important;
    }
  &:focus{
      border: none !important;
      box-shadow: none !important;
    
  }
  }
}
.react-datepicker-popper{
  top: 5px !important;
  & .react-datepicker{
    background-color: #1E222D !important;
    color: white !important;
    border: #1E222D !important;

    & .react-datepicker__header{
     background-color: #1E222D !important;
     & h2{
      color: white !important;
     }
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
      color: white !important;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
      color: black !important;
    }
 }
}

@media (max-width: 385px) {
  .header {
    & .upperHeader {
      & .userBalance {
        width: 110px !important;
        & .amountSpan {
          max-width: 45px !important;
        }
      }
    }
  }
}

.search-icon{
  height: 30px;
  width: 30px;
  box-shadow: 1px 2px 4px 0px #00000040 ,0px 0.65px 0.78px 0px #0E95FA inset ;
}

.down-arrow{
  background: #0e95fa;
    height: 17px;
    width: 17px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    left: 32px;
}

.menu-icon{
  padding-left: 21px;
  & .search-icon{
    height: 35px;
    width: 35px;
  }
}

.game-box{
  padding: 8px 10px;
  border-radius: 25px;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2509803922), 0px 0.65px 0.78px 0px #0E95FA inset;
  & h6{
    font-size: 12px;
    font-weight: 500;
  }
}

.header-right-bar{
  gap: 11px;
  & .col-auto{
    padding-inline: 0;
  }
}

.header-left-bar{
  gap: 11px;
}