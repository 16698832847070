// message offcanvas start 
.msgoffcanvas {
  width: 100% !important;
  background-color: #202733 !important;
  @include sm {
    
  max-width: 500px;
  width: 450px !important;
  }
  & .msgHeader {
      padding: 8px !important;
      background-color: #2F364C !important;
      border-bottom: 2px solid  #0E95FA;
      & .msgImg {
          color: #FFF;
          font-size: 12px;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;
          border-radius: 5px;
        //   background: #222634;
          height: 30px;
          gap: 8px;
          padding: 0 8px;
          & .msgicon {
              height: 14px;
              width: 14px;
              object-fit: contain;
          }
      }
      & .chatrules {
          // color: $color1;
          font-size: 12px;
          font-weight: 800;
          line-height: normal;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
      }
      & .offcanvasclose {
          width: 18px;
          height: 18px;
          background: none;
          opacity: 1 !important;
          & img {
              object-fit: contain;
          }
      }
      & .closebtn {
          height: 15px;
          background: none;
          width: 15px;
          opacity: 1 !important;
          & img {
              object-fit: contain;
          }
      }
  }
  & .msgBody {
      padding: 8px;
      & .msgContent {
        margin-top: 120px;
          & .msgBtn {
              border-radius:50px;
              // background: #15191f;
              padding: 10px;
              margin-bottom: 8px;
              & .userimg {
                height: 50px;
                width: 50px;
                min-width: 30px;
                margin-right: 5px;
                border: 3px solid #3DACFF;
                flex-shrink: 0;
                & img {
                    object-fit: cover;
                }
              }
              & .username {
                  color: #B2B6C5;
                  font-size: 11px;
                  font-weight: 500;
                  line-height: normal;
                  white-space: nowrap;
              }
              & .usermsg {
                  color: #B2B6C5;
                  font-size: 11px;
                  font-weight: 500;
                  line-height: normal;
                  max-width: calc(100% - 46px);
                  width: 100%;
                  overflow-wrap: anywhere;
                  // white-space: nowrap;
                  // text-overflow: ellipsis;
                  // overflow: hidden;
                  & .itemMsg {
                    background-color: #191D27;
                    width: 100%;
                    padding: 8px 10px;
                    border-radius: 2px;
                    font-size: 15px;
                    color: white;
                    margin-top: 5px;
                    &::first-letter {
                      text-transform: uppercase;
                    }
                  }
              }
          }
      }
      & .pinned-comment-section{
        background-color: #2F364C;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        position: fixed;
        top: 48px;
        width: 100%;
        overflow-y: scroll;
        max-height: 380px;
        @include sm{
            width: 450px;
        }
        & .collapsed{
            & svg{
                rotate: 180deg;
            }
        }
        & .pinned-side-border{
            border-left: 1px solid  #0E95FA;
            margin: 20px;
            padding: 10px 15px;
            margin-bottom: 0;
            & .pinned-msg{
                font-weight: 500;
                font-size: 15px;
                color: #6A798C;
                & span{
                    color: #fff;
                }
            }
            & .pinned-comment{
                color: #6A798C;
            }
        } 
        & .cross-icon{
            top: 8px;
            right: 8px;
        }
       
        & .trophy-dropdown{
            background-color: #242937;
            top: 48px;
            min-width: 100%;
            position: fixed;
            @include sm{
                min-width: 450px;
            }
            & .heading{
                font-weight: 600;
                font-size: 17px;
                color: #fff;
            }
            & .profile-name{
                color: #fff;
                font-weight: 600;
                font-size: 17px;
            }
            & .profile-income{
                background-color: #191D27;
                font-weight: 600;
                font-size: 15px;
                color: #C2FB01;
                border-radius: 5px;
                margin-inline: 11px;
                padding: 10px 13px;
                text-align: center;
            }
            & .profile-img{
                width: 29px;
                height: 29px;
                border-radius: 50%;
                & img{
                    object-fit: contain;
                }
            }
        }
    }
  }
  & .offcanvasFooter {
        padding: 20px;
        background: #2F364C;
        box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.50);
        border-radius: 10px 10px 0px 0px ;
        & .InputMain {
            padding: 0 8px;
            border-radius: 8px;
            background: #191D27;
            height: 50px;
                border-radius: 25px;
            
            & .sendInput {
                color: #B2B6C5;
                font-size: 13px;
                font-weight: 500;
                line-height: normal;
                padding-left: 5px;
                &::placeholder {
                    color: #B2B6C580;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            & .smileFace {
                height: 36px;
                width: 36px;
                margin-right: 10px;
                & img {
                    object-fit: contain;
                }
            }
            & .plus-icon{
                background: linear-gradient(135deg, #0BCFF4 14.64%, #4D5EF3 85.36%);
                border-radius: 50% !important;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
            }
            
        }
        & .sendBtn {
            width: 35px;
            height: 35px;
            border-radius: 50% !important;
            background: #0E95FA;
            flex-shrink: 0;
            & .sendImg {
                width: 18px;
                height: 18px;
            }
        }
        & .dropdown{
            & .dropdown-toggle{
                background-color: #202733;
                border-radius: 25px;
                color: #6A798C;
                border: 0;
                display: flex;
                align-items: center;
                padding: 8px 12px;
                width: 100%;
                justify-content: space-between;
    
                &::after{
                    content: "\f105"; 
                    font-weight: 900;
                    font-family: "FONTAWESOME"; 
                    font-style: normal;
                    color: #6A798C;
                    border: 0 !important;
                }
            } 
            & .dropdown-menu{
                background-color: #191D27;
                & .dropdown-item{
                  color: #fff;
                  padding: 6px 16px !important;
                  &:hover{
                    background-color: #202733 !important;
                  }
                }
                
              }
        }
        & .slash{
            color: #6A798C;
            font-weight: 600;
            font-size: 20px;
        }
        & #dropModal{
            & .modal-dialog{
                max-width:710px;
                & .modal-content{
                    overflow: hidden;
                    & .modal-header{
                        border-bottom: 0 !important;
                        background-color: #2E3443;
                        & .modal-title{
                            font-weight: 600;
                            font-size: 20px;
                            color: #fff;
                        }
                        & .btn-close{
                            filter: invert(1) !important;
                            opacity: 1 !important;
                        }
                    }
                    & .modal-body{
                        background-color:#202733 ;
                        padding: 20px;
                        & .dropModal-heading{
                            color: #B2B6C5;
                            font-weight: 300;
                            font-size: 16px;
                            & span{
                                color: #fff;
                            }
                        }
                        & .input-box{
                            background-color: #191D27;
                            border-radius: 25px;
                            padding: 10px 15px;
                            & input ,textarea{
                                resize: none;
                                background-color: transparent;
                                border: 0 !important;
                                color: #fff;
                                
                                border-radius: 0;
                                &:focus{
                                    box-shadow: none !important;
                                    outline: 0 !important;
                                    border: 0 !important;
                                }
                                &::placeholder{
                                    color: #B2B6C5;
                                    font-weight: 500;
                                    font-size: 17px;
                                }
                                
                            }
                            & .select-box{
                                position: relative;
                                border-left: 1px solid #B2B6C5 !important;
                                & .form-select{
                                    background-color: transparent;
                                    border: 0 !important;
                                    color: #B2B6C5;
                                    --bs-form-select-bg-img:none !important;
                                    
                                    &:focus{
                                        box-shadow: none !important;
                                        outline: 0 !important;
                                        border: 0 !important;
                                    }
                                    &::placeholder{
                                        color: #B2B6C5;
                                        font-weight: 500;
                                        font-size: 17px;
                                    }
                                }
                                &::after{
                                    position: absolute;
                                    content: "\f105"; 
                                    font-weight: 900;
                                    font-family: "FONTAWESOME"; 
                                    font-style: normal;
                                    color: #6A798C;
                                    border: 0 !important;
                                    top: 5px;
                                    right: 18px;
                                }
                            }
                            & .number{
                                color: #B2B6C5;
                                font-weight: 500;
                                font-size: 17px;
                                top: 18px;
                                right: 15px;
                            }
                            & .text-number{
                                bottom: 12px;
                                right: 15px;                            
                                color: #B2B6C5;
                                font-weight: 500;
                                font-size: 17px;
                            }
                        }
                        & .total-btc{
                            font-weight: 600;
                            font-size: 30px;
                            color: #fff;
                            & span{
                                color: #C2FB01;
                            }
                        }
                        & .drop-btn{
                            font-weight: 600;
                            font-size: 14px;
                            color: #fff;
                            border: 0;
                            background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
                            border-top: 2px solid  #3DACFF;
                            border-radius: 25px;
                            padding: 10px 20px;
                        }
                    }
                }
            }
            
        }
    }
}

 #confirmModal{
    & .modal-dialog{
        z-index: 99999999;
   
    & .modal-content{
        background-color: #202733 !important;
        & .btn-close{
            filter: invert(1) !important;
            opacity: 1 !important;
            right: 15px;
            position: absolute;
            top: 15px;
            z-index: 9;
        }
        & .cancle-btn{
            border: 0;
            border-top: 2px solid  #202733;
            box-shadow: 0px 0px 9px 0px #00000066;
            background-color:  #2F364C;
            font-weight: 600;
            font-size: 14px;
            color: #fff;
            border-radius: 25px;
            padding: 10px 38px;
        }
        & .ok-btn{
            background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
            border: 0;
            border-top: 2px solid  #3DACFF;
            font-weight: 600;
            font-size: 14px;
            color: #fff;
            border-radius: 25px;
            padding: 10px 38px;
        }
        & .heading-text{
            font-weight: 700;
            font-size: 23px;
            color: #fff;
        }
        & .desc-text{
            color:  #6A798C;
            font-weight: 500;
            font-size: 18px;
        }
    }
}
    
}
.chatrulescard {
  border-radius: 8px;
  // background: $coloring2;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.50);
  padding: 14px;
  & .chatHeading {
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
  }
  & .ruleMenu {
      // padding-top: 12px;
      & .rules {
          color: #B2B6C5;
          font-size: 15px;
          font-weight: 500;
          line-height: normal;
          margin: 5px 0px;
      }
  }
}
// message offcanvas end 

// chat rules modal start 
.rulesmodal {
    & .rulesDialog {
        max-width: 515px;
        & .rulescontent{
            background-color: #212632 !important;
            & .rulesheader {
                padding: 15px 21px 12px;
                background-color: $theme8;
                display: flex;
                align-items: center;
                justify-content: space-between;
                & .closebtn {
                    height: 13px;
                    width: 13px;
                    display: flex;
                    & img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    display: flex;
                    }
                }
            }
            & .rulebody {
                padding: 15px 20px 15px;
            }
        }
    }
}
// chat rules modal end

.coinmodal {
     & .coindialog {
      max-width: 550px;
            & .modalcontent {
            background-color: $theme1 !important;
            & .coinIcon {
                height: 50px;
                width: 50px;
            }
            & .coinname {
                font-size: 24px;
                font-weight: 600;
                color: #fff;
            }
            & .progress {
                height: 5px;
                background-color: #272B36;
                & .progress-bar {
                    background-color: $theme3;
                }
            }
            & .rank {
                font-size: 14px;
                font-weight: 500;
                color: #989CAD;
                &.rankname {
                    color: $theme5;
                }
            }
            & .rankcoin {
                height: 20px;
                width: 20px;
            }
            & .rankcard {
                background-color: $theme10;
                padding: 15px;
                & .ranktotal {
                    font-size: 14px;
                    font-weight: 600;
                    color: #989CAD;
                }
            }
            & .rankdata {
                gap: 8px;
            }
            & .modal-header{
                background-color: #2F364C;
                & .modal-title{
                    font-size: 16px;
                    font-weight: 800;
                    color: #fff;
                }
            }
            & .profile-header-section{
                border-bottom: 1px solid #202733;
                padding-bottom: 10px;
                & .medal-icon{
                    width: 20px;
                    fill: #fff;
                }
                & span{
                    color: #fff;
                }
            }
            & .modal-body{
                & .details-button{
                    color: #0E95FA;
                    font-size: 14px;
                    cursor: pointer;
                }
                & .profile-main-section{
                    & .user-profile{
                        width: 60px;
                        height: 60px;
                        border: 2px solid rgb(179 190 193);
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    & .profile-tag{
                        font-size: 12px;
                        font-weight: 800;
                        border-radius: 10px;
                        color: #fff;
                        padding: 0px 10px;
                        margin: -5px;
                        &.level-silver{
                            background: linear-gradient(246deg, rgb(183, 183, 183) 23.35%, rgb(195, 195, 195) 49.73%, rgb(127, 127, 127) 84.21%, rgb(68, 68, 68) 167.95%);
                        }
                    }
                    & .UserName{
                        font-size: 20px;
                        color: #fff;
                        font-weight: 800;
                        margin-top: 10px;
                    }
                    & .user-id{
                        color: rgb(179, 190, 193);
                        font-size: 12px;
                    }
                    & .likes-box{
                        background-color: #2F364C;
                        padding: 6px 12px;
                        border-radius: 10px;
                        top: 20px;
                        left: 0;
                        color: #fff;
                        & i{
                            color: #fc3c3c;
                        }
                        & span{
                            font-size: 18px;
                        }
                    }
                    & .Edit-box{
                        background-color: #2F364C;
                        padding: 6px 12px;
                        border-radius: 10px;
                        top: 20px;
                        right: 0;
                        color: #fff;
                        & i{
                            color: #fff;
                        }
                    }
                }
                & .medal-section{
                    background-color: #2F364C;
                    padding: 12px;
                    border-radius: 10px;
                    
                    & .medal-icons{
                        width: 44px ;
                        flex-shrink: 0;
                        & img{
                            height: 100%;
                            width: 100%;
                            opacity: 0.5;
                        }
                        &.complete{
                            & img{
                                opacity: 1;
                            }  
                        }
                    }
                }
                & .stats-section{
                    background-color: #2F364C;
                    padding: 12px;
                    border-radius: 10px;
                    & .stats-box{
                        background-color: #202733;
                        padding: 10px;
                        border-radius: 10px;
                        & .stat-icon{
                            height: 20px;
                            fill: white;
                        }
                        & .stats-heading{
                            font-size: 12px;
                        }
                    }
                }
                & .fav-game-section{
                    background-color: #2F364C;
                    padding: 12px;
                    border-radius: 10px;
                    & .game-image{
                        width: 80px;
                    }
                    & .game-name{
                        font-size: 16px;
                        color: #fff;
                    }
                    & .game-desc{
                        & p{
                            font-size: 12px;
                            color: rgb(179 190 193);
                        }
                        & h6{
                            font-size: 14px;
                            color: #fff;
                        }
                    }
                }
                & .wager-section{
                    background-color: #2F364C;
                    padding: 12px;
                    border-radius: 10px;
                    & table{
                        & th , td{
                            background-color:transparent !important ;
                            color: White !important;
                        }
                    }
                }
                & .date-joined{
                    color: rgb(179 190 193);
                    font-size: 14px;

                }
            }
        }
    }
}



.emojiDropdown {
  max-height: unset;
  height: 400px !important;
}

aside {
  &.EmojiPickerReact {
    background: transparent;
    --epr-emoji-size: 22px;
    --epr-category-navigation-button-size: 22px;
    & .epr-header {
      & .epr-category-nav {
        & button {
          // &.
        }
      }
    }

    &.epr-main {
      border: none;
    }

    & .epr-preview {
      display: none !important;
    }

    & .epr-header {
      & > div {
        &:first-child {
          display: none;
        }
      }
      & .epr-header-overlay {
        display: none;
      }
    }
    & .epr-body {
      & ~ div {
        display: none !important;
      }
    }

    & li.epr-emoji-category>.epr-emoji-category-label {
      background: transparent;
      color: #fff;
    }
  }
}

.resultModalWrapper{
  & .resultModalDialog{
      & .resultModalcontent{
          background: $theme1;
          & .modal-header{
              border-bottom: 0;
              & .dropMenu{
                  display: none;
                  transition: 0.3s linear;
              }
              & .dropMenuOne{
                  display: none;
                  transition: 0.3s linear;
              }
              &.added-class{
                  & .dropMenu{
                      display: block;
                  }
                  & .dropMenuOne{
                      display: none !important;
                  }
              }
              &.added-classs{
                  & .dropMenuOne{
                      // display: block;
                  }
              }
              & .modal-title{
                  color: #FFF;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: uppercase;
              }
              & .searchInp{
                  width: 100%;
                  height: 40px;
                  background-color: #242937;
                  border-radius: 4px;
                  border: 0;
                  box-shadow: none;
                  padding-left: 15px;
                  color: #B2B6C5;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  &:focus-visible{
                      outline: unset;
                  }
              }
              & .dropDown{
                  position: relative;
                  & .dropBtn{
                      color: #B2B6C5;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 600;
                      padding: 10px 16px;
                      border-radius: 5px;
                      line-height: normal;
                      text-transform: uppercase;
                      // filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
                      background-color: $theme10;
                      box-shadow: none;
                      border: 0;
                      // width: 125px;
                      height: 40px;
                      width: 100%;

                      & span{
                          color: #fff;
                          margin-left: 5px;
                      }
                      &::after{
                          content: '';
                          // background-image: url(../img/heroBanner/down.png);
                          background-repeat: no-repeat;
                          margin-left: 8px;
                          border-top: 0.5em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
                      }
                  }
                  & .dropMenu{
                      min-width: 5rem !important;
                      &.dropSort{
                          height: 135px;
                          width: 100%;
                      }
                      width: 200px;
                      height: 328px;
                      background-color: $theme10;
                      padding: 12px;
                      overflow: hidden;
                      position: absolute;
                      top: 100% !important;
                      right: 0 !important;
                      border-radius: 4px;
                      z-index: 6;
                      margin-bottom: 10px;
                      transform: translate(0,0) !important;
                      &.show {
                          display: block !important;
                      }
                      & .dropHeader{
                          // margin-bottom: 10px;
                          // padding-bottom: 10px;
                          // border-bottom: 1px solid #394956;
                          & .dropSearch{
                              width: 100%;
                              background-color: #15191F;
                              border: 0;
                              border-radius: 4px;
                              position: relative;
                              color: rgb(178, 182, 197);
                              height: 35px;
                              font-size: 12px; 
                              padding-left: 30px;
                              padding-right: 10px;
                              &::placeholder{
                                  color: rgb(178, 182, 197) !important;
                              }
                              &:focus-visible{
                                  outline: unset;
                              }
                          }
                          & .serachImg{
                              height: 13px;
                              width: 13px;
                              position: absolute;
                              top: 50%;
                              left: 10px !important;
                              transform: translateY(-50%);
                              & img{
                                  height: 100%;
                                  width: 100%;
                                  object-fit: contain;
                                  display: flex;
                              }
                          }
                      }
                      & .bodyWrapper{
                          overflow-y: auto;
                          height: 260px;
                          padding-bottom: 20px;
                          & .dropBody{
                              & .formCheck{
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  padding-left: 0;
                                  & .dropdownInp {
                                      &:checked {
                                          & ~ .dropdownLabel {
                                              & .dropdownCheckbox {
                                                  background-color: #15191F;
                                                  &::before {
                                                      display: flex;
                                                  }
                                          }
                                      }
                                      }
                                  }
                                  & .dropdownLabel {
                                      width: 100%;
                                      cursor: pointer;
                                      & .dropdownCheckbox {
                                          height: 17px;
                                          width: 17px;
                                          margin: 0px 8px 0 0 ;
                                          min-width: 17px;
                                          display: flex;
                                          border: 2px solid $theme3;
                                          border-radius: 4px;
                                          cursor: pointer;
                                          position: relative;
                                          &::before {
                                              content: "";
                                              position: absolute;
                                              top: 40%;
                                              left: 50%;
                                              transform: translate(-50%, -50%) rotate(45deg);
                                              height: 10px;
                                              width: 7px;
                                              border-right: 3px solid $theme3;
                                              border-bottom: 3px solid $theme3;
                                              border-radius: 2px;
                                              display: none;
                                          }
                                      }
                                      & .dropdownTxt {
                                          color: white;
                                          font-size: 13px;
                                      }
                                      & .dropdownValue{
                                          background-color: #2F3A47;
                                          width: 28px;
                                          height: 28px;
                                          border-radius: 50%;
                                          display: flex;
                                          justify-content: center;
                                          align-items: center;
                                          color: white;
                                          font-size: 10px;
      
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
          & .modal-body{
              & .loadBtn{
                  background-color: #15191F;
                  border: 0;
                  border-radius: 4px;
                  color: #FFF;
                  text-align: center;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 800;
                  letter-spacing: 0.5px;
                  line-height: normal;
                  text-transform: uppercase;
                  transition: all .3s ease-in-out;
                  padding: 12px 31px;
              }
          }
          & .modal-footer{

          }
      }
  }
}


.gameSearchPage {
  & .searchGameCardWrapper {
      & > * {
          width: 11.11%;
          flex: 0 0 11.11%;
      }
  }
}

@media (max-width: 1399px) {
  .gameSearchPage {
      & .searchGameCardWrapper {
          & > * {
              width: 12.5%;
              flex: 0 0 12.5%;
          }
      }
  }
}

@media (max-width: 1199px) {
  .gameSearchPage {
      & .searchGameCardWrapper {
          & > * {
              width: 12.5%;
              flex: 0 0 12.5%;
          }
      }
  }
}



@media (max-width: 991px) {
  .gameSearchPage {
      & .searchGameCardWrapper {
          & > * {
              width: 20%;
              flex: 0 0 20%;
          }
      }
  }
}
@media (max-width: 767px) {
  .gameSearchPage {
      & .searchGameCardWrapper {
          & > * {
              width: 25%;
              flex: 0 0 25%;
          }
      }
  }
}

@media (max-width: 575px) {
  .gameSearchPage {
      & .searchGameCardWrapper {
          & > * {
              width: 33.33%;
              flex: 0 0 33.33%;
          }
      }
  }
}



.NotificationoffcanvasWrapper{
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.50);
  & .offcanvasHeader{
      background: #15191F;
      & .offcanvasTitle{
          color: #FFF;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
      }
  }
  & .offcanvas-body{
      // background: #212632;
      & .offcanvasBtns{
          border-radius: 5px;
          height: 35px;
          color: #FFF;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #15191F;
          cursor: pointer;
          &.active{
              background: linear-gradient(180deg, #EFB90B 0%, #15191F 121.43%);
          }
      }
      & .offcanvasTextWrapper{
          border-radius: 4px;
          background: $theme8;
          padding: 14px 11px;
          // margin-top: 9px;
          & .date{
              color: #B2B6C5;
              font-size: 11px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-bottom: 14px;
          }
          & .time{
              color: #FFF;
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 11px;
          }
          & .banner{
              // height: 150px;
              width: 100%;
              & img{
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 4px;
                  margin-bottom: 8px;
              }
          }
          & .text{
              color: #B2B6C5;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
          }
          & .collapseBtn{
              color: #FFF;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              cursor: pointer;
              &.upperCollapsedBtn {
                  &:not(.collapsed) {
                      height: 0;
                      overflow: hidden;
                  }
              }
              &:active{
                  border: 0 !important;
                  border-color: black;
              }
              & svg{
                  width: 10px;
                  height: 5.74px;
                  margin-left: 5px;
              }
          }
          & .collapseBody{
              background: #15191F;
              & .textAnchor{
                  color: #EFB90B;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  text-decoration: none;
              }
              & .anchorDivider{
                  background: #424966;
                  width: 100%;
                  height: 1px;
              }
          }
      }
  }
}


.noNotificationCard {
  & img {
      // filter: grayscale(0.7);
      height: 130px;
      object-fit: contain;
  }
  & .txxxtttt {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: center;
  }
}

.loadMoreNotiBtn {
  border-radius: 4px;
  background: #15191F;
  font-size: 12px;
  font-weight: 500;
  color: white;
  border: 1px solid #15191F;
  text-transform: capitalize;
  padding: 8px;
  width: 130px !important;
  margin-inline: auto;
}

.medal-modal{
    & .medal-modal-content{
        background-color: #2F364C;
        border-radius: 10px;
        & .single-medal-box{
            background-color: #202733;
            padding: 12px;
            border-radius: 10px;
            & .medal-icon{
                width: 44px ;
            }
            & .medal-desc{
                & .medal-title{
                    color: #fff;
                    font-size: 14px;
                }
                & .medal-date{
                    color: #b3bec1;
                    font-size: 14px;
                }
            }
        }
        .medal-details-box{
            & .medal-icon{
                width: 85px;
            }
            & .medal-desc{
                & .medal-title{
                    font-size: 18px;
                    font-weight: 700;
                    color:#fff;
                }
                & .medal-data{
                    color: #b3bec1;
                }
                & .medal-date{
                    color: #b3bec1;
                    font-size: 14px;
                }
            }
        }
        & .more-details{
            & .detail-heading{
                color: #b3bec1;
                font-size: 14px;
            }
            & .detail-desc{
                color: #ffffff;
                font-size: 14px;
            }
        }
    }
    & .master-text{
        color: #0E95FA;
    }
}
.coinmodal{
 
 & .medal-modalcontent {
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;
  }

  & .medal-modalcontent.active {
    transform: translateX(0);
    
  }
}
.Statistics-modalcontent{
    & .stats-modal{
        & .stats-modal-content{
            & .dropdown{
                & .dropdown-toggle{
                    background-color: #191d27;
                    border-radius: 15px;
                    color: #fff;
                    border: 0;
                    font-weight: 500;
                    font-size: 15px;
                    padding: 10px 18px;
                    & span{
                        color: #0E95FA;
                    }
                    &::after{
                        content: "\f107"; 
                        font-weight: 900;
                        font-family: "FONTAWESOME"; 
                        font-style: normal;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #202733;
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        border: 0;
                    }
                }
                & .dropdown-menu{
                    background-color: #191d27;;
                    border-radius: 15px;
                    border: 0;
                    padding: 10px 18px;
                    max-height: 180px;
                    overflow: auto;
                    & .dropdown-item{
                        &:hover{
                            background-color: #2F364C;
                            border-radius: 10px;
                        }
                        & .form-check{
                            & .form-check-label{
                                color: #fff !important;
                                font-weight: 500;
                                font-size: 12px;
                            }
                            & .form-check-input{
                                background-color:transparent !important;
                                &:checked{
                                    border-width: 2px !important;
                                }
                            }
                        }
                    }
                }
            }
            & .stats-userimg{
                height: 36px;
                width: 36px;
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;
            }
            & .stats-profile-name{
                font-size: 14px;
            }
            & .table{
                & th{
                    background-color: transparent;
                    color: #fff;
                    padding-bottom: 20px;
                }
                & td{
                    background-color: transparent;
                    color: #fff;
                    font-weight: 300;
                    font-size: 14px;
                    & .bitcoin-img{
                        width: 16px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                }
            }
            & .pagination-btn{
                background-color: #202733;
                border: 0;
                border-radius: 5px;
                padding: 6px 10px;
                & i{
                    color: #fff;
                }
            }
            & .pagination-input-box{
                background-color: #202733;
                border-radius: 5px;
                padding: 6px 10px;
                & .pagination-input{
                    min-width: 35px;
                    width: 35px;
                    background-color: #2F364C ;
                    color: #fff;
                    border-radius: 5px;
                    border: 0;
                    text-align: center;
                }
            }
        }
    }
}
