.herobanner{
  width: 100%;
  height: 203px;
    @include sm{
      height: 100%;
    }
  img{
    width: 100%;
    height: 100%;
  }
}
.secondVip{
  margin-top: 30px;
  row-gap: 28px;
  display: grid !important;
 .vipcard{
  border-radius: 13.36px;
  background: var(--Gray-Mneu-button-Color, #202733);
  border-top: 2.67px solid var(--Strok-Color, #2F364C);
  padding-block: 18px;
  padding-inline: 20px;
  box-shadow: 0px 5.34px 5.34px 0px #00000040;


  & .innerCard{
    margin-top: 16px;
    background-color: #191D27;
    border-radius: 13.36px;
    padding-block: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-inline: 20px;
    border: 2px solid transparent;
    transition: .3s all;
    &:hover{
      border: 2px solid #0E95FA;
   
    }

    & .claimImg{
      width: 165px;
      height: 165px;
    }
    & .claimtext{
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0%;

    }
    

    
    }
    & .clamed{
      height: 1px;
      width: 100%;
      background-image: linear-gradient(90deg, rgba(57, 145, 210, 0) 0%, #1D4A6C 47.5%, rgba(57, 145, 210, 0) 100%);
      margin-top: 8px;
      margin-bottom: 12px;
    }
  }
 }
.energy_img{
  width: 14px;
  height: 14px;
  margin-inline-end: 5px;
}
 .energy{
  display: flex;
  width: max-content;
  align-items: center;
  padding-block: 4px;
  padding-inline: 5px;
  transition: .3s all;
  border-radius: 17.25px;
  cursor: pointer;
  
  }
  .energy:hover{
      background-color: #2F364C;
  }
  .energy_text{
    font-weight: 500;
    font-size: 10px;
    line-height: 12.5px;
    letter-spacing: 0%;
    text-align: center;

  }
  .claimtext2{
    font-family: Lexend;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0%;

  }
  .neontext{
    color: #C2FB01;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0%;
    
  }
  .energybtn{
    display: flex;
    align-items: center;
    background: #2F364C;
    border: 1px solid var(--Main-Blue-Color, #0E95FA);
    border-radius: 32.97px;
    padding-block: 8px;
    padding-inline: 10px;
    margin-top: 21px;
    & .thunder_img{
      height: 27px;
      width: 27px;
    }
    & .thunder_text{
      font-weight: 500;
      font-size: 19px;
      line-height: 23.75px;
      letter-spacing: 0%;
      text-align: center;
      padding-inline-start: 10px;

    }
  }
  .crownImg{
    width: 20px;
    height: 14px;
    display: flex;
  }
  .crowntext{
    font-weight: 500;
    font-size: 13.36px;
    line-height: 16.7px;
    letter-spacing: 0;
    text-align: center;
    color: #556573;
  }
  .bonustabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-top: 25px;
    & .thunderbtn{
      display: flex;
      align-items: center;
      padding-block: 2.5px;
      padding-inline:3.5px ;
      background-color: #2F364C;
      border-radius: 11.83px;
      & .thunder_img{
        width: 10px;
        height: 10px;
        img{
          display: flex;
        }
      }
      & .tunder_text{
        font-weight: 500;
        font-size: 7px;
        line-height: 8.75px;
        letter-spacing: 0%;
        text-align: center;
        color: white;
        padding-inline-start: 3px;
        margin-top: 1px;
      }
    }
    
  }
  .bonusbar{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 16px;
    height: 10px;
    background-color: #2F364C;
    border-radius: 5px;
    position: relative;
    transition: .3s all;
    &::before{
      position: absolute;
      content: '';
      width:  var(--width);
      height: 100%;
      top: 0;
      left: 0;
      background-color: #0E95FA;
      border-radius: 5px;

      
    }
    & .dott{
      border: 1px solid var(--Strok-Color, #2F364C);
      background-color: #0E95FA;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: relative;
      z-index: 50;
    }
    
  }
  .thunderbtnactive{
    background: linear-gradient(270deg, #0E95FA 0%, #085894 100%) !important;
  }
  .bonustime{
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 10px;
    
    
  }
  .digitalWatch{
    background-color: #202733;
    border-radius: 13.36px;
    border: 1.21px solid var(--Main-Blue-Color, #0E95FA);
    padding-block: 12px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 22px;
    line-height: 27.5px;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 10px;
    position: relative;
    & .time{
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 5px;
      bottom: -8px;
    }
    & .day{
      font-weight: 500;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0%;
      text-align: center;
      color: white;
      height: 15px;
      width: 39px;
      background-color: #2F364C;
      border-radius: 17.25px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
  .vip_latest{
    background: #202733;
    border-top: 2.67px solid #2F364C;
    padding-inline: 20px;
    padding-block: 16px;
    border-radius: 13.36px;
    margin-bottom: 0px;
    @include md{
      margin-bottom: 20px !important;
          }
    
    & .innerSection{
      background: linear-gradient(360deg, #D60743 0%, #F200FF 100%);
      padding-top: 23px;
      padding-bottom: 16px ;
      border-radius: 13.36px;
      margin-top: 15px;
      position: relative;
      & .text{
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0%;
        padding-inline: 17px;
        
      }
      & .subheading{
        margin-top: 8px;
        display: flex;
        gap: 6px;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0%;
        margin-bottom: 14px;
               
        
        & .label_1{
          background-color: #F8BA28;
          border-radius:  0px 10px 10px 0px ;
          padding-inline: 21px 10px;
          padding-block: 4px;
          font-weight: 800;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 0%;
          color: #191D27;
        }
      }
     & .girlImg{
       position: absolute;
       bottom: 0;
       right: 6px;
       width: 141px;
       height: 190px;
      }
    }
  }
  .checkImg{
    height: 14px !important;
    width: 14px !important;
  }

  .benefittext{
    font-weight: 700;
    font-size: 12px;
    line-height: 11.25px;
    letter-spacing: 0%;
  }
  .benefitList{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-inline: 17px;
    gap: 8px;
    

  
   }
   .RodeoCard{
    margin-top: 15px;
    background-color: #191D27;
    border-radius: 13.36px;
    position: relative;
    padding-top: 40px;
    padding-bottom: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0%;
    text-align: center; 
  
    @include md{
    font-size: 16px;

    }     



    & .blurlayer{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 0;
      @include md{
        width: 160px;
        height: 160px;
      }

    }
    & .superpoint{
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0%;
      text-align: center;
      background: #F8BA28;
      width: 60px;
      height: 21px;
      border-radius: 50px;
      position: absolute;
      color: #191D27;
      top: -5px;
      left: 50%;
      transform:translatex(-50%);
      display: flex;
      align-items: center;
      justify-content: center;

    }

    & .rodeoimg{
      width: 49px;
      height: 80px;
      margin-bottom: 14px;
      position: relative;
      z-index: 20;
      img{
          object-fit: contain;
      }
    }
   }
   .flameImg{
    width: 17px;
    height: 23px;
    display: flex;

   }
   .superbonusbox{
    background: #202733;
    border-top: 2.67px solid var(--Strok-Color, #2F364C);
    box-shadow: 0px 5.34px 5.34px 0px #00000040;
    border-radius: 10px;
    padding: 9px;
    display: flex;
    align-items: center;
    gap: 13px;
    margin-top: 12px;


   & .imgbox{
    width: 58px;
    height: 58px;
    background: var(--bgcolor);
    border-radius: 5px;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  & .jarimg{
    width: 28px;
    height: 40px;
  }
  
   }
   }
   .darktext{
    font-weight: 500;
    font-size: 13.36px;
    line-height: 16.7px;
    letter-spacing: 0%;
    text-align: center;
    color: #556573;
    padding-bottom: 5px;

  }
   .money{
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0%;
    color: white;
  }
 
  .nextbtn{
    width: 27px;
    height: 21px;
    background: var(--Main-Blue-Color, #0E95FA);
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
  }
  .prevbtn{
    width: 27px;
    height: 21px;
    background: var(--Main-Blue-Color, #0E95FA);
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .arrow{
    width: 5.1px;
    height: 8.47;
  }
  .navigation{
    display: flex;
    gap: 6px;
  }

  .topWinner{
    background: #202733;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 38px;
    margin-top: 30px;
    position: relative;

    & .range_Bar{

      & .range_line{
        width: 5px;
        height: 370px;
        background-color: #0E95FA;
        top: 43%;
        right: 36%;
        transform: translateY(-50%) translateX(-50%) rotate(36deg);
    

        &::before{
          content: "";
          width: 15px;
          height: 15px;
          border: 4px solid var(--Main-Blue-Color, #0E95FA);
          background-color: white;
          border-radius: 50%;
          position: absolute;
          right: -5px;
          top: -7px;
          z-index: 20;
      }
      }

      & .username{
        top: 8px;
        
        &.username_1{
          left: 74%;
        }
        &.username_2{
          left: 70%;
        }
        &.username_3{
          left: 66%;
        }
        &.username_4{
          left: 62%;
        }
        &.username_5{
          left: 58%;
        }
      }
    }
   
    & .first{
      margin-top: 26px;
      width: 100%;
      height: 40px;
      border: 1px solid var(--Strok-Color, #2F364C);
      border-radius: 10px;
      position: relative;
      
      
    
      &::after{
        content: '';
        position: absolute;
        background-image: url(../images/vip/bglayer.png);
        background-size: cover;
        border-radius: 10px;
        background-repeat: no-repeat;
        height: 100%;
        width:  var(--width);
        top: 0;
        left: 0;
      }
      &::before{
        content: '';
        position: absolute;
        background-size: cover;
        background: var(--bg);
        border: 3px solid var(--border);
        border-right: none;
        background-repeat: no-repeat;
        height: 100%;
        width:  var(--width);
        border-radius: 10px;
        top: 0;
        left: 0;
      }
    }
    .winner{
      height: 50px;
      width: 50px;
      border: 3px solid var(--border);
      border-radius: 50px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #191D27;
      top: 50%;
      transform: translateY(-50%);
      left: var(--left);
      z-index: 10;
      & .winnerImg{
        width: 30px;
        height: 37px;
      }
    }
    .winnumber{
      font-weight: 700;
      font-size: 31.67px;
      line-height: 39.58px;
      letter-spacing: 0%;
      text-align: center;
      color: #2F364C;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: 2px solid #2F364C;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -4px;
      background-color: #191D27;


    }
    .winneractive{
      border: 2px solid #F8BA28 !important;
      color: #F8BA28 !important;

    }
  }
  .levelSection{
    background: #202733;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
    
    & .levelCard{
      background:  #191D27;
      padding-block: 28px;
    border: 1px solid #F8BA28;
    border-radius: 10px;
    padding-inline: 10px;
   & .levelcardInner{
      background-image: url(../images/vip/passlevel.png);
      padding-top: 60px;
    background-size: cover;
    aspect-ratio: 172/220;

    @include md{
      padding-top: 33px;
    }
    & .text{
      font-weight: 700;
      font-size: 15px;
      line-height: 18.75px;
      letter-spacing: 0%;
      text-align: center;
      
    }
    & .starimg{
      width: 13px;
      height: 13px;
      display: flex;
      img{
        width: 100%;
        height: 100%;
      }
    }
    & .points{
      margin-top: 11px;
     & .reward{
      padding-inline: 22px;
      padding-bottom: 6px;

      & .coin{
        width: 15px ;
        height: 15px;
      }
      & .trophy{
        width: 15px;
        height: 17px;
      }
      & .point_text{
        font-weight: 500;
        font-size: 12px;
        line-height: 11.25px;
        letter-spacing: 0%;

      }

     }
  
    }
   }
    }
  }
  .divider{
    height: 1px;
    width: 100%;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 47.5%, rgba(255, 255, 255, 0) 100%);
    margin-bottom: 6px;
   }
   .textbold{
    font-weight: 500;
    font-size: 16px;
    line-height: 16.25px;
    letter-spacing: 0%;
    text-align: center;
    padding-top: 8px;
    

   }
   .active{
    border: 1px solid transparent !important;

    & .levelblock{
      background-image: url(../images/vip/locklevel.png) !important;
      padding-top: 33px;
      background-size: cover;
      opacity: 50%;
      aspect-ratio: 172/217;
      margin-block: auto;
      width: 100%;

      @include md{
      }

    }
  }
  .levelprogress{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100% ;
    margin-top: 38px;
   
    & .progress{
      width: 100%;
      height: 5px;
      background-color: #2F364C;
      position: relative;
      border-radius: 5px;
      overflow: visible;
       @include sm{
        width: 100%;
       }
        
      &::after{
        content: '';
        position: absolute;
        width:  var(--width);
        height: 100%;
        background-color: #F8BA28;
        top: 0;
        left: 0;
        border-radius: 5px;

      }
    }
    & .dottsholder{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 10px;
      top: 50%;
      transform: translateY(-50%);
      left: 0%;
      z-index: 20;
      & .dotts{
        width: 10px;
        height: 10px;
        background: #0E95FA;
        border-radius: 100%;
        flex-wrap: nowrap;
        border: 1px solid #2F364C;
      }
      
    }
    & .dott_text{
      width: 100%;
      font-weight: 500;
      font-size: 8px;
      line-height: 10px;
      letter-spacing: 0%;
      text-align: center;
      @include sm{
        width: 80%;
      }
      
    }
  }

  .customGrid {
    --bs-columns: 12;
    column-gap: 2px !important;
    gap: 4px !important;
    
    @include md {
      --bs-columns: 5;
    }
  }

  .customGrid_2{
    --bs-columns: 1;
      --bs-columns:1;
      gap: 10px !important;
      @include sm{
      --bs-columns:2 !important;

      }
     @include md {
      --bs-columns:3 !important;
    }
    
    @include xl {
      --bs-columns:5 !important;
    }
    
   } 


@media only screen and (max-width:768px){
  .herobanner{
    height: 100%;
  }

  .topWinner{

    & .range_Bar{

      & .range_line{
        top: 43%;
        right: 67%;
        transform: translateY(-50%) translateX(-50%) rotate(27deg);
    }

      & .username{
        top: 8px;
        font-size: 12px;
        
        &.username_1{
          left: 60%;
        }
        &.username_2{
          left: 50%;
        }
        &.username_3{
          left: 40%;
        }
        &.username_4{
          left: 30%;
        }
        &.username_5{
          left: 24%;
        }
      }
    }
   
    & .first{
     
      &.first_1{
        --width:50% !important;
      }
        
      &.first_2{
        --width:40% !important;
      }
        
      &.first_3{
        --width:30% !important;
      }
        
      &.first_4{
        --width:20% !important;
      }
        
      &.first_5{
        --width:10% !important;
      }
      
    }
    .winner{
        
      &.winner_1{
          --left:40% !important;
      }
      &.winner_2{
        --left:30% !important;
      }
      &.winner_3{}
         --left:20% !important;
      &.winner_4{
        --left:10% !important;
      }
      &.winner_5{
        --left:5% !important;
      }
      
    }
   & .winnumber{
       font-size: 22px; 
    }
  }
}
@media only screen and (min-width:768px) and (max-width:1200px){

    .topWinner {
      
      & .range_Bar .range_line {
        width: 5px;
        height: 370px;
        background-color: #0E95FA;
        top: 43%;
        right: 34%;
        transform: translateY(-50%) translateX(-50%) rotate(24deg);
      }
      & .username{
        top: 8px;
        font-size: 14px;
        
        &.username_1{
          left: 78% !important;
        }
        &.username_2{
          left: 74% !important;
        }
        &.username_3{
          left: 70% !important;
        }
        &.username_4{
          left: 66% !important;
        }
        &.username_5{
          left: 62% !important;
        }
      }
      
  }
}


