.aml-policy-section{
    padding-block: 40px;
    & .heading{
        color: #D8DEE5;
        font-size: 24.36px;
        font-weight: 700;
    }
    & .sub-heading{
        margin-top:15px ;
        font-size: 16px;
        font-weight: 600;
        color: #D8DEE5;
    }
    & .sub-txt{
        font-size: 16px;
        font-weight: 400;
        color: #989CAD;
    }
    & .sub-txt-heading{
        color: white;
        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
    }
    & .link-span{
        & a{
        color: #D8DEE5;
        }
    }
    & .sports-ponit-table{

        & td{
            text-align: center;
            background: #0E95FA;
            color: #FFFFFF;
        }
        & th{
            background: #0E95FA;
            text-align: center;
            font-size: 15px;
            line-height: 18.75px;               ;
            font-weight: 600;
            color: #FFFFFF;
        }
    }
}

.wallet-section{
    background-color: #242937;
    border-radius: 5px;
    overflow: hidden;
    padding: 14px;
    // margin-top: 10px;
    @include sm{
        padding: 25px;
    }
    @include xl{
    padding: 35px;

    }
    & .add-icon{
        height: 15px;
        width: 15px;
        display: flex;
        @include sm{
            height: 17px;
            width: 17px;
        }
    }
    & .add-txt{
        font-size: 18px;
        line-height: 130%;
        font-weight: 600;
        @include sm{
        font-size: 20px;

        }
    }
    .brDiv {
        border-right: 1px solid #B2B6C5;
    }
    .buttons-div{
        background: #2E3443;
        border-radius: 10px;
        padding: 22px 22px;
        @include md {
            max-width: 300px;
        }
        & .coin-icon{
            height: 17px;
            width: 17px;
            display: flex;
            @include sm{
                height: 21px;
                width: 21px;
            }
        }
        & .button-txt{
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            // justify-content: center;
            // text-align: center;
            &.button-right-border{
                // border-right: 1px solid #B2B6C5;
            }
            & .upper-txt{
                font-size: 12px;
                font-weight: 500;
                line-height: 130%;
            }
            & .lower-txt{
                font-size: 15px;
                font-weight: 600;
                line-height: 130%;
                color: #42B72A;
            }
        }
    }
    .onOffBtn{
        display: flex;
        align-items: center;
        & .form-check-input{
        background-color:$theme8;
        height: 12.4px;
        width: 33.44px;
        border-radius: 100px;
        margin-bottom: 5px;
        border: none;
        &:focus{
            border: 0;
            outline: none;
            box-shadow: none;
        }
        }
    
    }
    .form-switch .form-check-input{
        background-image: url(../images/profile/onOffGray.png) !important;
        height: 11px;
        width: 33px;
        background-repeat: no-repeat;
    }
    .toggle-div{
        display: flex;
        justify-content: end;
        gap: 10px;
        & .label-txt{
            font-size: 13px;
            line-height: 16px;
            text-wrap: nowrap;
            color: $theme5;
            font-weight: 500;
        }
    }
    & .search-bar{
        margin-top: auto;
        height: 100%;
        background: $theme1;
        display: flex;
        align-items: center;
        padding: 10px 10px 10px 22px;
        margin-top: 15px;
        border-radius: 5px;
        height: 35px;
        @include md{
        margin-top: 25px;

        }
        & .searchIcon{
            height: 16px;
            width: 16px;
            display: flex;
            align-items: center;
        }
        & .formControl{
            font-size: 12px;
            color: $theme5;
            background-color: transparent;
        }
        & ::placeholder{
            color: $theme5 !important;
        }
    }
    & .sectonMainHeading{
        font-size: 15.44px;
        line-height: 19.69px;
        color: white;
    }
    & .tableSection{
        background-color: $theme8;
        border-radius: 5px;
        width: 100%;
        padding: 0 15px 7px;
        &::-webkit-scrollbar {
            display: flex;
            background-color: $theme5;
            height: 5px;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $theme8;
            height: 5px;
            border-radius: 8px;
        }
        & .rsIcon{
            height: 20px;
            width: 20px;
            display: inline-flex;
            align-items: center;
            border-radius: 50px;
            overflow: hidden;
            & img{
                height: 100%;
                width: 100%;
            }
        }
        & .tableContentHeading{
            & .tableContent{
                font-size: 14px;
                line-height: 18px;
                color: white;
                font-weight: 600;
                @include sm{
                    font-size: 17px;
                    line-height: 20px;
                }
                & .ruppyIcon{
                    width: 13px;
                    height: 13px;
                    margin-right: 2px;
                    display: inline-flex;
                    align-items: center;
                    & img{
                        height: 100%;
                        width: 100%;
                    }
                }
    
            }
            & .tableSubheading{
                font-size: 10px;
                line-height: 13px;
                color: $theme5;
                text-align: end;
                @include sm{
                    font-size: 13px;
                    line-height: 16px;
                }
            }
            & .depositeHeading{
                color: #42B72A;
                font-weight: 500;
            }
        }
        & .withDrawHeading{
            color:$theme5 !important;
            font-weight: 500 !important;
        }
        & .tdWidth{
            @include sm{
                min-width: 120px;
                width: auto;
            }
            @include xl{
            width: 120px;

            }
        }
        & table {
            & tbody {
                & tr {
                    &:last-child {
                        & .tableBorderBottom{
                            border-bottom: none !important;
                        }
                    }
                }
            }
        }
       .tableBorderBottom{
          border-bottom: 0.5px solid rgba($theme5, .5);
          padding-inline: 5px;
        }
    }
}

.withdrawTabs{
    background-color: #242937;
    border-radius: 5px;
    overflow: hidden;
    padding: 14px;
    margin-top: 10px;
    @include sm{
        padding: 25px;
    }
    @include xl{
    padding: 35px;

    }
    & .navTabs{
        list-style: none;
        margin: 0;
        padding: 0;
        border-bottom: 0.5px solid $theme5;
        // padding-bottom: 7px;
        display: flex;
        align-items: center;
        & .navLink{
            font-size: 15px;
            color: $theme5;
            text-align: center;
            font-weight: 500;
            line-height: 19px;
            text-decoration: none;
            padding: 10px 35px;
            position: relative;
            z-index: 10;
            border-bottom: 1px solid transparent !important;
            cursor: pointer;
            &.active{
                background-image: linear-gradient(to top, rgba(66, 183, 42, 0.4), rgba(66, 183, 42, 0) 44%);
                color: white;
                border: none;
                border-bottom: 1px solid #42B72A !important;
                font-weight: 400;

            }
          
        }
    }
    & .mainTabs{
      
        & .headingTabs{
            font-size: 15px;
            color: $theme5;
            font-weight: 500;
            margin: 10px 0 7px 0;
        }
        & .headingValue{
            font-size: 15px;
            color: white;
            font-weight: 400;
        }
        & .usdtSection{
            background-color: $theme8;
            border-radius: 5px;
            height: 100%;
            padding: 15px 17px;
          
            & .subHeading{
                font-size: 15px;
                color: $theme5;
                font-weight: 500;
            }
            & .rightArrow{
                width: 6.22px;
                height: 12px;
                @include sm{
                    width: 8.22px;
                    height:15px ;
                }
                & img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        & .headingUsdt{
            font-size: 14px;
            font-weight: 500;   
            color:white;
            @include sm{
                font-size: 17px;
                font-weight: 600;
            }
            & .logousdt{
                width: 23px;
                height: 23px;
                margin-right: 10px;
                @include sm{
                    width: 30px;
                    height: 30px;
                }
                & img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        & .widthdrawAmmount{
            background-color: #15191F;
            border: 1px solid $theme5;
            border-radius: 5px;
            padding: 15px;
            & .subHeading{
                font-size: 13px;
                color: $theme5;
                font-weight: 500;
                @include sm{
                font-size: 15px;

                }
            }
            & .feesIcon{
                height: 15px;
                width: 15px;
                display: flex;
                margin-left: 10px;
                & img{
                    height: 100%;
                    width: 100%;
                }
            }
            & .sucess{
                color:#42B72A;
            }
        }
        & .noticeSection{
            background-image: linear-gradient(to right, hsl(111, 50%, 33%) , #253322);
            padding: 18px;
            border-radius: 5px;
            border: 1px solid$theme5;
            & .noticeHeading{
                font-size: 13px;
                line-height: 16px;
                font-weight: 400;
                color: white;
                @include sm{
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
        & .changeBtn {
            font-size: 15px;
            line-height: 22px;
            color: white;
            font-weight: 500;
            border-bottom: 3px solid #318120 !important;
            background-color: #62C412;
            border: 0;
            box-shadow: none;
            padding: 10px 35px;
            border-radius: 5px;
            display: flex;
            align-items: center;
        }
        & .flatdiv{
            background-color: $theme8;
            border-radius: 5px;
            padding: 10px;
            & .flatHeading{
                font-size: 15px;
                font-weight: 600;
                color: white;
            }
            & .inrLogo{
                height: 23px;
                width: 23px;
                margin-right: 15px;
            }
            & .rightArrow{
                width: 6.22px;
                height: 12px;
                @include sm{
                    width: 8.22px;
                    height:15px ;
                }
            }
        }
        & .fletSection{
            border-bottom: 1px solid $theme5;
            padding-bottom: 10px;
        }
        & .fiatHeading{
            font-size: 15px;
            font-weight: 600;
            color: white;
        }
        & .recommandBox{
            background-color: #1B2028;
            padding: 12px;
            border-radius: 5px;
            & .recommandText{
                font-size: 13px;
                font-weight: 500;
                color: $theme5;
                margin-bottom: 22px;
            }
            & .cardWrapper{
                background-color: #212632;
                border-radius: 5px;
                height: 76px;
                & .cardLeft{
                    padding-left: 11px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @include sm{
                        padding-left: 18px;
                    }
                    & .cardImg{
                        width: 26px;
                        height: 23px;
                        @include sm{
                            width: 32px;
                            height: 28px;
                        }
                        object-fit: contain;
                        & img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    & .creditText{
                        font-size: 12px;
                        font-weight: 500;
                        color: white;
                        @include sm{
                            font-size: 13px;
                            font-weight: 600;
                        }
                    }
                    & .creditGray{
                        font-size: 13px;
                        font-weight: 400;
                        color: $theme5
                    }
                    & .eurtxt{
                        font-size: 12px;
                        font-weight: 500;
                        color: white;
                    }
                }
                & .divider{
                    background-color: $theme5;
                    width: 2px;
                    height: 24px;
                }
                & .rytArroww{
                    width: 12px;
                    height: 12px;
                    & img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                & .fastestTxt{
    
                }
                & .exclaImg{
                    width: 18px;
                    height: 18px;
                    object-fit: contain;
                    & img{
                        width: 100%;
                        height: 100%;
                    }
                }
                & .fastestTxt{
                    border-radius: 0px 5px 0px 10px;
                    background-color: $theme14;
                    height: 16px;
                    padding-inline: 19px;
                    font-size: 12px;
                    font-weight: 500;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

.AffiliateSection{
    background-color: #242937;
    border-radius: 5px;
    overflow: hidden;
    padding: 14px;
    // margin-top: 10px;
    @include sm{
        padding: 25px;
    }
    @include xl{
    padding: 35px;

    }
    & .add-icon{
        height: 15px;
        width: 15px;
        display: flex;
        @include sm{
            height: 17px;
            width: 17px;
        }
    }
    & .add-txt{
        font-size: 18px;
        line-height: 130%;
        font-weight: 600;
        text-transform: uppercase;
        @include sm{
        font-size: 20px;

        }
    }
    & .affiliateBtn{
        background-color: $theme8;
        border-radius: 5px;
        padding: 10px 30px;
        font-size: 13px;
        font-weight: 500;
        color: $theme5;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block   ;
        text-wrap: nowrap;
        cursor: pointer;
        &.active{
            background-color: $theme14;
            color: white;
        }
    }
    & .inputLabel{
        font-size: 15px;
        color: $theme5;
        font-weight: 600;
    }
    & .inputUser {
        background-color: #2E3443;
        border-radius: 5px;
        overflow: hidden;
        font-size: 12px;
        color: $theme5;
        width: 100%;
        border: none;
        height: 41px;
        box-shadow: none;
        padding: 5px 10px;
        &::placeholder{
            color: $theme5;
            font-size: 10px;
            line-height: 13px;
            font-weight: 600;
        }
        &:focus{
            outline: none;
        }
    }
    & .changeBtn {
        font-size: 13px;
        line-height: 16px;
        color: white;
        font-weight: 500;
        border-bottom: 3px solid #318120 !important;
        background-color: #62C412;
        border: 0;
        box-shadow: none;
        padding: 10px 25px;
        border-radius: 5px;
        text-wrap: nowrap;
    }
    & .comissionCard{
        background-color: $theme8;
        border-radius: 5px;
        padding:10px 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        & .comissionHeading{
            font-size: 15px;
            line-height: 22px;
            font-weight: 700;
            color: white;
            text-align: center;
            margin-bottom: 8px;
            
        }
        & .comissionSubheading{
            font-size: 11px;
            line-height: 18px;
            font-weight: 700;
            color: white;
            text-align: center;
        }
    }
    & .betTableNew {
        margin-top: 20px;
        & table{
            border-collapse: separate;
            border-spacing: 0 5px;
            & > * {
                border: 0;
            }
            & thead{
                border-radius: 5px;

                th {
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    background-color: $theme8 !important;
                    white-space: nowrap;
                    color: #B2B6C5;
                    padding: 10px 17px;
                    border: 0;
                    &:first-child {
                        border-radius: 3px 0 0 3px;
                    }
                    &:last-child {
                        border-radius: 0 3px 3px 0;
                    }
                }  
            } 
            & tbody{
               & td {
                font-size: 12px;
                font-weight: 500;
                background-color: $theme8;
                white-space: nowrap;
                color: $theme5;
                padding: 6px 17px;
                border: 0px;
                vertical-align: middle;
                @include lg {
                    font-size: 14px;
                }
                &:first-child {
                    border-radius: 3px 0 0 3px;
                }
                &:last-child {
                    border-radius: 0 3px 3px 0;
                }
                & .clamSection{
                    background-color: $theme14;
                    border-radius: 5px;
                    padding: 6px 15px;
                    font-size: 11px;
                    line-height: 14.3px;
                    color: white;
                    text-align: center;
                }
               }
            }
    }
    
    }
    & .affilatepera{
        font-size: 13px;
        line-height: 16.9px;
        color: $theme5;
        font-weight: 600;
        & span{
            color: $theme14;
        }
    }
}

.depositedSection{ 
    
    & .noEntryBtn{
        background-color: $theme8;
        border-radius: 5px;
        padding: 13px 30px;
        display: inline-flex;
        font-size: 13px;
        font-weight: 500;
        color: $theme5;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
       
    }
}

.bannerSlide{
    position: relative;
    & .transform-banner{
        transform: translateY(100%);
        position: absolute;
        transition: all .3s ease-in-out;
    }
    &:hover .transform-banner{
        transform: translateY(-100%);
    }
}

.dropDown-icon{
background-color: transparent;
padding: 0;
border: none;
&:hover{
    background-color: transparent;
}
&:active{
    background-color: transparent !important;
}
&::after{
    content: none;
}
}

.dropDownMenu{
background-color: $theme10;
padding: 20px 15px 2px 15px;
border-radius: 15px;
min-width: 215px;
border-top: 2.67px solid var(--Strok-Color, #2F364C) !important;
border: 0;
&.profileDropMenu {
    @include max-lg {
        top: 7px !important;
    }
    @include lg {
        top: 22px !important;
        right: -5px !important;
        left: unset !important;
    }
}
& .dropdownItem{
    color: $theme5 !important;
    background-color: $theme1;
    border-radius: 3px;
    margin-bottom: 8px;
    border-radius: 30px;
    padding: 4px;

    & .DropTxt{
        font-size: 12px;
    }
    &.active{
        background-image: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%) !important;
        color: white !important;
        & .dropIcon{
            background: #fff;
        }
    }
    &:hover{
        background-image: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%) !important;
        color: white !important;
        & .dropIcon{
            background: #fff;
        }
    }
    & .dropIcon{
        height: 25px;
        width: 25px;
        display: flex;
        // filter: brightness(0.6);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #202733;
        box-shadow: 0 0 2px #969696;
        & img{
            height: 11px;
            width: 100%;
        }
    }
}
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    background-color: transparent;
}

.signout-modal{
    & .signModalTitle{
        & .SignIcon{
            height: 18px;
            width: 18px;
            display: flex;
            & img{
                height: 100%;
                width: 100%;
            }
        }
        & .heading{
            font-size: 18px;
            font-weight: 700;
            line-height: 130%;
            color: white;
        }
    }
    & .btn-close{
        filter: invert(1);
    }
    & .modalBody{
        color: $theme5;
    }
    & .modal-content{
        background-color: $theme1 !important;
        overflow: hidden;
        border-radius: 10px !important;
    }
    & .signoutBtn{
        background-color: #0e95fa !important;
        border-radius: 25px !important;
        border: 0;
        font-size: 15px;
        font-weight: 600;
        height: 43px;
        width: 166px;
        // border-bottom: 3px solid #942B35;
    }
    & .modal-header{
        background-color: #2E3443;
    }
}