.dgwtoken-section{
    & .heading{
        font-size: 20px;
        font-weight: 600;
        line-height: 45.5px;
        text-align: left;
        color: white;
        @include md{
            font-size: 32px;
        }
        & span{
            color: #EFB90B;
        }
    }
    & .sub-text{
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        color: #B2B6C5;
        padding-right: 20px;
        @include md{
            font-size: 15px;
        }
    }
    & .cta-section{
        background: linear-gradient(90deg, #212632 0%, rgba(91, 181, 79, 0.18) 100%);
        padding: 20px;
        border-radius: 15px;
        @include md{
            padding: 30px;
        }
        & .securityImg{
            height: 50px;
            width: 190px;
        }
        & .security-txt{
            font-size: 13px;
            font-weight: 500;
            line-height: 19.5px;
            text-align: left;
            color: white;
            @include md{
                font-size: 14px;
            }
        }
        & .audit-link{
            font-size: 12px;
            font-weight: 500;
            line-height: 16.9px;
            text-align: right;
            color: #B2B6C5;
            padding-top: 2px;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    & .fire-section{
        & .fire-img{
            height: 23px;
            width: auto;
            @include md{
                height: 40px;
            }
        }
        & .fire-heading{
            font-size: 17px;
            font-weight: 600;
            line-height: 32.5px;
            text-align: left;
            color: white;
            @include md{
                font-size: 25px;
            }
        }
        & .fire-txt{
            font-size: 13px;
            font-weight: 500;
            line-height: 19.5px;
            text-align: left;
            color: #B2B6C5;
            @include md{
                font-size: 15px;
            }
        }
        & .fire-Btn{
            background: linear-gradient(180deg, #4D7DD6 0%, #284170 100%);
            padding: 10px 12px;
            border-radius: 5px;
            display: inline-block;
            cursor: pointer;
            & .btn-txt{
                font-size: 13px;
                font-weight: 600;
                line-height: 16.9px;
                text-align: left;
                color: white;
            }
            & .btn-svg{
                height: 14px;
                width: 14px;
                display: flex;
            }
        }
        & .fire-list{
            color: #B2B6C5;
            font-size: 13px;
            font-weight: 500;
            line-height: 19.5px;
            text-align: left;
            @include md{
                font-size: 15px;
            }
        }
    }
    & .earn-section{
        background: linear-gradient(90deg, #212632 0%, rgba(91, 181, 79, 0.18) 100%);
        padding: 15px;
        border-radius: 15px;
        @include md{
            padding: 25px;
        }
        & .heading{
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            @include md{
                font-size: 25px;
                line-height: 32.5px;
            }
        }
        & .sub-txt{
            font-family: Geogrotesque Sharp;
            font-size: 13px;
            font-weight: 500;
            line-height: 19.5px;
            text-align: left;
            color: #B2B6C5;
            @include md{
                font-size: 15px;
            }
            & .learn-span{
                font-size: 13px;
                font-weight: 500;
                line-height: 16.9px;
                text-decoration: underline;
                color: white;
                letter-spacing: 1px;
            }
        }
        & .coins-img{
            aspect-ratio: 1/0.8;
        }
        & .stake-Btn{
            background: linear-gradient(180deg, #4D7DD6 0%, #284170 100%);
            font-size: 13px;
            font-weight: 500;
            line-height: 16.9px;
            color: white;
            width: fit-content;
            padding: 10px 40px;
            border-radius: 5px;
        }
        & .head{
            font-size: 13px;
            font-weight: 500;
            line-height: 19.5px;
            text-align: center;
            color: #B2B6C5;
            @include md{
                font-size: 15px;
            }
        }
        & .text{
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            text-align: center;
            color: #fff;
            padding-top: 5px;
            @include md{
                font-size: 30px;
                line-height: 39px;
            }
        }
        & .right-line{
            border-right: 1px solid #B2B6C5;
        }
    }
    & .two-cards{
        margin-top: 20px;
        & .gaming-card{
            background: linear-gradient(90deg, #212632 0%, rgba(91, 181, 79, 0.18) 100%);
            padding-inline: 10px;
            border-radius: 15px;
            padding:20px 10px;
            height: 100%;
            @include md{
                padding: 30px 20px;
            }
            & .gaming-icon{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                & .gaming-img{
                    height: 33px;
                    width: 33px;
                    @include md{
                        height: 55px;
                        width: 55px;
                    }
                }
                & .hands-img{
                        height: 45px;
                        width: 67px;
                    @include md{
                        height: 80px;
                        width: 114px;
                    }
                }
            }
            & .gaming-heading{
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                color: #fff;
                padding-top: 25px;
                letter-spacing: 0.5px;
                @include md{
                    font-size: 25px;
                    line-height: 32.5px;
                }
            }
            & .gaming-txt{
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
                color: #B2B6C5;
                padding-top: 10px;
                @include md{
                    font-size: 15px;
                    line-height: 19.5px;
                }
            }
            & .three-coin-img{
                aspect-ratio: 1/1;
            }
        }
    }
    & .buy-sell-btn{
        padding: 10px 30px !important;
    }
    & .trade-section{
        & .trade-heading{
            font-size: 19px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            color: #fff;
            @include md{
                font-size: 25px;
                line-height: 32.5px;
            }
        }
        & .trade-btn{
            display: flex;
            align-items: center;
            gap: 20px;
            padding-top: 30px;
            & .trade-1{
                height: 85px;
                width: 210px;
            }
        }
    }
    & .mining-section{
        & .mine-heading{
            font-size: 19px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            color: #fff;
            @include md{
            font-size: 25px;
            line-height: 32.5px;
            }
        }
        & .sub-para{
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            color: #B2B6C5;
            @include md{
                font-size: 15px;
                line-height: 19.5px;
            }
        }
    }
    & .head{
        & .heading{
            font-size: 24px;
            font-weight: 600;
            line-height: 39px;
            text-align: left;
            color: #fff;
            @include md{
                font-size: 28px;
            }
        }
        & .matrics-card{
            background: linear-gradient(180deg, #243A29 0%, #212632 100%);
            border-radius: 15px;
            padding: 9px;
            @include md{
                padding: 15px;
            }
            & .matrics-table{
                & table{
                    & tr{
                        & td{
                            background-color: transparent;
                            & .head{
                                color: #B2B6C5;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 22.1px;
                                text-align: left;
                                @include md{
                                    font-size: 14px;
                                }
                            }
                            & .sub-head{
                                color: white;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 22.1px;
                                text-align: left;
                                @include md{
                                    font-size: 14px;
                                }
                                & .green-price{
                                    color:#57AF00;
                                }
                                & .yellow-text{
                                    color: #EFB90B;
                                }
                                & .contact-img{
                                    height: 13px;
                                    width: 13px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
        & .burn-card{
            background: #212632;
            border-radius: 15px;
            padding: 9px;
            overflow: hidden;
            @include md{
                padding: 15px;
            }
            & .burn-table{
                z-index: 10;
                position: relative;
                & table{
                    & tr{
                        & td{
                            background: transparent;
                        }
                    }
                    & .head{
                        color: #B2B6C5;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 22.1px;
                        text-align: left;
                        @include md{
                            font-size: 14px;
                        }
                    }
                    & .sub-head{
                        color: white;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 22.1px;
                        text-align: left;
                        @include md{
                            font-size: 14px;
                        }
                        & .yellow-text{
                            color: #EFB90B;
                        }
                        & .contact-img{
                            height: 13px;
                            width: 13px;
                            cursor: pointer;
                        }
                    }
                }
            }
            & .burn-layer-img{
                right: -83px;
                bottom: -78px;
                height: 333px;
            }
            & .burn-img{
                aspect-ratio: 4/2.5;
                right: -20px;
                bottom: -20px;
            }
        }
    }
}   
.lineDivider{
    height: 1px;
    background: linear-gradient(to right, #B2B6C500 , #B2B6C5 , #B2B6C500 );
}

// estimated-modal start

.estimated-modal{
    & .modal-content{
        background: linear-gradient(180deg, #2C303F 0%, #1F331D 100%);
        padding: 15px;
        & .modalHeader{
            border-bottom: 0;
            & .modalTitle{
                font-size: 25px;
                font-weight: 600;
                line-height: 32.5px;
                text-align: left;
                color: #ffffff;
                letter-spacing: 1px;
            }
        }
        & .modalBody{
            padding: 8px 15px;
            & .body-text{
                font-size: 11px;
                font-weight: 500;
                line-height: 13px;
                color: #B2B6C5;
            }
            & .pills-div{
                display: flex;
                align-items: center;
                gap: 30px;
                & .pill{
                    font-size: 11px;
                    font-weight: 600;
                    line-height: 14.3px;
                    color: #ffffff;
                    &.active{
                        padding: 5px 10px;
                        border-radius: 5px;
                        background: #3F66B0;
                    }
                }
            }
            & .input-div{
                & .dApp-coin{
                    position: absolute;
                    top: 50%;
                    left: 9px;
                    height: 13px;
                    width: 13px;
                }
                & .cross-Btn{
                    position: absolute;
                    top: 50%;
                    right: 11px;
                    height: 10px;
                    width: 10px;
                    cursor: pointer;
                }
                & .formLabel{
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 13px;
                    color: #B2B6C5;
                }
                & .formControl{
                    font-size: 11px;
                    font-weight: 600;
                    line-height: 14.3px;
                    color: white;
                    background-color: #1F331D;
                    padding-left: 30px;
                    letter-spacing: 0.5px;
                    padding-right: 30px;
                    &::placeholder{
                        color: white !important;
                    }
                    &:focus{
                        outline: none;
                    }
                    &:focus-visible{
                        border-color: #fff;
                    }
                }
            }
            & .modal-table{
                & table{
                    & tr{
                        border-bottom: 1px solid black;
                    }
                    & td{
                        background-color: transparent;
                    }
                    & .table-head{
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 10.4px;
                        color: #B2B6C5;
                        word-wrap: nowrap;
                        white-space: nowrap;
                    }
                    & .table-txt{
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 120%;
                        color: white;
                    }
                    & .table-dollar-txt{
                        color: #B2B6C5;
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 120%;
                    }
                    & .table-coin-img{
                        height: 18px;
                        width: 18px;
                        display: flex;
                    }
                    & th{
                        background-color: transparent;
                    }
                }
            }
        }
        & .modalFooter{
            border-top: 0;
            & .footer-btn{
                background: linear-gradient(180deg, #4D7DD6 0%, #284170 100%);
                border-radius: 5px;
                color: white;
                font-size: 13px;
                font-weight: 600;
                line-height: 130%;
                width: 100%;
                padding: 12px 20px;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}

// staking-modal

.staking-modal{
    & .modal-content{
        background: linear-gradient(180deg, #2C303F 0%, #1F331D 100%);
        // padding-block: 30px;
    }
    & .modalHeader{
        border-bottom: 0;
        padding: 15px 20px;
        & .modalTitle{
            font-size: 25px;
            font-weight: 600;
            line-height: 32.5px;
            text-align: left;
            color: #ffffff;
            letter-spacing: 1px;
        }
    }
    & .modalBody{
        padding: 0;
        overflow: hidden;
        padding-bottom: 30px;
        & .body-text{
            font-size: 11px;
            font-weight: 500;
            line-height: 13px;
            color: #B2B6C5;
            padding: 10px 20px;
        }
        & .left-bar{
            & .coin-imgtxt{
                padding: 15px 15px 15px 25px;
                border-radius: 0px 10px 10px 0;
                @include sm{
                    padding: 15px 15px 15px 35px;
                }
                &.active{
                    background: linear-gradient(270deg, rgba(21, 25, 31, 0) 0%, #15191F 100%);
                }
                & .coins-img{
                    height: 30px;
                    width: 30px;
                    min-width: 30px;
                }
                & .coins-txt{
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 16.9px;
                    text-align: center;
                    color: white;
                }
            }
        }
        & .right-bar{
            background-color: #15191F;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 30px;
            border-radius: 10px;
            height: 300px;
            overflow: hidden;
            overflow-y: auto;
            & .coin-img{
                height: 22px;
                width: 22px;
            }
            & .upper-txt{
                font-size: 11px;
                font-weight: 600;
                line-height: 14.3px;
                color: white;
            }
            & .lower-txt{
                font-size: 9px;
                font-weight: 500;
                line-height: 11.7px;
                color: #B2B6C5;
            }
        }
    }
}


// deposit-section
.deposit-section{
    & .deposit-card{
        background: linear-gradient(156.96deg, #192C24 14.92%, #212632 100%);
        border-radius: 15px;
        padding: 20px;
        & .deposit-header{
            & .coin-img{
                height: 28px;
                width: 28px;
                @include sm{
                    height: 45px;
                    width: 45px;
                }
            }
            & .upper-txt{
                font-size: 13px;
                font-weight: 500;
                line-height: 130%;
                color: #B2B6C5;
                & .upper-span{
                    height: 10px;
                    width: 10px;
                    display: inline-block;
                }
            }
            & .middle-txt{
                font-size: 14px;
                font-weight: 600;
                line-height: 130%;
                color: white;
                @include sm{
                    font-size: 17px;
                }
                @include md{
                    font-size: 20px;
                }
                & span{
                    color: #B2B6C5;
                }
            }
            & .lower-txt{
                font-size: 13px;
                font-weight: 500;
                line-height: 16.9px;
                color: #B2B6C5;
            }
            & .buy-btn{
                background: linear-gradient(180deg, #4D7DD6 0%, #284170 100%);
                border-radius: 5px;
                padding: 8px 12px;
                font-size: 13px;
                font-weight: 600;
                line-height: 130%;
                color: white;
                width: fit-content;
                cursor: pointer;
                text-wrap: nowrap;
            }
        }
        & .deposit-body{
            & .coin-img{
                height: 20px;
                width: 20px;
                @include sm{
                    height: 28px;
                    width: 28px;
                }
            }
            & .upper-txt{
                font-size: 11px;
                font-weight: 600;
                line-height: 130%;
                color: white;
                @include sm{
                    font-size: 13px;
                }
                & span{
                    color: #B2B6C5;
                }
            }
            & .lower-txt{
                font-size: 12px;
                font-weight: 500;
                line-height: 130%;
                color: #B2B6C5;
            }
            & .dollar-Btn{
                background-color: #2C303F;
                padding: 8px 10px;
                border-radius: 5px;
                & .coin-img{
                    height: 18px;
                    width: 18px;
                    display: flex;
                }
                & .dollartxt{
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16.9px;
                    color: #B2B6C5;
                    & span{
                        color: white;
                    }
                }
            }
        }
        & .deposit-footer{
            margin-top: 30px;
            & .deposit-btn{
                background: linear-gradient(180deg, #4D7DD6 0%, #284170 100%);
                border-radius: 5px;
                color: white;
                font-size: 13px;
                font-weight: 600;
                line-height: 130%;
                width: 100%;
                padding: 12px 20px;
                text-align: center;
                cursor: pointer;
                border: 0;
                &.disabled{
                    opacity: 0.5;
                    cursor: auto;
                }
            }
            & .earned-txt{
                font-size: 13px;
                font-weight: 500;
                line-height: 16.9px;
                color: #B2B6C5;
            }
            & .amount-txt{
                font-size: 13px;
                font-weight: 600;
                line-height: 16.9px;
                color: white;
                width: fit-content;
            }
        }
    }
}