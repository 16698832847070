$theme1 : #1E222D !default;
$theme2 : #0D131C !default;
// $theme3 : #CCFF00 !default;
$theme3 : #62C412 !default;
$theme4 : #202733 !default;
$theme5 : #B2B6C5 !default;
// $theme6 : #FFEA00 !default;
$theme6 : #62C412 !default;
$theme7 : #161A25 !default;
$theme8 : #2E3443 !default;
$theme9 : #92B600 !default;
$theme10 : #242937 !default;
$theme11 : #57FF00 !default;
$theme12 : #318120 !default;
$theme13 : #FFEA00 !default;
$theme14 : #42B72A !default;

$enable-cssgrid:              true !default;
$enable-negative-margins:     true !default;





// Media Query Mixins
@mixin sm {
  @media only screen and (min-width: 576px) {
    @content;
  }
}

@mixin max-sm {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin max-md {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin max-lg {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}

@mixin xxxl {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

@mixin max-450 {
  @media (max-width: 450px) {
    @content;
  }
}

// Light Mode Mixin
@mixin light-mode {
  [data-theme="light"] & {
    @content;
  }
}

// Light Mode + Media Query Mixins
@mixin light-sm {
  [data-theme="light"] {
    @media only screen and (min-width: 576px) {
      @content;
    }
  }
}

@mixin light-md {
  [data-theme="light"] {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
}

@mixin light-lg {
  [data-theme="light"] {
    @media only screen and (min-width: 992px) {
      @content;
    }
  }
}

@mixin light-xl {
  [data-theme="light"] {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
}

@mixin light-xxl {
  [data-theme="light"] {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  }
}


@mixin ellipsis($line: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}