$themeColor1 : #0E95FA !default; // logo color
$themeColor2 : #1A1E29 !default; // header color
$themeColor3 : #191A24 !default; // body color
$themeColor4 : #2F93DD !default; // sidebar icon color
$themeColor5 : #2F364C !default; // sidebar active color
$themeColor6 : #3DACFF !default; // border active color
$themeColor7 : #ffffff !default; // light color
$themeColor8 : #d4d4d4 !default; //off white color
$themeColor9 : #202733 !default; //dark tab color
$themeColor10 : #6A798C !default; //dark second color
$themeColor11 : #1C202B !default; //dark second color
$themeColor12 : #1D4A6C !default; //sidebar hover border color
$body        :   #191A24 !default; //body-color
@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html body {
    font-family: 'Lexend', sans-serif !important;
        background-color: $body !important;
}


@import './sb';
@import './ss';