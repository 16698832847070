// new css
.SportsBoxSec {
  --bs-gutter-x: 15px !important;
    --bs-gutter-y: 15px !important;
  .sport-bx-1 {
    background-color: $themeColor9;
    border-top:2px solid $themeColor5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    img {
      width: 70px;
      height: 70px;
    }

    &.active {  
      border-top: 2px solid $themeColor6 !important;
      background-color: $themeColor1 !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
      h5 {
        font-size: 15px;
        line-height: 22px;
        color: $themeColor7;
        font-weight: 600;
      }
      p {
        font-size: 11px;
        font-weight: 400;
        color: $themeColor8 !important;
      }
    }
  @include max-lg{
    --bs-gutter-x: 12px !important;
    --bs-gutter-y: 12px !important;
  } 
 
  }
  .PopularGameCard {
    position: relative;
    background-color: $themeColor11;
    border-top: 2px solid $themeColor5;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(17, 20, 26, 0.60);
    padding-bottom: 5px;
    .card-tp {
      padding: 8px 16px;
      .tp-title {
        font-size: 10px;
        font-weight: 400;
      }
      .flag-img {
        display: inline-flex;
        img {
          width: 15px;
          border-radius: 2px;
        }
      }
    }
    .card-mid-sec {
      border-top: 1px solid $themeColor5;
    }
    .card-mid-inner {
      padding: 10px 18px;
      .team-img1 {
        display: inline-block;
        background-color: $themeColor9;
        padding: 12px 16px;
        border: 2px solid $themeColor5;
        border-radius: 5px;
        img {
          width: 25px;
        }
      }
      .team-title {
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 0;
      }
      .time-bx {
        p {
          color: $themeColor10;
          font-size: 9px;
          font-weight: 500;
        }
        h4 {
          color: $themeColor7;
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
    .card-btm-sec {
      padding: 10px 18px;
      .button-bx {
        position: relative;
        padding: 8px 16px;
        background-color: #202733;
        border-radius: 5px;
        border-top: 2px solid #2F364C;
        display: inline-flex;
        justify-content: space-between;
        gap: 14px;
      }
      .content1 {
        color: $themeColor10;
        font-size: 8px;
        font-weight: 500;
      }
      .content2 {
        color: $themeColor8;
        font-size: 8px;
        font-weight: 500;
      }
    }
  }
  
.headingAnchor{
  color: $themeColor10;
}
.mainSubHeading{
  font-size: 15px !important;
  font-weight: 600;
  color: white;
  @include lg {
    text-decoration: none !important;
  }
}
.mainHeading img{
  filter: none !important;
}



.sportsCardBox {

  transition: 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
}
  
  background-color: $themeColor11;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(17, 20, 26, 0.60),
  inset 0px 0px 1px 2px $themeColor5; 
  padding-bottom: 5px;
  overflow: hidden;
}
.sportsCard {
  .sportsImg {
    border-radius: 0px !important;
    height: 190px;
    &:hover {
      border-color: transparent;
    }
    @include max-md{
      height: 155px;
    }
  }
}
.sportsCardBox {
  .sportsTitle {
    h5 {
      font-size: 12px !important;
      font-weight: 600;
      color: $themeColor8;
      text-align: center;
      padding-top: 12px;
    }
  }
}
.SportsBannerSec {
  .sports_banner {
    cursor: pointer;
    height: 270px;

    img {
      width: 100%;
      display: block;
    }
  }
}
.betTableOptionWrapper {
  .betTableOption {
    padding: 10px 26px !important;
    border-radius: 40px !important;
    background-color: $themeColor9;
    box-shadow: inset 2px 2px 0px 0px $themeColor5;

    &:hover,
    &.active {
      background: linear-gradient(270deg, #47AEFA 0%, #0E95FA 100%) !important;
      box-shadow: inset 2px 2px 0px 0px $themeColor6;
    }
  }
}
.betTable {
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    background-color: $themeColor11;
    padding: 4px 15px;
    box-shadow: 0px 4px 4px 0px rgba(17, 20, 26, 0.6);
    border-top: 2px solid $themeColor5;
    border-radius: 10px;
    margin-top: 8px !important;
  }

  tbody {
    td {
      background-color: $themeColor9 !important;
      padding: 15px 17px !important;
    }
  }

  thead {
    th {
      font-size: 11px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      color: $themeColor10 !important;
      padding: 10px 0px;
      text-align: center;
    }
  }
}



.headingAnchor {
  color: $themeColor10;
  font-size: 12px;
  font-weight: 500;
}
.casinoSliderBtns .sliderBtn{
  height: 0px !important;
  width: 0px !important;
  background-color: transparent;
}
.casinoSliderBtns .sliderBtn img{
  height: 11px;
  filter: brightness(0.5) !important;
}
.mainHeading {
  font-size: 15px !important;
}

// -------------casino-page-css------------------

.bonusSlider .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: $themeColor1 !important;
}
.searchComp {
 border-radius: 40px !important;
}
.casinoTabOptionsWrapper .casinoTabOptions .casinoTabOption.active {
  background: linear-gradient(270deg, #47AEFA 0%, #0E95FA 100%) !important;
  box-shadow: inset 2px 2px 0px 0px #3DACFF;
  border-radius: 40px;
  padding: 10px 26px !important;
  color: #fff !important;
}
.casinoTabOptionsWrapper .casinoTabOptions .casinoTabOption {
  font-size: 14px !important;
  padding-inline: 0px !important;
  padding: 10px 10px !important;
  color: $themeColor10 !important;
  font-weight: 500;
}
.mainHeadingCasino img {
  // width: 24px;
  // height: 24px;
  margin-right: 5px;
  // margin-top: 5px;
}
.mainSubHeading  .viewIcon{
  height: 11px;
  margin-left: 5px;
  margin-top: -1px;
}
.mainSubHeadingCasino{
background: $themeColor9;
    padding: 8px 12px;
    border-radius: 40px;
}
.casinoPageSliderBtns .sliderBtn{
  padding: 15px 22px;
  border-radius: 40px;
  background-color: $themeColor9;
}
.casinoPageSliderBtns .sliderBtn img {
  height: 11px;
  filter: none !important;
}
.providerSwiperSlider .ProviderSlider{
  background-color: $themeColor9 !important;
  border-radius: 40px;
}

// promotion
.promotionBanner{
  padding-inline: 5px;
  // background: rgb(14,149,250);
  background: linear-gradient(90deg, #0E95FA 0%, #1C3243 100%);
  border-radius: 10px;
  padding-block: 13.5px;

  @include md{
    padding-inline: 27px;
    padding-block: 43.5px;
  }
  .banner-buttons{
    top: 55%;
    position: relative;
    @include md{
        position: absolute;
    }
   }
  & .tittle{
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    color: white;
    @include md{
      font-size: 18px;
    }
  }
  & .tittle2{
    font-size: 20px;
    font-weight: 800;
    margin-top: 8px;
    @include md{
      font-size: 30px;
    }
  }
  & .button1{
    background: linear-gradient(270deg, #7BC6FF 0%, #0E95FA 100%);
    color: white;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    padding: 11px 15px;
    border: none;
    border-top: 2px solid #3DACFF;
    border-radius: 5px;
    box-shadow: 0px 0px 9px 0px #00000066;
  }
  & .button2{
    font-weight: 600;
    font-size: 14px;
    padding: 11px 15px;
    border: none;
    text-align: center;
    border-radius: 5px;
    border: 1px solid white;
    background-color: transparent;
    color: white;

  }

}
.bg-2{
  background: linear-gradient(180deg, #BA490B 0%, #60120B 100%) !important;

}
.bg-3{
  background: linear-gradient(180deg, #A621AA 0%, #370544 100%) !important;

}
.bg-4{
  background: linear-gradient(180deg, #FFCA24 0%, #58450A 100%) !important;

}
.cardOuter{
  background: linear-gradient(180deg, #63BA41 0%, #18480F 100%);
  padding-inline: 9px;
  padding-block: 13.05px;
  border-radius: 10px;
  width: 100% !important;
  // min-width: 150px;
  & .level{
    width: 30px;
    // height: 30px;
    filter: drop-shadow(0px 4px 4px 0px #00000040);
      @include md{
        width:auto;
      }
  }
  .box{
    width: 60px;
    @include md{
      width: auto;
    }
  }
  & .tittle-3{
  font-weight: 800;
  
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
  margin-top: 3px;
  font-size: 10px;
  @include md{
    font-size: 16px;
  }

  }
  & .depositBtn{
    font-weight: 500;
    line-height: 12.5px;
    letter-spacing: 0%;
    text-align: center;
    color: #B3B3BE;
    border-radius: 5px;
    background:#191D27 !important;
    border: none;
    margin-top: 4px;
    font-size: 7px;
      padding: 5px;
    @include md{
      padding: 7px 18px;
      font-size: 10px;
    }
  }

}
.bonus{
  margin-top: 25px;
  & .nav-pills{
    background-color: #202733;
    width: fit-content;
    border-radius: 25px;
    margin-bottom: 50px;
    & .nav-item{
      & .nav-link{
        color: #6A798C;
        font-weight: 700;
        font-size: 12px;
        padding: 11px 47px;
        border-radius:  20px !important;
        &.active{
          background-color: #0E95FA !important;
          color: white !important;
        }
      }
    }
  }
}
.bonus-card{
  background-color: #202733;
  border-radius: 10px;
  position: relative;
 
}
.bonus_img{
  width: 176px !important;
  height: 159px;
}
.detail{
  padding-block: 9px;
  padding-inline: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bonus_tittle{
font-weight: 700;
font-size: 14px;
line-height: 17.5px;
letter-spacing: 0%;

}
.bonus_text{
font-weight: 500;
font-size: 13px;
line-height: 16.25px;
letter-spacing: 0%;
color: #B3B3BE;
margin-top: 4px;
}
.progressbtn{
font-weight: 600;
font-size: 12px;
line-height: 15px;
letter-spacing: 0%;
text-align: center;
padding-block: 8px;
padding-inline: 15px;
color: #0E95FA;
border: 1px solid #1D4A6C;
background-color: #2F364C;
border-radius: 5px;
white-space: nowrap;
@include md{
  padding-inline:32px;
}
}
.exslusive{
  position: absolute;
  width: 47px;
  height: 47px;
  top: -35px;
  right: 20px;
}