.bonusSlider {
  box-shadow: 0px 4px 5px rgba(#000, 0.5);
  border-radius: 10px;
  @include sm {
    box-shadow: none;
  }
  & .swiper {
    & .swiper-wrapper {
      & .swiper-slide {
        & .bonusSliderImage {
          border-radius: 10px;
          overflow: hidden;
          @include sm {
            box-shadow: 0px 4px 5px rgba(#000, 0.5);
          }
          & img {}
        }
      }
    }
    & .swiper-pagination {
      & .swiper-pagination-bullet {
        height: 5px;
        width: 5px;
        background-color: $theme5;
        opacity: 1;
        transition: 0.3s ease-in-out;
        border-radius: 10px;
        &.swiper-pagination-bullet-active {
          background-color: white;
        }
        @include sm {
          height: 8px;
          width: 8px;
          &.swiper-pagination-bullet-active {
            width: 35px;
          }
        }
      }
      @include sm {
        bottom: 0;
      }
    }
    @include sm {
      padding-bottom: 30px;
    }
  }
}

.recentBigWins {
  & .recentBigWinsInner {
    background-color: $theme4;
    padding: 8px;
    border-radius: 10px;
    margin-top: 15px;
    & .recentWinCard {
      background-color: $theme1;
      padding: 8px;
      border-radius: 6px;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      & .gameImg {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 2px;
        margin-right: 4px;
        display: flex;
      }
      & .userRank {
        height: 12px;
        width: 12px;
        object-fit: contain;
        margin-right: 4px;
      }
      & .userName {
        font-size: 12px;
        color: white;
        font-weight: 500;
      }
      & .amount {
        font-size: 10px;
        font-weight: 600;
        color: $theme3;
        & img {
          height: 12px;
          width: 12px;
          object-fit: contain;
          margin-left: 4px;
        }
      }
      & .arrowImg {
        & img {
          height: 10px;
          width: 10px;
          object-fit: contain;
          margin-left: 15px;
          filter: brightness(100);
        }
      }
    }
  }
}
